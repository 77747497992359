<template>
	<page-layout title="navigation.persons">
		<v-card elevation="1">
			<v-card-title>{{ $t("person.search.search") }}</v-card-title>
			<v-card-text>
				<v-row>
					<v-col>
						<v-text-field
							v-model="searchFirstName"
							clearable
							:label="$t('person.search.firstName')"
						></v-text-field>
					</v-col>
					<v-col>
						<v-text-field
							v-model="searchLastName"
							clearable
							:label="$t('person.search.lastName')"
						></v-text-field>
					</v-col>
					<v-col>
						<v-select
							v-model="searchGender"
							clearable
							:item-title="(item) => $t(item)"
							:items="genderTypes"
							:label="$t('person.search.gender')"
							return-object
						></v-select>
					</v-col>
					<v-col>
						<v-select
							v-model="searchRoleId"
							clearable
							item-title="name"
							item-value="id"
							:items="allRole"
							:label="$t('role.singularTitle')"
						>
						</v-select>
					</v-col>
					<v-col>
						<v-text-field
							v-model="searchEmail"
							clearable
							:label="$t('person.search.email')"
						></v-text-field>
					</v-col>
					<v-col>
						<v-text-field
							v-model="searchSource"
							clearable
							:label="$t('person.search.source')"
						></v-text-field>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>
		<paginated-table
			v-model="options.itemsPerPage"
			:headers="headers"
			:initial-sort="options.sortBy"
			:loading="state.loading"
			:state="state"
			:total="state.total"
			@change-sort="changeSort($event)"
			@click:row="onRowClick"
		>
		</paginated-table>
		<access-button
			:access-requirements="createRight"
			class="mb-5 mr-5"
			color="primary"
			icon
			location="bottom right"
			position="fixed"
			:title="$t('organisation.create')"
			@click="
				$router.push({
					name: 'createPerson',
				})
			"
		>
			<v-icon>mdi-plus</v-icon>
		</access-button>
	</page-layout>
</template>

<script setup lang="ts">
	import { Role } from "@de/IST.Cloud.Permission_API";
	import { Person } from "@de/IST.Cloud.Person_API";
	import rights from "@de/ist.cloud.rights";
	import { AccessButton, PageLayout } from "@de/ist.style_components_v3";
	import { refThrottled } from "@vueuse/core";
	import { ref } from "vue";
	import { useRouter } from "vue-router";

	import TaskReader from "@/api/PaginationApiReader";
	import { getPersons } from "@/api/PersonAPI";
	import { getRoles } from "@/api/RoleAPI";
	import { useDateTimeFormat } from "@/shared/DateUtils";
	import PaginatedTable from "@/shared/table/PaginatedTable.vue";
	import { genderTypes } from "@/shared/types/api/genderTypes";
	import { Headers } from "@/shared/types/table/HeadersType";
	import { OptionsType } from "@/shared/types/table/SortType";

	const { format: formatDate } = useDateTimeFormat();

	const createRight: rights = "Person.Create";
	const SEARCH_THROTTLED_MS = 1000;

	const searchFirstName = ref<string>("");
	const searchThrottledFirstName = refThrottled(
		searchFirstName,
		SEARCH_THROTTLED_MS,
	);
	const searchLastName = ref<string>("");
	const searchThrottledLastName = refThrottled(
		searchLastName,
		SEARCH_THROTTLED_MS,
	);

	const searchRoleId = ref<string>();
	const searchThrottledRoleId = refThrottled(searchRoleId, SEARCH_THROTTLED_MS);

	const searchGender = ref<string>();
	const searchThrottledGender = refThrottled(searchGender, SEARCH_THROTTLED_MS);

	const searchEmail = ref<string>();
	const searchThrottledEmail = refThrottled(searchEmail, SEARCH_THROTTLED_MS);

	const searchSource = ref<string>();
	const searchThrottledSource = refThrottled(searchSource, SEARCH_THROTTLED_MS);

	const sortString = ref<string>();

	const headers = ref<Headers>([
		{
			key: "firstName",
			title: "Vorname",
		},
		{
			key: "lastName",
			title: "Nachname",
		},
		{
			key: "email",
			title: "Email",
		},
		{ key: "gender", title: "Geschlecht", align: "end" },
		{ key: "external_id", title: "externe Nummer", align: "end" },
		{
			key: "dateOfBirthDateTime",
			title: "Geburtsdatum",
			align: "end",
			value: (item) => formatDate(item.dateOfBirthDateTime),
		},
	]);

	// here is the default sorting and pagination when the page is loaded
	const options = ref<OptionsType>({
		sortBy: [{ key: "lastName", order: "desc" }],
		itemsPerPage: 10,
	});

	const r = new TaskReader<Person>();
	const { state } = r.call(getPersons, (provideParameters) =>
		// istCustomerId and istSessionId will be set later
		// TODO: change type so that they are no longer mandatory
		provideParameters({
			email: searchThrottledEmail.value,
			firstName: searchThrottledFirstName.value,
			gender: searchThrottledGender.value,
			lastName: searchThrottledLastName.value,
			limit: options.value.itemsPerPage,
			roleId: searchThrottledRoleId.value,
			sort: sortString.value,
			source: searchThrottledSource.value,
		}),
	);

	const changeSort = (opt: string) => {
		sortString.value = opt;
	};
	const router = useRouter();

	const onRowClick = (event: Person) => {
		router.push({
			name: "person",
			params: { id: event.id },
		});
	};

	const allRole = ref<Role[]>([]);

	getRoles(undefined, undefined).then((res) => {
		allRole.value = res.items;
	});
</script>

<style scoped></style>
