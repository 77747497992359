<template>
	<form @submit="onSubmit">
		<v-card elevation="0">
			<v-card-text>
				<validated-text-field label="Id" :model-value="id" name="id" readonly />
				<validated-text-field
					:label="$t('identityProvider.name')"
					:model-value="name"
					name="name"
					@update:model-value="
						(newValue: string) => $emit('update:name', newValue)
					"
				/>
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn @click="resetForm">{{ $t("reset") }}</v-btn>
				<access-button
					:access-requirements="deleteRight"
					color="red"
					:disabled="id == undefined"
					variant="outlined"
					@click="emit('delete')"
					>{{ $t("delete") }}</access-button
				>
				<access-button
					:access-requirements="write_requirements"
					color="primary"
					location="bottom right"
					type="submit"
					variant="outlined"
					>{{ $t("save") }}</access-button
				>
			</v-card-actions>
		</v-card>
	</form>
</template>

<script setup lang="ts">
	import rights from "@de/ist.cloud.rights";
	import { AccessButton } from "@de/ist.style_components_v3";
	import { useForm } from "vee-validate";
	import * as yup from "yup";

	import { sharedInputProps } from "@/shared/types/SharedInputProps";

	import ValidatedTextField from "../validation/ValidatedTextField.vue";

	const deleteRight: rights = "Organisation.Delete";

	defineProps({
		name: {
			type: String,
			default: "",
		},
		...sharedInputProps,
	});

	const emit = defineEmits(["update:name", "submit", "delete"]);

	const { handleSubmit, resetForm } = useForm({
		validationSchema: yup.object({
			id: yup.string(),
			name: yup.string().required(),
		}),
	});

	const onSubmit = handleSubmit(() => {
		emit("submit");
	});
</script>
