<template>
	<page-layout
		:back-title="$t('back')"
		back-title-link="../../rightGroups"
		:title="$t('rightGroup.singularTitle')"
	>
		<right-group-form
			v-model:description="rightGroup.description"
			v-model:name="rightGroup.name"
			v-model:rights-for-right-group="rightGroup.rights"
			:write_requirements="createRight"
			@submit="save"
		>
		</right-group-form>
	</page-layout>
</template>

<script setup lang="ts">
	import { RightGroup } from "@de/IST.Cloud.Permission_API";
	import allRights from "@de/ist.cloud.rights";
	import { PageLayout } from "@de/ist.style_components_v3";
	import { AxiosError } from "axios";
	import { ref } from "vue";
	import { useRouter } from "vue-router";

	import { createRightGroup } from "../../api/RightGroupAPI";
	import RightGroupForm from "../../components/rightGroup/RightGroupForm.vue";

	const createRight: allRights = "RightGroups.Create";

	const rightGroup = ref<RightGroup>({
		created_at: "",
		description: "",
		id: "",
		name: "",
		rights: [],
		updated_at: "",
	});

	const router = useRouter();
	const save = () => {
		if (rightGroup.value) {
			createRightGroup({
				description: rightGroup.value?.description,
				name: rightGroup.value?.name,
				rights: rightGroup.value?.rights,
			})
				.then((res) => {
					//TODO: add notification
					router.push({
						name: "rightGroup",
						params: { id: res.data.id },
					});
				})
				.catch((err: AxiosError) => {
					//TODO: add notification
					alert("Fehler beim Speichern");
				});
		}
	};
</script>

<style scoped></style>
