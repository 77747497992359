<template>
	<page-layout
		:back-title="$t('back')"
		back-title-link="../"
		:title="
			$t('person.externalId') + ' ' + person?.firstName + ' ' + person?.lastName
		"
	>
		<external-id-form
			v-if="personToIdentityProvider && personToIdentityProvider.personRef"
			v-model:external-id="personToIdentityProvider.externalId"
			v-model:id="personToIdentityProvider.id"
			v-model:related-entity-id="personId"
			v-model:source="personToIdentityProvider.source"
			label="person.id"
			@delete="deleteAction"
			@submit="save"
		></external-id-form>
	</page-layout>
</template>

<script setup lang="ts">
	import { Person, PersonToExternalId } from "@de/IST.Cloud.Person_API";
	import { PageLayout } from "@de/ist.style_components_v3";
	import { AxiosError } from "axios";
	import { ref } from "vue";
	import { useRoute, useRouter } from "vue-router";

	import { getPersonById } from "@/api/PersonAPI";
	import {
		deletePersonToExternalId,
		getPersonToExternalIdById,
		updatePersonToExternalId,
	} from "@/api/PersonToExternalIdAPI";
	import ExternalIdForm from "@/components/person/ExternalIdForm.vue";

	const id = useRoute().params.externalId as string;
	const personId = useRoute().params.id as string;

	const personToIdentityProvider = ref<PersonToExternalId>();

	getPersonToExternalIdById(id).then((res) => {
		personToIdentityProvider.value = res.data;
	});

	const router = useRouter();
	const save = () => {
		updatePersonToExternalId(id, personToIdentityProvider.value)
			.then(() => {})
			.catch((err: AxiosError) => {
				//TODO: add notification
				alert("Fehler beim Speichern");
			});
	};

	const deleteAction = () => {
		deletePersonToExternalId(id)
			.then(() => {
				router.push({
					name: "person",
					params: { id: personId },
				});
			})
			.catch((err: AxiosError) => {
				//TODO: add notification
				alert("Fehler beim Speichern");
			});
	};

	const person = ref<Person>();

	getPersonById(personId).then((res) => {
		person.value = res.data;
	});
</script>

<style scoped></style>
