import {
	Configuration,
	CreateOrganisationRequest,
	Organisation,
	Organisations,
	OrganisationsApi,
	OrganisationsApiGetOrganisationsRequest,
} from "@de/IST.Cloud.Organisation_API";
import { useAuth } from "@de/ist-cloud-auth";
import { AxiosResponse } from "axios";
import { v4 as uuidv4 } from "uuid";

import { ApiResult } from "@/shared/types/api/apiTypes";

const configuration = new Configuration({
	basePath: "/organisation-api",
});

const organisationAPI = new OrganisationsApi(configuration);

export type argsWithoutCustandSession = Omit<
	OrganisationsApiGetOrganisationsRequest,
	"istCustomerId" | "istSessionId"
>;

export async function getOrganisations(
	args: argsWithoutCustandSession | undefined,
	cursor: string | undefined,
): Promise<ApiResult<Organisation>> {
	return await getOrganisationsInternal({
		externalId: args?.externalId ?? "",
		limit: args?.limit ?? 3,
		sort: args?.sort ?? "",
		cursor: cursor,
	});
}

export async function getOrganisationsById(id: string) {
	const { roleId, organisationId, customerId } = useAuth();

	// maybe move this to an interceptor
	const headers = {
		"X-Role-ID": roleId.value ?? "",
		"X-Organisation-ID": organisationId.value ?? "",
	};

	return await organisationAPI.getOrganisation(
		{
			id: id,
			istCustomerId: customerId.value ?? "",
			istSessionId: uuidv4(),
		},
		{
			headers: headers,
		},
	);
}

export async function deleteOrganisationsById(id: string | undefined) {
	const { roleId, organisationId, customerId } = useAuth();

	if (!id) {
		throw new Error("id is undefined");
	}

	// maybe move this to an interceptor
	const headers = {
		"X-Role-ID": roleId.value ?? "",
		"X-Organisation-ID": organisationId.value ?? "",
	};

	return await organisationAPI.deleteOrganisation(
		{
			id: id,
			istCustomerId: customerId.value ?? "",
			istSessionId: uuidv4(),
		},
		{
			headers: headers,
		},
	);
}

export async function updateOrganisation(
	id: string | undefined,
	org: Organisation | undefined,
) {
	if (!id || !org) {
		throw new Error("id is undefined");
	}
	const { roleId, organisationId, customerId } = useAuth();

	// maybe move this to an interceptor
	const headers = {
		"X-Role-ID": roleId.value ?? "",
		"X-Organisation-ID": organisationId.value ?? "",
	};

	return await organisationAPI.updateOrganisation(
		{
			id: id,
			updateOrganisation: org,
			istCustomerId: customerId.value ?? "",
			istSessionId: uuidv4(),
		},
		{
			headers: headers,
		},
	);
}

export async function createOrganisation(request: CreateOrganisationRequest) {
	const { roleId, organisationId, customerId } = useAuth();

	// maybe move this to an interceptor
	const headers = {
		"X-Role-ID": roleId.value ?? "",
		"X-Organisation-ID": organisationId.value ?? "",
	};

	return await organisationAPI.createOrganisation(
		{
			createOrganisationRequest: request,
			istCustomerId: customerId.value ?? "",
			istSessionId: uuidv4(),
		},
		{
			headers: headers,
		},
	);

	//return result.data;
}

const getOrganisationsInternal = async (
	args: argsWithoutCustandSession,
): Promise<ApiResult<Organisation>> => {
	const { roleId, organisationId, customerId } = useAuth();

	// maybe move this to an interceptor
	const headers = {
		"X-Role-ID": roleId.value ?? "",
		"X-Organisation-ID": organisationId.value ?? "",
	};

	if (args.cursor) {
		return convertApiResponse(
			await organisationAPI.getOrganisations(
				{
					istCustomerId: customerId.value ?? "",
					istSessionId: uuidv4(),
					cursor: args.cursor,
				},
				{
					headers: headers,
				},
			),
		);
	}
	return convertApiResponse(
		await organisationAPI.getOrganisations(
			{
				externalId: args.externalId,
				istCustomerId: customerId.value ?? "",
				istSessionId: uuidv4(),
				limit: args.limit,
				sort: args.sort,
			},
			{
				headers: headers,
			},
		),
	);
};

function convertApiResponse(
	response: AxiosResponse<Organisations, any>,
): ApiResult<Organisation> {
	return {
		first: response.data.first,
		items: response.data.items,
		last: response.data.last,
		next: response.data.next,
		prev: response.data.prev,
		self: response.data.self,
		total: response.data.total,
	};
}
