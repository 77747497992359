<template>
	<page-layout
		:back-title="$t('back')"
		back-title-link="../../identityProviders"
		:title="$t('identityProvider.singularTitle')"
	>
		<identity-provider-form
			v-if="newIdentityProvider"
			v-model:name="newIdentityProvider.name"
			:write_requirements="createRight"
			@submit="save"
		>
		</identity-provider-form>
	</page-layout>
</template>

<script setup lang="ts">
	import { CreateIdentityProviderRequest } from "@de/IST.Cloud.Person_API";
	import rights from "@de/ist.cloud.rights";
	import { PageLayout } from "@de/ist.style_components_v3";
	import { AxiosError } from "axios";
	import { ref } from "vue";
	import { useRouter } from "vue-router";

	import { createIdentityProvider } from "../../api/IdentityProviderAPI";
	import IdentityProviderForm from "../../components/identityProvider/IdentityProviderForm.vue";

	const createRight: rights = "IdentityProvider.Create";

	const newIdentityProvider = ref<CreateIdentityProviderRequest>({
		name: "",
	});

	const router = useRouter();
	const save = () => {
		createIdentityProvider(newIdentityProvider.value)
			.then((res) => {
				//TODO: add notification
				router.push({
					name: "identityProvider",
					params: { id: res.data.id },
				});
			})
			.catch((err: AxiosError) => {
				//TODO: add notification
				alert("Fehler beim Speichern");
			});
	};
</script>

<style scoped></style>
