<template>
	<page-layout
		:back-title="$t('back')"
		back-title-link="../../organisations"
		:title="$t('organisation.singularTitle')"
	>
		<organisation-form
			v-if="newOrganisation"
			v-model:authority="newOrganisation.authority"
			v-model:external-id="newOrganisation.external_id"
			v-model:name="newOrganisation.name"
			v-model:school-code="newOrganisation.school_code"
			v-model:school-types="newOrganisation.school_types"
			v-model:short-name="newOrganisation.short_name"
			:write_requirements="createRight"
			@submit="save"
		>
		</organisation-form>
	</page-layout>
</template>

<script setup lang="ts">
	import { CreateOrganisationRequest } from "@de/IST.Cloud.Organisation_API";
	import rights from "@de/ist.cloud.rights";
	import { PageLayout } from "@de/ist.style_components_v3";
	import { AxiosError } from "axios";
	import { ref } from "vue";
	import { useRouter } from "vue-router";

	import { createOrganisation } from "@/api/OrganisationAPI";
	import OrganisationForm from "@/components/organisation/OrganisationForm.vue";

	const createRight: rights = "Organisation.Create";

	const newOrganisation = ref<CreateOrganisationRequest>({
		authority: "",
		external_id: "",
		name: "",
		parent_id: "",
		school_code: "",
		school_types: [],
		short_name: "",
	});

	const router = useRouter();
	const save = () => {
		createOrganisation(newOrganisation.value)
			.then((res) => {
				//TODO: add notification
				router.push({
					name: "organisation",
					params: { id: res.data.id },
				});
			})
			.catch((_: AxiosError) => {
				//TODO: add notification
				alert("Fehler beim Speichern");
			});
	};
</script>

<style scoped></style>
