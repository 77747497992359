<template>
	<page-layout
		:back-title="$t('back')"
		back-title-link="../../identityProviders"
		:title="$t('identityProvider.singularTitle')"
	>
		<identity-provider-form
			v-if="identityProvider"
			v-model:id="identityProvider.id"
			v-model:name="identityProvider.name"
			:write_requirements="updateRight"
			@delete="deleteAction"
			@submit="save"
		>
		</identity-provider-form>
	</page-layout>
</template>

<script setup lang="ts">
	import { IdentityProvider } from "@de/IST.Cloud.Person_API";
	import rights from "@de/ist.cloud.rights";
	import { PageLayout } from "@de/ist.style_components_v3";
	import { AxiosError } from "axios";
	import { ref } from "vue";
	import { useRoute, useRouter } from "vue-router";

	import {
		deleteIdentityProvider,
		getIdentityProviderById,
		updateIdentityProvider,
	} from "../../api/IdentityProviderAPI";
	import IdentityProviderForm from "../../components/identityProvider/IdentityProviderForm.vue";

	const updateRight: rights = "IdentityProvider.Update";

	const id = useRoute().params.id as string;

	const identityProvider = ref<IdentityProvider>();

	getIdentityProviderById(id).then((res) => {
		identityProvider.value = res.data;
	});

	const router = useRouter();
	const save = () => {
		updateIdentityProvider(identityProvider.value?.id, identityProvider.value)
			.then(() => {})
			.catch((err: AxiosError) => {
				//TODO: add notification
				alert("Fehler beim Speichern");
			});
	};

	const deleteAction = () => {
		deleteIdentityProvider(identityProvider.value?.id)
			.then(() => {
				router.push({
					name: "identityProviders",
				});
			})
			.catch((err: AxiosError) => {
				//TODO: add notification
				alert("Fehler beim Speichern");
			});
	};
</script>

<style scoped></style>
