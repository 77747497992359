<template>
	<page-layout
		:back-title="$t('back')"
		back-title-link="../"
		:title="$t('secrets.singularTitle')"
	>
		<secrets-form
			v-if="roleToPerson"
			v-model:clientId="roleToPerson.client_id"
			v-model:hasShown="roleToPerson.was_shown"
			v-model:id="roleToPerson.id"
			v-model:name="roleToPerson.name"
			v-model:organisationId="roleToPerson.organisation.organisation_id"
			@delete="deleteAction"
			@showed-secret="reloadSecret"
		></secrets-form>
	</page-layout>
</template>

<script setup lang="ts">
	import { Secret } from "@de/IST.Cloud.Organisation_API";
	import { PageLayout } from "@de/ist.style_components_v3";
	import { AxiosError } from "axios";
	import { ref } from "vue";
	import { useRoute, useRouter } from "vue-router";

	import {
		deleteSecretForOrganisation,
		getSecretForOrganisation,
	} from "@/api/SecretAPI";
	import SecretsForm from "@/components/secret/SecretsForm.vue";

	const id = useRoute().params.secretId as string;
	const organisationId = useRoute().params.id as string;

	const roleToPerson = ref<Secret>();

	getSecretForOrganisation(id, organisationId).then((res) => {
		roleToPerson.value = res.data;
	});

	const router = useRouter();

	const deleteAction = () => {
		deleteSecretForOrganisation(id, organisationId)
			.then(() => {
				router.push({
					name: "organisation",
					params: { id: organisationId },
				});
			})
			.catch((err: AxiosError) => {
				//TODO: add notification
				alert("Fehler beim Speichern");
			});
	};

	const reloadSecret = () => {
		getSecretForOrganisation(id, organisationId).then((res) => {
			roleToPerson.value = res.data;
		});
	};
</script>

<style scoped></style>
