<template>
	<page-layout
		:back-title="$t('back')"
		back-title-link="../../organisations"
		:title="$t('organisation.singularTitle')"
	>
		<organisation-form
			v-if="organisation"
			v-model:authority="organisation.authority"
			v-model:external-id="organisation.external_id"
			v-model:id="organisation.id"
			v-model:name="organisation.name"
			v-model:school-code="organisation.school_code"
			v-model:school-types="organisation.school_types"
			v-model:secrestsLoading="loadingSecrets"
			v-model:secrets="secrets"
			v-model:short-name="organisation.short_name"
			:write_requirements="updateRight"
			@delete="deleteAction"
			@submit="save"
		>
		</organisation-form>
	</page-layout>
</template>

<script setup lang="ts">
	import { Organisation } from "@de/IST.Cloud.Organisation_API";
	import rights from "@de/ist.cloud.rights";
	import { PageLayout } from "@de/ist.style_components_v3";
	import { AxiosError } from "axios";
	import { ref } from "vue";
	import { useRoute, useRouter } from "vue-router";

	import {
		deleteOrganisationsById,
		getOrganisationsById,
		updateOrganisation,
	} from "@/api/OrganisationAPI";
	import { getSecretsForOrganisation } from "@/api/SecretAPI";
	import OrganisationForm from "@/components/organisation/OrganisationForm.vue";

	const updateRight: rights = "Organisation.Update";

	const id = useRoute().params.id as string;

	const organisation = ref<Organisation>();

	getOrganisationsById(id).then((res) => {
		organisation.value = res.data;
	});

	const { data: secrets, loading: loadingSecrets } =
		getSecretsForOrganisation(id);

	const router = useRouter();
	const save = () => {
		updateOrganisation(organisation.value?.id, organisation.value)
			.then(() => {})
			.catch((err: AxiosError) => {
				//TODO: add notification
				alert("Fehler beim Speichern");
			});
	};

	const deleteAction = () => {
		deleteOrganisationsById(organisation.value?.id)
			.then(() => {
				router.push({
					name: "organisations",
				});
			})
			.catch((err: AxiosError) => {
				//TODO: add notification
				alert("Fehler beim Speichern");
			});
	};
</script>

<style scoped></style>
