<template>
	<page-layout
		:back-title="$t('back')"
		back-title-link="../../rights"
		:title="$t('right.singularTitle')"
	>
		<role-form
			v-if="right"
			v-model:description="right.description"
			v-model:id="right.id"
			v-model:key-prop="right.name"
			:write_requirements="updateToRight"
			@delete="deleteAction"
			@submit="save"
		>
		</role-form>
	</page-layout>
</template>

<script setup lang="ts">
	import { Right } from "@de/IST.Cloud.Permission_API";
	import rights from "@de/ist.cloud.rights";
	import { PageLayout } from "@de/ist.style_components_v3";
	import { AxiosError } from "axios";
	import { ref } from "vue";
	import { useRoute, useRouter } from "vue-router";

	import {
		deleteRightById,
		getRightById,
		updateRight,
	} from "../../api/RightAPI";
	import RoleForm from "../../components/right/RightForm.vue";

	const updateToRight: rights = "Right.Update";

	const id = useRoute().params.id as string;

	const right = ref<Right>();

	getRightById(id).then((res) => {
		right.value = res.data;
	});

	const router = useRouter();
	const save = () => {
		updateRight(right.value?.id, right.value)
			.then(() => {})
			.catch((err: AxiosError) => {
				//TODO: add notification
				alert("Fehler beim Speichern");
			});
	};

	const deleteAction = () => {
		deleteRightById(right.value?.id)
			.then(() => {
				router.push({
					name: "rights",
				});
			})
			.catch((err: AxiosError) => {
				//TODO: add notification
				alert("Fehler beim Speichern");
			});
	};
</script>

<style scoped></style>
