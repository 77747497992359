import {
	Configuration,
	CreateRightGroupRequest,
	RightGroup,
	RightGroups,
	RightGroupsApi,
	RightGroupsApiGetRightGroupsRequest,
} from "@de/IST.Cloud.Permission_API";
import { useAuth } from "@de/ist-cloud-auth";
import { AxiosResponse } from "axios";
import { v4 as uuidv4 } from "uuid";

import { ApiResult } from "@/shared/types/api/apiTypes";

const configuration = new Configuration({
	basePath: "/permission-api",
});

const rightGroupsAPI = new RightGroupsApi(configuration);

export type argsWithoutCustandSession = Omit<
	RightGroupsApiGetRightGroupsRequest,
	"istCustomerId" | "istSessionId"
>;

export async function getRightGroups(
	args: argsWithoutCustandSession | undefined,
	cursor: string | undefined,
): Promise<ApiResult<RightGroup>> {
	return await getRightGroupsInternal({
		limit: args?.limit ?? 3,
		sort: args?.sort ?? "",
		cursor: cursor,
	});
}

export async function getRightGroupById(id: string) {
	const { roleId, organisationId, customerId } = useAuth();

	// maybe move this to an interceptor
	const headers = {
		"X-Role-ID": roleId.value ?? "",
		"X-Organisation-ID": organisationId.value ?? "",
	};

	return await rightGroupsAPI.getRightGroup(
		{
			id: id,
			istCustomerId: customerId.value ?? "",
			istSessionId: uuidv4(),
		},
		{
			headers: headers,
		},
	);
}

export async function deleteRightGroupById(id: string | undefined) {
	const { roleId, organisationId, customerId } = useAuth();

	if (!id) {
		throw new Error("id is undefined");
	}

	// maybe move this to an interceptor
	const headers = {
		"X-Role-ID": roleId.value ?? "",
		"X-Organisation-ID": organisationId.value ?? "",
	};

	return await rightGroupsAPI.deleteRightGroup(
		{
			id: id,
			istCustomerId: customerId.value ?? "",
			istSessionId: uuidv4(),
		},
		{
			headers: headers,
		},
	);
}

export async function updateRightGroup(
	id: string | undefined,
	org: RightGroup | undefined,
) {
	if (!id || !org) {
		throw new Error("id is undefined");
	}
	const { roleId, organisationId, customerId } = useAuth();

	// maybe move this to an interceptor
	const headers = {
		"X-Role-ID": roleId.value ?? "",
		"X-Organisation-ID": organisationId.value ?? "",
	};

	return await rightGroupsAPI.updateRightGroup(
		{
			id: id,
			updateRightGroupRequest: org,
			istCustomerId: customerId.value ?? "",
			istSessionId: uuidv4(),
		},
		{
			headers: headers,
		},
	);
}

export async function createRightGroup(request: CreateRightGroupRequest) {
	const { roleId, organisationId, customerId } = useAuth();

	// maybe move this to an interceptor
	const headers = {
		"X-Role-ID": roleId.value ?? "",
		"X-Organisation-ID": organisationId.value ?? "",
	};

	return await rightGroupsAPI.createRightGroup(
		{
			createRightGroupRequest: request,
			istCustomerId: customerId.value ?? "",
			istSessionId: uuidv4(),
		},
		{
			headers: headers,
		},
	);
}

const getRightGroupsInternal = async (
	args: argsWithoutCustandSession,
): Promise<ApiResult<RightGroup>> => {
	const { roleId, organisationId, customerId } = useAuth();

	// maybe move this to an interceptor
	const headers = {
		"X-Role-ID": roleId.value ?? "",
		"X-Organisation-ID": organisationId.value ?? "",
	};

	if (args.cursor) {
		return convertApiResponse(
			await rightGroupsAPI.getRightGroups(
				{
					istCustomerId: customerId.value ?? "",
					istSessionId: uuidv4(),
					cursor: args.cursor,
				},
				{
					headers: headers,
				},
			),
		);
	}
	return convertApiResponse(
		await rightGroupsAPI.getRightGroups(
			{
				istCustomerId: customerId.value ?? "",
				istSessionId: uuidv4(),
				limit: args.limit,
				sort: args.sort,
			},
			{
				headers: headers,
			},
		),
	);
};

function convertApiResponse(
	response: AxiosResponse<RightGroups, any>,
): ApiResult<RightGroup> {
	return {
		first: response.data.first,
		items: response.data.items,
		last: response.data.last,
		next: response.data.next,
		prev: response.data.prev,
		self: response.data.self,
		total: response.data.total,
	};
}
