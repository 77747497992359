<template>
	<page-layout
		:back-title="$t('back')"
		back-title-link="../"
		:title="
			$t('identityProviderCodes.singularTitle') +
			' ' +
			person?.firstName +
			' ' +
			person?.lastName
		"
	>
		<identity-provider-code-form
			v-if="role"
			v-model:code_value="role.code_value"
			v-model:emailCodeRequests="emailRequests"
			v-model:emailCodeRequestsLoading="loadingEmailRequests"
			v-model:id="id"
			v-model:person-id="personId"
			v-model:provider-id="role.identityProviderRef.id"
			v-model:send-at="role.send_at"
			v-model:showEmailDialog="showEmailDialog"
			v-model:used-at="role.used_at"
			v-model:valid-from="role.valid_from"
			v-model:valid-until="role.valid_until"
			:write_requirements="updateRight"
			@delete="deleteAction"
			@submit="save"
		>
		</identity-provider-code-form>
	</page-layout>

	<email-for-identity-provider-code-dialog
		v-if="role"
		v-model="showEmailDialog"
		:identity-provider-code="role"
		@email-send="
			reloadEmailRequests();
			localgetIdentityProviderCodeById();
		"
	></email-for-identity-provider-code-dialog>
</template>

<script setup lang="ts">
	import { IdentityProviderCode, Person } from "@de/IST.Cloud.Person_API";
	import allRights from "@de/ist.cloud.rights";
	import { PageLayout } from "@de/ist.style_components_v3";
	import { AxiosError } from "axios";
	import { ref } from "vue";
	import { useRoute, useRouter } from "vue-router";

	import { getPersonById } from "@/api/PersonAPI";
	import { formatDateWithFormat } from "@/shared/DateUtils";

	import {
		deleteIdentityProviderCode,
		getEmailRequestsForIdentityProviderCode,
		getIdentityProviderCodeById,
		updateIdentityProviderCode,
	} from "../../api/IdentityProviderCodeAPI";
	import EmailForIdentityProviderCodeDialog from "../../components/emailTemplate/EmailForIdentityProviderCodeDialog.vue";
	import IdentityProviderCodeForm from "../../components/identityProviderCode/IdentityProviderCodeForm.vue";

	const updateRight: allRights = "IdentityProviderCode.Update";

	const id = useRoute().params.identityProviderCodesId as string;
	const personId = useRoute().params.id as string;
	const showEmailDialog = ref<boolean>(false);
	const role = ref<IdentityProviderCode>();

	const localgetIdentityProviderCodeById = () => {
		getIdentityProviderCodeById(id).then((res) => {
			role.value = res.data;
			role.value.valid_from = formatDateWithFormat(
				"YYYY-MM-DD",
				role.value.valid_from,
			);
			role.value.valid_until = formatDateWithFormat(
				"YYYY-MM-DD",
				role.value.valid_until,
			);
			role.value.send_at = formatDateWithFormat(
				"YYYY-MM-DD",
				role.value.send_at,
			);
			role.value.used_at = formatDateWithFormat(
				"YYYY-MM-DD",
				role.value.used_at,
			);
		});
	};
	localgetIdentityProviderCodeById();

	const router = useRouter();
	const save = () => {
		updateIdentityProviderCode(role.value?.id, role.value)
			.then()
			.catch((err: AxiosError) => {
				//TODO: add notification
				alert("Fehler beim Speichern");
			});
	};

	const deleteAction = () => {
		deleteIdentityProviderCode(role.value?.id)
			.then(() => {
				router.push({
					name: "person",
					params: { id: personId },
				});
			})
			.catch((err: AxiosError) => {
				//TODO: add notification
				alert("Fehler beim Speichern");
			});
	};
	const person = ref<Person>();

	getPersonById(personId).then((res) => {
		person.value = res.data;
	});

	const {
		data: emailRequests,
		loading: loadingEmailRequests,
		reload: reloadEmailRequests,
	} = getEmailRequestsForIdentityProviderCode(id);
</script>

<style scoped></style>
