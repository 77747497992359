<template>
	<v-navigation-drawer color="primary" permanent :rail="rail">
		<template #prepend>
			<div class="drawer-header d-flex align-center pa-2">
				<div class="text-h6 text-truncate">IST.Manager</div>
				<v-btn
					:block="rail"
					class="ml-auto"
					density="comfortable"
					:icon="rail ? 'mdi-menu' : 'mdi-menu-open'"
					:ripple="false"
					rounded="0"
					:title="$t('toggleMenu')"
					variant="text"
					@click.stop="rail = !rail"
				></v-btn>
			</div>
		</template>
		<v-list nav>
			<v-list-item
				v-for="(menuItem, i) in links"
				:key="i"
				link
				:title="t(menuItem.title)"
				:to="menuItem.path"
			>
				<template #prepend>
					<v-icon
						v-if="!menuItem.customicon"
						:icon="menuItem.icon"
						:title="t(menuItem.title)"
					></v-icon>
					<img
						v-else
						:src="menuItem.customiconpath"
						:title="t(menuItem.title)"
					/>
				</template>
			</v-list-item>
		</v-list>
		<v-spacer></v-spacer>
		<v-btn-toggle
			v-model="lang"
			class="d-flex justify-center"
			divided
			mandatory
			variant="text"
		>
			<v-btn value="de">DE</v-btn>
			<v-btn value="en">EN</v-btn>
		</v-btn-toggle>
		<template #append>
			<div
				class="drawer-footer d-flex flex-column justify-center align-center pa-2"
			>
				<a class="text-center w-100" href="https://ist.com/de" target="_blank">
					<v-img class="w-100" height="56" src="@/assets/logo2.svg" />
				</a>
				<div class="text-center pt-2">
					<version-number />
				</div>
			</div>
		</template>
	</v-navigation-drawer>
</template>

<script setup lang="ts">
	import { VersionNumber } from "@de/ist.style_components_v3";
	import { computed, ref } from "vue";
	import { useI18n } from "vue-i18n";
	const { t } = useI18n();

	const rail = ref(false);

	const links = [
		{
			customicon: false,
			customiconpath: "",
			icon: "mdi-briefcase-account",
			path: "/customers",
			title: "navigation.customers",
		},
		{
			customicon: false,
			customiconpath: "",
			icon: "mdi-home-city-outline",
			path: "/organisations",
			title: "navigation.organisations",
		},
		{
			customicon: false,
			customiconpath: "",
			icon: "mdi-account-outline",
			path: "/persons",
			title: "navigation.persons",
		},
		{
			customicon: false,
			customiconpath: "",
			icon: "mdi-badge-account-outline",
			path: "/identityProviders",
			title: "navigation.identityProviders",
		},
		{
			customicon: false,
			customiconpath: "",
			icon: "mdi-badge-account-alert-outline",
			path: "/identityProviderCodes",
			title: "navigation.identityProviderCodes",
		},
		{
			customicon: false,
			customiconpath: "",
			icon: "mdi-email-edit-outline",
			path: "/emailTemplates",
			title: "navigation.emailTemplates",
		},
		{
			customicon: false,
			customiconpath: "",
			icon: "mdi-account-group-outline",
			path: "/roles",
			title: "navigation.roles",
		},
		{
			customicon: false,
			customiconpath: "",
			icon: "mdi-account-key-outline",
			path: "/rights",
			title: "navigation.rights",
		},
		{
			customicon: false,
			customiconpath: "",
			icon: "mdi-key-change",
			path: "/rightGroups",
			title: "navigation.rightGroups",
		},
	];

	const props = defineProps<{
		lang: string;
	}>();

	const emit = defineEmits<{
		"update:lang": [value: string];
	}>();

	const lang = computed({
		get: () => props.lang,
		set: function (value: string) {
			return emit("update:lang", value);
		},
	});
</script>

<style>
	.drawer-header {
		height: 62px;
	}

	.drawer-footer {
		gap: 8px;
	}
</style>
