<template>
	<page-layout :title="$t('navigation.rightGroups')">
		<v-text-field
			v-model="search"
			clearable
			:placeholder="$t('rightGroup.search')"
		></v-text-field>
		<paginated-table
			v-model="options.itemsPerPage"
			:headers="headers"
			:initial-sort="options.sortBy"
			:loading="state.loading"
			:state="state"
			:total="state.total"
			@change-sort="changeSort($event)"
			@click:row="onRowClick"
		>
		</paginated-table>
		<access-button
			:access-requirements="createRight"
			class="mb-5 mr-5"
			color="primary"
			icon
			location="bottom right"
			position="fixed"
			:title="$t('rightGroup.create')"
			@click="
				$router.push({
					name: 'createRightGroup',
				})
			"
		>
			<v-icon>mdi-plus</v-icon>
		</access-button>
	</page-layout>
</template>

<script setup lang="ts">
	import { RightGroup } from "@de/IST.Cloud.Permission_API";
	import rights from "@de/ist.cloud.rights";
	import { AccessButton, PageLayout } from "@de/ist.style_components_v3";
	import { refThrottled } from "@vueuse/core";
	import { ref } from "vue";
	import { useRouter } from "vue-router";

	import PaginationApiReader from "../../api/PaginationApiReader";
	import { getRightGroups } from "../../api/RightGroupAPI";
	import PaginatedTable from "../../shared/table/PaginatedTable.vue";
	import { Headers } from "../../shared/types/table/HeadersType";
	import { OptionsType } from "../../shared/types/table/SortType";

	const createRight: rights = "RightGroups.Create";
	const SEARCH_THROTTLED_MS = 1000;
	const search = ref<string>("");
	const searchThrottled = refThrottled(search, SEARCH_THROTTLED_MS);
	const sortString = ref<string>("");

	const headers = ref<Headers>([
		{
			key: "name",
			title: "Name",
		},
		{
			key: "description",
			title: "Beschreibung",
		},
	]);

	// here is the default sorting and pagination when the page is loaded
	const options = ref<OptionsType>({
		sortBy: [{ key: "name", order: "desc" }],
		itemsPerPage: 10,
	});

	const r = new PaginationApiReader<RightGroup>();
	const { state } = r.call(getRightGroups, (provideParameters) =>
		provideParameters({
			name: searchThrottled.value,
			limit: options.value.itemsPerPage,
			sort: sortString.value,
		}),
	);

	const changeSort = (opt: string) => {
		sortString.value = opt;
	};

	const router = useRouter();

	const onRowClick = (event: RightGroup) => {
		router.push({
			name: "rightGroup",
			params: { id: event.id },
		});
	};
</script>

<style scoped></style>
