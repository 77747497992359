<template>
	<form @submit="onSubmit">
		<v-card elevation="0">
			<br />
			<v-card-title class="justify-right">
				<div v-if="id" class="text-right">
					<v-tooltip bottom class="opacity-80" :disabled="!IsInvalidCode()">
						<template #activator="{ props }">
							<div v-bind="props" class="d-inline-block">
								<access-button
									:access-requirements="sendRight"
									color="primary"
									:disabled="IsInvalidCode()"
									icon
									:model-value="showEmailDialog"
									:title="$t('identityProviderCodes.send_email')"
									variant="text"
									@click="() => $emit('update:showEmailDialog', true)"
									><v-icon>mdi-email</v-icon></access-button
								>
								<access-button
									:access-requirements="sendRight"
									color="primary"
									:disabled="IsInvalidCode()"
									icon
									:title="$t('identityProviderCodes.print')"
									variant="text"
									><v-icon>mdi-printer</v-icon></access-button
								>
							</div>
						</template>
						<span>{{ disabledTooltipText }}</span>
					</v-tooltip>
				</div>
			</v-card-title>
			<v-card-text>
				<validated-text-field label="Id" :model-value="id" name="id" readonly />
				<validated-text-field
					disabled
					label="PersonId"
					:model-value="personId"
					name="personId"
					@update:model-value="
						(newValue: string) => $emit('update:personId', newValue)
					"
				/>
				<validated-text-field
					disabled
					label="Code"
					:model-value="codeValue"
					name="codeValue"
				/>

				<validated-select-single
					:items="allRole"
					itemtext="name"
					itemvalue="id"
					:label="$t('identityProvider.singularTitle')"
					:model-value="providerId"
					name="providerId"
					:readonly="usedAt != undefined && usedAt != null && usedAt != ''"
					@update:model-value="
						(newValue: string) => $emit('update:providerId', newValue)
					"
				></validated-select-single>
				<validated-text-field
					:label="$t('identityProviderCodes.valid_from')"
					:model-value="validFrom"
					name="validFrom"
					:readonly="usedAt != undefined && usedAt != null && usedAt != ''"
					type="date"
					@update:model-value="
						(newValue: string) => $emit('update:validFrom', newValue)
					"
				/>
				<validated-text-field
					:disabled="usedAt != undefined && usedAt != null && usedAt != ''"
					:label="$t('identityProviderCodes.valid_until')"
					:model-value="validUntil"
					name="validUntil"
					type="date"
					@update:model-value="
						(newValue: string) => $emit('update:validUntil', newValue)
					"
				/>
				<validated-text-field
					disabled
					:label="$t('identityProviderCodes.send_at')"
					:model-value="sendAt"
					name="sendAt"
					type="date"
				/>
				<validated-text-field
					disabled
					:label="$t('identityProviderCodes.used_at')"
					:model-value="usedAt"
					name="usedAt"
					type="date"
				/>
				<p
					v-if="
						id &&
						emailCodeRequests != undefined &&
						emailCodeRequests?.length > 0
					"
				>
					{{ $t("identityProviderCodes.history") }}
				</p>
				<v-skeleton-loader
					v-if="id"
					:loading="emailCodeRequestsLoading"
					type="card"
				>
					<v-list v-if="emailCodeRequests" density="compact">
						<v-list-item
							v-for="(emailCodeRequest, i) in emailCodeRequests"
							:key="i"
							:subtitle="getSubtitle(emailCodeRequest)"
							:title="'Email an: ' + emailCodeRequest.email"
						></v-list-item>
					</v-list>
				</v-skeleton-loader>
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn @click="resetForm">{{ $t("reset") }}</v-btn>
				<access-button
					:access-requirements="deleteRight"
					color="red"
					:disabled="
						id == undefined ||
						(usedAt != undefined && usedAt != null && usedAt != '')
					"
					variant="outlined"
					@click="emit('delete')"
					>{{ $t("delete") }}</access-button
				>
				<access-button
					:access-requirements="write_requirements"
					color="primary"
					:disabled="usedAt != undefined && usedAt != null && usedAt != ''"
					location="bottom right"
					type="submit"
					variant="outlined"
					>{{ $t("save") }}</access-button
				>
			</v-card-actions>
		</v-card>
	</form>
</template>

<script setup lang="ts">
	import { EmailCodeRequest, IdentityProvider } from "@de/IST.Cloud.Person_API";
	import rights from "@de/ist.cloud.rights";
	import { AccessButton } from "@de/ist.style_components_v3";
	import { useForm } from "vee-validate";
	import { ref } from "vue";
	import * as yup from "yup";

	import { getIdentityProviders } from "@/api/IdentityProviderAPI";
	import { formatDateWithTime } from "@/shared/DateUtils";

	import { sharedInputProps } from "../../shared/types/SharedInputProps";
	import ValidatedSelectSingle from "../validation/ValidatedSelectSingle.vue";
	import ValidatedTextField from "../validation/ValidatedTextField.vue";

	const deleteRight: rights = "IdentityProviderCode.Delete";
	const sendRight: rights = "IdentityProviderCode.Send";
	const disabledTooltipText =
		"Der Code ist noch nicht oder nicht mehr gültig oder bereits verwendet.";

	const props = defineProps({
		codeValue: {
			type: String,
			default: "",
		},
		emailCodeRequests: {
			type: Array as () => EmailCodeRequest[] | null | undefined,
			default: undefined,
		},
		emailCodeRequestsLoading: {
			type: Boolean,
			default: true,
		},
		personId: {
			type: String,
			default: "",
		},
		providerId: {
			type: String,
			default: "",
		},
		sendAt: {
			type: null,
			default: undefined,
		},
		showEmailDialog: {
			type: Boolean,
			default: false,
		},
		usedAt: {
			type: null,
			default: undefined,
		},
		validFrom: {
			type: null,
			default: undefined,
		},
		validUntil: {
			type: String,
			default: "",
		},
		...sharedInputProps,
	});

	const emit = defineEmits([
		"update:validUntil",
		"update:validFrom",
		"update:providerId",
		"update:personId",
		"update:showEmailDialog",
		"submit",
		"delete",
	]);

	const { handleSubmit, resetForm } = useForm({
		validationSchema: yup.object({
			codeValue: yup.string(),
			id: yup.string(),
			personId: yup.string().required(),
			providerId: yup.string().required(),
			validFrom: yup.string(),
			validUntil: yup.string().required(),
		}),
	});

	const onSubmit = handleSubmit(() => {
		emit("submit");
	});

	const allRole = ref<IdentityProvider[]>();

	getIdentityProviders(undefined, undefined).then((res) => {
		allRole.value = res.items;
	});

	const getSubtitle = (emailCodeRequest: EmailCodeRequest) => {
		if (emailCodeRequest.status == "ok") {
			return "gesendet am: " + formatDateWithTime(emailCodeRequest.send_at);
		}
		return (
			"gesendet am: " +
				formatDateWithTime(emailCodeRequest.send_at) +
				" mit dem Status: " +
				emailCodeRequest.status +
				" " +
				emailCodeRequest.error_message ?? ""
		);
	};

	const IsInvalidCode = () => {
		var validFrom = new Date(props.validFrom);
		var validUntil = new Date(props.validUntil);
		var usedAt = new Date(props.usedAt);
		var now = new Date();
		return (
			validFrom > now ||
			validUntil < now ||
			(usedAt != undefined && usedAt != null && usedAt > now)
		);
	};
</script>
