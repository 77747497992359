<template>
	<page-layout
		:back-title="$t('back')"
		back-title-link="./"
		:title="
			$t('roleToPerson.externalId') +
			' ' +
			person?.firstName +
			' ' +
			person?.lastName
		"
	>
		<external-id-form
			v-if="personToExternalId"
			v-model:external-id="personToExternalId.externalId"
			v-model:related-entity-id="roleToPersonId"
			v-model:source="personToExternalId.source"
			label="roleToPerson.id"
			@submit="save"
		></external-id-form>
	</page-layout>
</template>

<script setup lang="ts">
	import { CreateRoleToPersonToExternalIdRequest } from "@de/IST.Cloud.Permission_API";
	import { Person } from "@de/IST.Cloud.Person_API";
	import { PageLayout } from "@de/ist.style_components_v3";
	import { AxiosError } from "axios";
	import { ref } from "vue";
	import { useRoute, useRouter } from "vue-router";

	import { getPersonById } from "@/api/PersonAPI";
	import { createRoleToPersonToExternalId } from "@/api/RoleToPersonToExternalIdApi";
	import ExternalIdForm from "@/components/person/ExternalIdForm.vue";

	const personId = useRoute().params.id as string;
	const roleToPersonId = useRoute().params.roleToPersonId as string;

	const personToExternalId = ref<CreateRoleToPersonToExternalIdRequest>({
		roleToPersonId: roleToPersonId,
		source: "",
		externalId: "",
	});

	const router = useRouter();
	const save = () => {
		createRoleToPersonToExternalId(personToExternalId.value)
			.then((res) => {
				//TODO: add notification
				router.push({
					name: "roleToPersonToExternalIdView",
					params: {
						id: personId,
						externalId: res.data.id,
						roleToPersonId: roleToPersonId,
					},
				});
			})
			.catch((err: AxiosError) => {
				//TODO: add notification
				alert("Fehler beim Speichern");
			});
	};

	const person = ref<Person>();

	getPersonById(personId).then((res) => {
		person.value = res.data;
	});
</script>

<style scoped></style>
