<template>
	<page-layout
		:back-title="$t('back')"
		back-title-link="./"
		:title="$t('secrets.singularTitle')"
	>
		<secrets-form
			v-if="roleToPerson.organisation"
			v-model:name="roleToPerson.name"
			v-model:organisationId="roleToPerson.organisation.organisation_id"
			@submit="save"
		></secrets-form>
	</page-layout>
</template>

<script setup lang="ts">
	import { Secret } from "@de/IST.Cloud.Organisation_API";
	import { PageLayout } from "@de/ist.style_components_v3";
	import { useAuth } from "@de/ist-cloud-auth";
	import { AxiosError } from "axios";
	import { ref } from "vue";
	import { useRoute, useRouter } from "vue-router";

	import { createSecretForOrganisation } from "@/api/SecretAPI";
	import SecretsForm from "@/components/secret/SecretsForm.vue";

	const { customerId, customerName } = useAuth();
	const id = useRoute().params.id as string;

	const roleToPerson = ref<Secret>({
		client_id: "",
		customer: {
			id: customerId.value ?? "",
			name: customerName.value ?? "",
		},
		id: "",
		name: "",
		organisation: {
			organisation_id: id ?? "",
			organisation_name: "",
		},
		updated_at: "",
		was_shown: false,
	});

	const router = useRouter();
	const save = () => {
		createSecretForOrganisation(roleToPerson.value, id)
			.then((res) => {
				//TODO: add notification
				router.push({
					name: "secretToOrganisation",
					params: {
						id: id,
						secretId: res.data.id,
					},
				});
			})
			.catch((err: AxiosError) => {
				//TODO: add notification
				alert("Fehler beim Speichern");
			});
	};
</script>

<style scoped></style>
