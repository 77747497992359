<template>
	<form @submit="onSubmit">
		<v-card elevation="0">
			<v-card-text>
				<validated-text-field label="Id" :model-value="id" name="id" readonly />
				<validated-text-field
					:label="$t('organisation.id')"
					:model-value="organisationId"
					name="organisationId"
					readonly
				/>
				<validated-text-field
					:disabled="id.length > 0"
					:label="$t('secrets.name')"
					:model-value="name"
					name="name"
					@update:model-value="
						(newValue: string) => $emit('update:name', newValue)
					"
				/>
				<v-text-field
					v-if="id.length > 0"
					label="Client Id"
					:model-value="clientId"
					readonly
					><template #prepend-inner>
						<v-btn
							class="mr-3"
							:title="$t('copyToClipboard')"
							@click="copyClientId"
							><v-icon icon="mdi-content-copy"
						/></v-btn>
					</template>
				</v-text-field>
				<v-btn v-if="id.length > 0 && !hasShown" @click="revealSecret">{{
					$t("secrets.showPassword")
				}}</v-btn>
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn @click="resetForm">{{ $t("reset") }}</v-btn>
				<v-btn
					color="red"
					:disabled="id == undefined"
					variant="outlined"
					@click="emit('delete')"
					>{{ $t("delete") }}</v-btn
				>
				<v-btn
					color="primary"
					location="bottom right"
					type="submit"
					variant="outlined"
					>{{ $t("save") }}</v-btn
				>
			</v-card-actions>
		</v-card>
	</form>
	<v-dialog v-model="showSecret" height="280" persistent width="600">
		<v-card>
			<v-card-title>Passwort</v-card-title>
			<v-card-text
				>{{ $t("secrets.warningText")
				}}<v-text-field
					:label="$t('secrets.password')"
					:model-value="secretValue"
					readonly
					><template #prepend-inner>
						<v-btn
							class="mr-3"
							:title="$t('copyToClipboard')"
							@click="copySecret"
							><v-icon icon="mdi-content-copy"
						/></v-btn> </template></v-text-field
			></v-card-text>
			<v-card-actions>
				<v-spacer> </v-spacer>
				<v-btn color="primary" variant="outlined" @click="closeSecretDialog">{{
					$t("close")
				}}</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script setup lang="ts">
	import { useForm } from "vee-validate";
	import { ref } from "vue";
	import * as yup from "yup";

	import { retrieveAndDeleteSecret } from "../../api/SecretAPI";
	import { sharedInputProps } from "../../shared/types/SharedInputProps";
	import ValidatedTextField from "../validation/ValidatedTextField.vue";

	const showSecret = ref(false);
	const secretValue = ref("");

	const props = defineProps({
		clientId: {
			type: String,
			default: "",
		},
		hasShown: {
			type: Boolean,
			default: false,
		},
		name: {
			type: String,
			default: "",
		},
		organisationId: {
			type: String,
			default: "",
		},
		...sharedInputProps,
	});

	const emit = defineEmits(["update:name", "submit", "delete", "showedSecret"]);

	const { handleSubmit, resetForm } = useForm({
		validationSchema: yup.object({
			organisationId: yup.string().required(),
			name: yup.string().required(),
		}),
	});

	const onSubmit = handleSubmit(() => {
		emit("submit");
	});

	const revealSecret = () => {
		retrieveAndDeleteSecret(props.id).then((res) => {
			secretValue.value = res.data.value;
		});
		showSecret.value = true;
	};

	const closeSecretDialog = () => {
		showSecret.value = false;
		secretValue.value = "";
		emit("showedSecret");
	};

	const copyClientId = () => {
		navigator.clipboard.writeText(props.clientId);
	};

	const copySecret = () => {
		navigator.clipboard.writeText(secretValue.value);
	};
</script>

<style scoped></style>
