<template>
	<page-layout
		:back-title="$t('back')"
		back-title-link="../../roles"
		:title="$t('role.singularTitle')"
	>
		<role-form
			v-model:description="role.description"
			v-model:name="role.name"
			v-model:right-groups="role.rightGroups"
			v-model:rights-for-role="role.rights"
			v-model:short-name="role.shortName"
			:write_requirements="createRight"
			@submit="save"
		>
		</role-form>
	</page-layout>
</template>

<script setup lang="ts">
	import { Role } from "@de/IST.Cloud.Permission_API";
	import allRights from "@de/ist.cloud.rights";
	import { PageLayout } from "@de/ist.style_components_v3";
	import { AxiosError } from "axios";
	import { ref } from "vue";
	import { useRouter } from "vue-router";

	import { createRole } from "@/api/RoleAPI";
	import RoleForm from "@/components/role/RoleForm.vue";

	const createRight: allRights = "Role.Create";

	const role = ref<Role>({
		created_at: "",
		description: "",
		id: "",
		name: "",
		rights: [],
		shortName: "",
		updated_at: "",
	});

	const router = useRouter();
	const save = () => {
		createRole({
			description: role.value?.description,
			name: role.value?.name,
			rightGroups: role.value?.rightGroups ?? [],
			rights: role.value?.rights ?? [],
			shortName: role.value?.shortName,
		})
			.then((res) => {
				//TODO: add notification
				router.push({
					name: "role",
					params: { id: res.data.id },
				});
			})
			.catch((err: AxiosError) => {
				//TODO: add notification
				alert("Fehler beim Speichern");
			});
	};
</script>

<style scoped></style>
