import {
	Configuration,
	CreateRightRequest,
	Right,
	Rights,
	RightsApi,
	RightsApiGetRightsRequest,
} from "@de/IST.Cloud.Permission_API";
import { useAuth } from "@de/ist-cloud-auth";
import { AxiosResponse } from "axios";
import { v4 as uuidv4 } from "uuid";

import { ApiResult } from "@/shared/types/api/apiTypes";

const configuration = new Configuration({
	basePath: "/permission-api",
});

const rightsAPI = new RightsApi(configuration);

export type argsWithoutCustandSession = Omit<
	RightsApiGetRightsRequest,
	"istCustomerId" | "istSessionId"
>;

export async function getRights(
	args: argsWithoutCustandSession | undefined,
	cursor: string | undefined,
): Promise<ApiResult<Right>> {
	return await getRightsInternal({
		name: args?.name,
		limit: args?.limit ?? 3,
		sort: args?.sort ?? "",
		cursor: cursor,
	});
}

export async function getRightById(id: string) {
	const { roleId, organisationId, customerId } = useAuth();

	// maybe move this to an interceptor
	const headers = {
		"X-Role-ID": roleId.value ?? "",
		"X-Organisation-ID": organisationId.value ?? "",
	};

	return await rightsAPI.getRight(
		{
			id: id,
			istCustomerId: customerId.value ?? "",
			istSessionId: uuidv4(),
		},
		{
			headers: headers,
		},
	);
}

export async function deleteRightById(id: string | undefined) {
	const { roleId, organisationId, customerId } = useAuth();

	if (!id) {
		throw new Error("id is undefined");
	}

	// maybe move this to an interceptor
	const headers = {
		"X-Role-ID": roleId.value ?? "",
		"X-Organisation-ID": organisationId.value ?? "",
	};

	return await rightsAPI.deleteRight(
		{
			id: id,
			istCustomerId: customerId.value ?? "",
			istSessionId: uuidv4(),
		},
		{
			headers: headers,
		},
	);
}

export async function updateRight(
	id: string | undefined,
	org: Right | undefined,
) {
	if (!id || !org) {
		throw new Error("id is undefined");
	}
	const { roleId, organisationId, customerId } = useAuth();

	// maybe move this to an interceptor
	const headers = {
		"X-Role-ID": roleId.value ?? "",
		"X-Organisation-ID": organisationId.value ?? "",
	};

	return await rightsAPI.updateRight(
		{
			id: id,
			updateRightRequest: org,
			istCustomerId: customerId.value ?? "",
			istSessionId: uuidv4(),
		},
		{
			headers: headers,
		},
	);
}

export async function createRight(request: CreateRightRequest) {
	const { roleId, organisationId, customerId } = useAuth();

	// maybe move this to an interceptor
	const headers = {
		"X-Role-ID": roleId.value ?? "",
		"X-Organisation-ID": organisationId.value ?? "",
	};

	return await rightsAPI.createRight(
		{
			createRightRequest: {
				name: request.name,
				description: request.description,
			},
			istCustomerId: customerId.value ?? "",
			istSessionId: uuidv4(),
		},
		{
			headers: headers,
		},
	);
}

const getRightsInternal = async (
	args: argsWithoutCustandSession,
): Promise<ApiResult<Right>> => {
	const { roleId, organisationId, customerId } = useAuth();

	// maybe move this to an interceptor
	const headers = {
		"X-Role-ID": roleId.value ?? "",
		"X-Organisation-ID": organisationId.value ?? "",
	};

	if (args.cursor) {
		return convertApiResponse(
			await rightsAPI.getRights(
				{
					istCustomerId: customerId.value ?? "",
					istSessionId: uuidv4(),
					cursor: args.cursor,
				},
				{
					headers: headers,
				},
			),
		);
	}
	return convertApiResponse(
		await rightsAPI.getRights(
			{
				istCustomerId: customerId.value ?? "",
				istSessionId: uuidv4(),
				limit: args.limit,
				sort: args.sort,
			},
			{
				headers: headers,
			},
		),
	);
};

function convertApiResponse(
	response: AxiosResponse<Rights, any>,
): ApiResult<Right> {
	return {
		first: response.data.first,
		items: response.data.items,
		last: response.data.last,
		next: response.data.next,
		prev: response.data.prev,
		self: response.data.self,
		total: response.data.total,
	};
}
