import {
	Configuration,
	CreatePersonToExternalIdRequest,
	PersonToExternalId,
	PersonToExternalIdApi,
	PersonToExternalIdApiGetPersonToExternalIdsRequest,
	PersonToExternalIds,
} from "@de/IST.Cloud.Person_API";
import { useAuth } from "@de/ist-cloud-auth";
import { AxiosResponse } from "axios";
import { v4 as uuidv4 } from "uuid";
import { ref } from "vue";

import { ApiResult } from "@/shared/types/api/apiTypes";

const configuration = new Configuration({
	basePath: "/person-api",
});

const rolesAPI = new PersonToExternalIdApi(configuration);

export type argsWithoutCustandSession = Omit<
	PersonToExternalIdApiGetPersonToExternalIdsRequest,
	"istCustomerId" | "istSessionId"
>;

export function getPersonToExternalIds(
	args: argsWithoutCustandSession | undefined,
	cursor: string | undefined,
) {
	const data = ref<ApiResult<PersonToExternalId>>();
	const loading = ref<boolean>(true);

	getPersonToExternalIdsInternal({
		cursor: cursor,
		externalId: args?.externalId,
		limit: args?.limit ?? 3,
		personId: args?.personId,
		sort: args?.sort ?? "",
		source: args?.source,
	})
		.then((result) => {
			data.value = result;
		})
		.finally(() => {
			loading.value = false;
		});

	return {
		data,
		loading,
	};
}

export async function getPersonToExternalIdById(id: string) {
	const { roleId, organisationId } = useAuth();

	// maybe move this to an interceptor
	const headers = {
		"X-Role-ID": roleId.value ?? "",
		"X-Organisation-ID": organisationId.value ?? "",
	};

	return await rolesAPI.getPersonToExternalId(
		{
			id: id,
		},
		{
			headers: headers,
		},
	);
}

export async function deletePersonToExternalId(id: string | undefined) {
	const { roleId, organisationId } = useAuth();

	if (!id) {
		throw new Error("id is undefined");
	}

	// maybe move this to an interceptor
	const headers = {
		"X-Role-ID": roleId.value ?? "",
		"X-Organisation-ID": organisationId.value ?? "",
	};

	return await rolesAPI.deletePersonToExternalId(
		{
			id: id,
		},
		{
			headers: headers,
		},
	);
}

export async function updatePersonToExternalId(
	id: string | undefined,
	org: PersonToExternalId | undefined,
) {
	if (!id || !org) {
		throw new Error("id is undefined");
	}
	const { roleId, organisationId } = useAuth();

	// maybe move this to an interceptor
	const headers = {
		"X-Role-ID": roleId.value ?? "",
		"X-Organisation-ID": organisationId.value ?? "",
	};

	return await rolesAPI.updatePersonToExternalId(
		{
			id: id,
			updatePersonToetExternalIdRequest: org,
		},
		{
			headers: headers,
		},
	);
}

export async function createPersonToExternalId(
	request: CreatePersonToExternalIdRequest,
) {
	const { roleId, organisationId } = useAuth();

	// maybe move this to an interceptor
	const headers = {
		"X-Role-ID": roleId.value ?? "",
		"X-Organisation-ID": organisationId.value ?? "",
	};

	return await rolesAPI.createPersonToExternalId(
		{
			createPersonToExternalIdRequest: request,
			istSessionId: uuidv4(),
		},
		{
			headers: headers,
		},
	);

	//return result.data;
}

const getPersonToExternalIdsInternal = async (
	args: argsWithoutCustandSession,
): Promise<ApiResult<PersonToExternalId>> => {
	const { roleId, organisationId } = useAuth();

	// maybe move this to an interceptor
	const headers = {
		"X-Role-ID": roleId.value ?? "",
		"X-Organisation-ID": organisationId.value ?? "",
	};

	if (args.cursor) {
		return convertApiResponse(
			await rolesAPI.getPersonToExternalIds(
				{
					istSessionId: uuidv4(),
				},
				{
					headers: headers,
				},
			),
		);
	}
	return convertApiResponse(
		await rolesAPI.getPersonToExternalIds(
			{
				externalId: args.externalId,
				istSessionId: uuidv4(),
				limit: args.limit,
				personId: args.personId,
				sort: args.sort,
				source: args?.source,
			},
			{
				headers: headers,
			},
		),
	);
};

function convertApiResponse(
	response: AxiosResponse<PersonToExternalIds, any>,
): ApiResult<PersonToExternalId> {
	return {
		first: response.data.first,
		items: response.data.items,
		last: response.data.last,
		next: response.data.next,
		prev: response.data.prev,
		self: response.data.self,
		total: response.data.total,
	};
}
