import {
	Configuration,
	CreateEmailBulkRequest,
	CreateIdentityProviderCodeRequest,
	EmailCodeRequest,
	IdentityProviderCode,
	IdentityProviderCodes,
	IdentityProviderCodesApi,
	IdentityProviderCodesApiGetIdentityProviderCodesRequest,
} from "@de/IST.Cloud.Person_API";
import { useAuth } from "@de/ist-cloud-auth";
import { AxiosResponse } from "axios";
import { v4 as uuidv4 } from "uuid";
import { ref } from "vue";

import { ApiResult } from "@/shared/types/api/apiTypes";

const configuration = new Configuration({
	basePath: "/person-api",
});

const IdentityProviderCodeAPI = new IdentityProviderCodesApi(configuration);

export type GetIdentityProviderCodesRequestWithoutHeaders = Omit<
	IdentityProviderCodesApiGetIdentityProviderCodesRequest,
	"istCustomerId" | "istSessionId" | "xOrganisationID" | "xRoleID"
>;

export async function getIdentityProviderCodes(
	args: GetIdentityProviderCodesRequestWithoutHeaders | undefined,
	cursor: string | undefined,
): Promise<ApiResult<IdentityProviderCode>> {
	return await getIdentityProviderCodesInternal({
		limit: args?.limit ?? 3,
		sort: args?.sort ?? "",
		cursor: cursor,
		status: args?.status ?? "",
	});
}

export async function getIdentityProviderCodeById(id: string) {
	const { roleId, organisationId, customerId } = useAuth();

	// maybe move this to an interceptor
	const headers = {
		"X-Role-ID": roleId.value ?? "",
		"X-Organisation-ID": organisationId.value ?? "",
	};

	return await IdentityProviderCodeAPI.getIdentityProviderCode(
		{
			id: id,
			istCustomerId: customerId.value ?? "",
			istSessionId: uuidv4(),
		},
		{
			headers: headers,
		},
	);
}

export async function deleteIdentityProviderCode(id: string | undefined) {
	const { roleId, organisationId, customerId } = useAuth();

	if (!id) {
		throw new Error("id is undefined");
	}

	// maybe move this to an interceptor
	const headers = {
		"X-Role-ID": roleId.value ?? "",
		"X-Organisation-ID": organisationId.value ?? "",
	};

	return await IdentityProviderCodeAPI.deleteIdentityProviderCode(
		{
			id: id,
			istCustomerId: customerId.value ?? "",
			istSessionId: uuidv4(),
		},
		{
			headers: headers,
		},
	);
}

export async function updateIdentityProviderCode(
	id: string | undefined,
	org: IdentityProviderCode | undefined,
) {
	if (!id || !org) {
		throw new Error("id is undefined");
	}
	const { roleId, organisationId, customerId } = useAuth();

	// maybe move this to an interceptor
	const headers = {
		"X-Role-ID": roleId.value ?? "",
		"X-Organisation-ID": organisationId.value ?? "",
	};

	return await IdentityProviderCodeAPI.updateIdentityProviderCode(
		{
			id: id,
			updateIdentityProviderCodeRequest: org,
			istCustomerId: customerId.value ?? "",
			istSessionId: uuidv4(),
		},
		{
			headers: headers,
		},
	);
}

export async function createIdentityProviderCode(
	request: CreateIdentityProviderCodeRequest,
) {
	const { roleId, organisationId, customerId } = useAuth();

	// maybe move this to an interceptor
	const headers = {
		"X-Role-ID": roleId.value ?? "",
		"X-Organisation-ID": organisationId.value ?? "",
	};

	return await IdentityProviderCodeAPI.createIdentityProviderCode(
		{
			createIdentityProviderCodeRequest: request,
			istSessionId: uuidv4(),
			istCustomerId: customerId.value ?? "",
		},
		{
			headers: headers,
		},
	);

	//return result.data;
}

const getIdentityProviderCodesInternal = async (
	args: GetIdentityProviderCodesRequestWithoutHeaders,
): Promise<ApiResult<IdentityProviderCode>> => {
	const { roleId, organisationId, customerId } = useAuth();

	// maybe move this to an interceptor
	if (args.cursor) {
		return convertApiResponse(
			await IdentityProviderCodeAPI.getIdentityProviderCodes({
				cursor: args.cursor,
				istCustomerId: customerId.value ?? "",
				istSessionId: uuidv4(),
				xOrganisationID: organisationId.value ?? "",
				xRoleID: roleId.value ?? "",
			}),
		);
	}
	return convertApiResponse(
		await IdentityProviderCodeAPI.getIdentityProviderCodes({
			istCustomerId: customerId.value ?? "",
			istSessionId: uuidv4(),
			limit: args.limit,
			sort: args.sort,
			status: args.status,
			xOrganisationID: organisationId.value ?? "",
			xRoleID: roleId.value ?? "",
		}),
	);
};

function convertApiResponse(
	response: AxiosResponse<IdentityProviderCodes, any>,
): ApiResult<IdentityProviderCode> {
	return {
		first: response.data.first,
		items: response.data.items,
		last: response.data.last,
		next: response.data.next,
		prev: response.data.prev,
		self: response.data.self,
		total: response.data.total,
	};
}

export async function writeBulkIdentityProviderCodesForPerson(
	bulkRequest: CreateIdentityProviderCodeRequest[] | undefined,
) {
	const { roleId, organisationId, customerId } = useAuth();
	const headers = {
		"X-Role-ID": roleId.value ?? "",
		"X-Organisation-ID": organisationId.value ?? "",
	};

	if (!bulkRequest) {
		throw new Error("bulkRequest is undefined");
	}

	return await IdentityProviderCodeAPI.createBulkIdentityProviderCodes(
		{
			createIdentityProviderCodeBulkRequest: {
				items: bulkRequest,
			},
			istCustomerId: customerId.value ?? "",
			istSessionId: uuidv4(),
		},
		{ headers: headers },
	);
}

export async function sendEmailForIdentityProviderCode(
	id: string | undefined,
	templateId: string | undefined,
) {
	const { roleId, organisationId, customerId } = useAuth();
	const headers = {
		"X-Role-ID": roleId.value ?? "",
		"X-Organisation-ID": organisationId.value ?? "",
	};

	if (!id) {
		throw new Error("id is undefined");
	}

	return await IdentityProviderCodeAPI.sendMailForIdentityProviderCode(
		{
			istCustomerId: customerId.value ?? "",
			istSessionId: uuidv4(),
			id: id,
			createEmailCodeRequest: {
				template_id: templateId,
			},
		},
		{ headers: headers },
	);
}

export function getEmailRequestsForIdentityProviderCode(id: string) {
	const data = ref<EmailCodeRequest[]>();
	const loading = ref<boolean>(true);
	const { roleId, organisationId, customerId } = useAuth();
	const headers = {
		"X-Role-ID": roleId.value ?? "",
		"X-Organisation-ID": organisationId.value ?? "",
	};

	const loadData = () => {
		loading.value = true; // Set loading to true before making the API call

		IdentityProviderCodeAPI.getEmailRequestsForIdentityProviderCode(
			{
				istCustomerId: customerId.value ?? "",
				istSessionId: uuidv4(),
				id: id,
			},
			{ headers: headers },
		)
			.then((result) => {
				data.value = result.data;
			})
			.finally(() => {
				loading.value = false; // Set loading to false after the API call completes
			});
	};

	const reload = () => {
		loadData(); // Call the loadData function to reload the data
	};

	// Initial load of data
	loadData();

	return {
		data,
		loading,
		reload,
	};
}

export async function sendBulkEmailForIdentityProviderCodes(
	request: CreateEmailBulkRequest,
) {
	const { roleId, organisationId, customerId } = useAuth();
	const headers = {
		"X-Role-ID": roleId.value ?? "",
		"X-Organisation-ID": organisationId.value ?? "",
	};
	return await IdentityProviderCodeAPI.createBulkEmailRequests(
		{
			istCustomerId: customerId.value ?? "",
			istSessionId: uuidv4(),
			createEmailBulkRequest: request,
		},
		{ headers: headers },
	);
}
