import "@de/ist.style_components_v3/dist/ist.style_components_v3.css";

import {
	createStyleComponents,
	versionInjectionKey,
} from "@de/ist.style_components_v3";
import { createAuth } from "@de/ist-cloud-auth";
import { createPinia } from "pinia";
import { createApp } from "vue";

import ContentHeaderMenu from "@/components/layout/ContentHeaderMenu.vue";

import App from "./App.vue";
import { registerPlugins } from "./plugins";
import router from "./router";

const auth = createAuth({
	rightToUse: "IST.Manager",
});

const styleComponents = createStyleComponents();
const version = import.meta.env.VITE_APP_VERSION;

const app = createApp(App)
	.use(createPinia())
	.use(router)
	.use(auth)
	.use(styleComponents)
	.provide(versionInjectionKey, version)
	.component("ContentHeaderMenu", ContentHeaderMenu);

registerPlugins(app);
app.mount("#app");
