import "vuetify/styles";
import "@mdi/font/css/materialdesignicons.css";

import { istThemeLight } from "@de/ist.style_components_v3";
import { I18n, useI18n } from "vue-i18n";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import * as labsComponents from "vuetify/labs/components";
import { createVueI18nAdapter } from "vuetify/locale/adapters/vue-i18n";

export default function (i18n: I18n) {
	return createVuetify({
		components: {
			...components,
			...labsComponents,
		},
		defaults: {
			VTextField: {
				density: "compact",
				variant: "outlined",
			},
			VSelect: {
				density: "compact",
				variant: "outlined",
			},
		},
		directives,
		locale: {
			adapter: createVueI18nAdapter({ i18n, useI18n }),
		},
		theme: istThemeLight,
	});
}
