<template>
	<page-layout
		:back-title="$t('back')"
		back-title-link="../../roles"
		:title="$t('role.singularTitle')"
	>
		<role-form
			v-if="role"
			v-model:description="role.description"
			v-model:id="role.id"
			v-model:name="role.name"
			v-model:right-groups="role.rightGroups"
			v-model:rights-for-role="role.rights"
			v-model:short-name="role.shortName"
			:write_requirements="updateRight"
			@delete="deleteAction"
			@submit="save"
		>
		</role-form>
	</page-layout>
</template>

<script setup lang="ts">
	import { Role } from "@de/IST.Cloud.Permission_API";
	import allRights from "@de/ist.cloud.rights";
	import { PageLayout } from "@de/ist.style_components_v3";
	import { AxiosError } from "axios";
	import { ref } from "vue";
	import { useRoute, useRouter } from "vue-router";

	import { deleteRoleById, getRoleById, updateRole } from "@/api/RoleAPI";
	import RoleForm from "@/components/role/RoleForm.vue";

	const updateRight: allRights = "Role.Update";

	const id = useRoute().params.id as string;

	const role = ref<Role>();

	getRoleById(id).then((res) => {
		role.value = res.data;
	});

	const router = useRouter();
	const save = () => {
		updateRole(role.value?.id, role.value)
			.then(() => {})
			.catch((err: AxiosError) => {
				//TODO: add notification
				alert("Fehler beim Speichern");
			});
	};

	const deleteAction = () => {
		deleteRoleById(role.value?.id)
			.then(() => {
				router.push({
					name: "roles",
				});
			})
			.catch((err: AxiosError) => {
				//TODO: add notification
				alert("Fehler beim Speichern");
			});
	};
</script>

<style scoped></style>
