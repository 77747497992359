import {
	Configuration,
	CreateRoleRequest,
	Role,
	Roles,
	RolesApi,
	RolesApiGetRolesRequest,
} from "@de/IST.Cloud.Permission_API";
import { useAuth } from "@de/ist-cloud-auth";
import { AxiosResponse } from "axios";
import { v4 as uuidv4 } from "uuid";

import { ApiResult } from "@/shared/types/api/apiTypes";

const configuration = new Configuration({
	basePath: "/permission-api",
});

const rolesAPI = new RolesApi(configuration);

export type argsWithoutCustandSession = Omit<
	RolesApiGetRolesRequest,
	"istCustomerId" | "istSessionId"
>;

export async function getRoles(
	args: RolesApiGetRolesRequest | undefined,
	cursor: string | undefined,
): Promise<ApiResult<Role>> {
	return await getRolesInternal({
		name: args?.name ?? "",
		limit: args?.limit ?? 100,
		sort: args?.sort ?? "",
		cursor: cursor,
	});
}

export async function getRoleById(id: string) {
	const { roleId, organisationId, customerId } = useAuth();

	// maybe move this to an interceptor
	const headers = {
		"X-Role-ID": roleId.value ?? "",
		"X-Organisation-ID": organisationId.value ?? "",
	};

	return await rolesAPI.getRole(
		{
			id: id,
			istCustomerId: customerId.value ?? "",
			istSessionId: uuidv4(),
		},
		{
			headers: headers,
		},
	);
}

export async function deleteRoleById(id: string | undefined) {
	const { roleId, organisationId, customerId } = useAuth();

	if (!id) {
		throw new Error("id is undefined");
	}

	// maybe move this to an interceptor
	const headers = {
		"X-Role-ID": roleId.value ?? "",
		"X-Organisation-ID": organisationId.value ?? "",
	};

	return await rolesAPI.deleteRole(
		{
			id: id,
			istCustomerId: customerId.value ?? "",
			istSessionId: uuidv4(),
		},
		{
			headers: headers,
		},
	);
}

export async function updateRole(
	id: string | undefined,
	org: Role | undefined,
) {
	if (!id || !org) {
		throw new Error("id is undefined");
	}
	const { roleId, organisationId, customerId } = useAuth();

	// maybe move this to an interceptor
	const headers = {
		"X-Role-ID": roleId.value ?? "",
		"X-Organisation-ID": organisationId.value ?? "",
	};

	return await rolesAPI.updateRole(
		{
			id: id,
			updateRoleRequest: org,
			istCustomerId: customerId.value ?? "",
			istSessionId: uuidv4(),
		},
		{
			headers: headers,
		},
	);
}

export async function createRole(request: CreateRoleRequest) {
	const { roleId, organisationId, customerId } = useAuth();

	// maybe move this to an interceptor
	const headers = {
		"X-Role-ID": roleId.value ?? "",
		"X-Organisation-ID": organisationId.value ?? "",
	};

	return await rolesAPI.createRole(
		{
			createRoleRequest: request,
			istCustomerId: customerId.value ?? "",
			istSessionId: uuidv4(),
		},
		{
			headers: headers,
		},
	);

	//return result.data;
}

const getRolesInternal = async (
	args: argsWithoutCustandSession,
): Promise<ApiResult<Role>> => {
	const { roleId, organisationId, customerId } = useAuth();

	// maybe move this to an interceptor
	const headers = {
		"X-Role-ID": roleId.value ?? "",
		"X-Organisation-ID": organisationId.value ?? "",
	};

	if (args.cursor) {
		return convertApiResponse(
			await rolesAPI.getRoles(
				{
					istCustomerId: customerId.value ?? "",
					istSessionId: uuidv4(),
					cursor: args.cursor,
				},
				{
					headers: headers,
				},
			),
		);
	}
	return convertApiResponse(
		await rolesAPI.getRoles(
			{
				istCustomerId: customerId.value ?? "",
				istSessionId: uuidv4(),
				limit: args.limit,
				name: args.name,
				sort: args.sort,
			},
			{
				headers: headers,
			},
		),
	);
};

function convertApiResponse(
	response: AxiosResponse<Roles, any>,
): ApiResult<Role> {
	return {
		first: response.data.first,
		items: response.data.items,
		last: response.data.last,
		next: response.data.next,
		prev: response.data.prev,
		self: response.data.self,
		total: response.data.total,
	};
}
