import { ResourcePath } from "@intlify/core-base";
import { createI18n } from "vue-i18n";
import { de as vuetifyDe, en as vuetifyEn } from "vuetify/locale";

import translations from "@/shared/translations/";

const innerMessages = {
	de: {
		...translations.de,
		$vuetify: vuetifyDe,
	},
	en: {
		...translations.en,
		$vuetify: vuetifyEn,
	},
} as const;

export type Language = keyof typeof innerMessages;
export type MessageSchema = typeof innerMessages.en;

export type TranslationLabel = ResourcePath<MessageSchema>;

export default createI18n({
	fallbackLocale: "de",
	locale: "de",
	messages: innerMessages,
});
