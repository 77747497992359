<template>
	<page-layout :title="$t('navigation.identityProviderCodes')">
		<v-row>
			<v-col cols="3"
				><v-select
					v-model="selectedStatus"
					clearable
					:items="availableStatus"
					label="Status Filter"
					multiple
				></v-select
			></v-col>
			<v-spacer></v-spacer>
			<v-col cols="1">
				<access-button
					:access-requirements="sendRight"
					color="primary"
					icon
					:title="$t('identityProviderCodes.send_email')"
					variant="text"
					@click="
						$router.push({
							name: 'sendMailIdentityProviderCodeBulk',
						})
					"
					><v-icon>mdi-email</v-icon></access-button
				>
				<access-button
					:access-requirements="sendRight"
					color="primary"
					disabled
					icon
					:title="$t('identityProviderCodes.print')"
					variant="text"
					><v-icon>mdi-printer</v-icon></access-button
				>
			</v-col>
		</v-row>
		<paginated-table
			v-model="options.itemsPerPage"
			:headers="headers"
			:initial-sort="options.sortBy"
			:loading="state.loading"
			:state="state"
			:total="state.total"
			@change-sort="changeSort($event)"
			@click:row="onRowClick"
		>
			<template #[`item.valid_from`]="{ item }">
				{{ formatDate(item.valid_from) }}
			</template>
			<template #[`item.valid_until`]="{ item }"
				>{{ formatDate(item.valid_until) }}
			</template>
			<template #[`item.send_at`]="{ item }">
				{{ formatDate(item.send_at) }}</template
			>
			<template #[`item.used_at`]="{ item }">
				{{ formatDate(item.used_at) }}</template
			>
		</paginated-table>
		<access-button
			:access-requirements="createRight"
			class="mb-5 mr-5"
			color="primary"
			icon
			location="bottom right"
			position="fixed"
			:title="$t('role.create')"
			@click="
				$router.push({
					name: 'createIdentityProviderCodeBulk',
				})
			"
		>
			<v-icon>mdi-plus</v-icon>
		</access-button>
	</page-layout>
</template>

<script setup lang="ts">
	import { IdentityProviderCode } from "@de/IST.Cloud.Person_API";
	import rights from "@de/ist.cloud.rights";
	import { AccessButton, PageLayout } from "@de/ist.style_components_v3";
	import { refThrottled } from "@vueuse/core";
	import { ref } from "vue";
	import { useRouter } from "vue-router";

	import { getIdentityProviderCodes } from "../../api/IdentityProviderCodeAPI";
	import PaginationApiReader from "../../api/PaginationApiReader";
	import { useDateTimeFormat } from "../../shared/DateUtils";
	import PaginatedTable from "../../shared/table/PaginatedTable.vue";
	import { Headers } from "../../shared/types/table/HeadersType";
	import { OptionsType } from "../../shared/types/table/SortType";

	const { format: formatDate } = useDateTimeFormat();

	const createRight: rights = "IdentityProviderCode.Create";
	const sendRight: rights = "IdentityProviderCode.Send";
	const SEARCH_THROTTLED_MS = 1000;

	const sortString = ref<string>("");

	const selectedStatus = ref<string[]>([]);
	const availableStatus = ref<string[]>(["used", "send", "created"]);
	const searchThrottled = refThrottled(selectedStatus, SEARCH_THROTTLED_MS);

	const headers = ref<Headers>([
		{
			key: "personRef.first_name",
			title: "Vorname",
		},
		{
			key: "personRef.last_name",
			title: "Nachname",
		},
		{
			key: "identityProviderRef.name",
			title: "Identitätsprovider",
		},
		{
			key: "valid_from",
			title: "gültig von",
		},
		{
			key: "valid_until",
			title: "gültig bis",
		},
		{
			key: "used_at",
			title: "verwendet am",
		},
		{
			key: "send_at",
			title: "gesendet am",
		},
	]);

	// here is the default sorting and pagination when the page is loaded
	const options = ref<OptionsType>({
		sortBy: [{ key: "name", order: "desc" }],
		itemsPerPage: 10,
	});

	const r = new PaginationApiReader<IdentityProviderCode>();
	const { state } = r.call(getIdentityProviderCodes, (provideParameters) =>
		provideParameters({
			limit: options.value.itemsPerPage,
			sort: sortString.value,
			status: searchThrottled.value.join(","),
		}),
	);

	const changeSort = (opt: string) => {
		sortString.value = opt;
	};

	const router = useRouter();

	const onRowClick = (event: IdentityProviderCode) => {
		router.push({
			name: "identityProviderCode",
			params: {
				id: event.personRef.id,
				identityProviderCodesId: event.id,
			},
		});
	};
</script>

<style scoped></style>
