import moment from "moment";
import { inject, toValue } from "vue";

import { localeInjectionKey } from "./injectionKeys";

export function formatDate(date?: string | null) {
	return formatDateWithFormat("DD.MM.YYYY", date);
}

export function formatDateWithFormat(
	stringFormat: string,
	date?: string | null,
) {
	if (date == null || date == "") return "";
	return moment(date).format(stringFormat);
}

export function formatDateWithTime(date?: string | null) {
	return formatDateWithFormat("DD.MM.YYYY HH:mm:ss", date);
}

export function useDateTimeFormat() {
	const locale = inject(localeInjectionKey);

	function format(date?: string) {
		if (!date) {
			return "";
		}

		return Intl.DateTimeFormat(toValue(locale), {
			day: "2-digit",
			month: "2-digit",
			year: "numeric",
		}).format(new Date(date));
	}

	return {
		format,
	};
}
