import {
	Configuration,
	CreateEmailTemplateRequest,
	EmailTemplate,
	EmailTemplates,
	EmailTemplatesApi,
	EmailTemplatesApiGetEmailTemplatesRequest,
} from "@de/IST.Cloud.Person_API";
import { useAuth } from "@de/ist-cloud-auth";
import { AxiosResponse } from "axios";
import { v4 as uuidv4 } from "uuid";

import { ApiResult } from "@/shared/types/api/apiTypes";

const configuration = new Configuration({
	basePath: "/person-api",
});

const emailTemplateAPI = new EmailTemplatesApi(configuration);

export type argsWithoutCustandSession = Omit<
	EmailTemplatesApiGetEmailTemplatesRequest,
	"istCustomerId" | "istSessionId"
>;

export async function getEmailTemplates(
	args: argsWithoutCustandSession | undefined,
	cursor: string | undefined,
): Promise<ApiResult<EmailTemplate>> {
	return await getEmailTemplatesInternal({
		limit: args?.limit ?? 10,
		sort: args?.sort ?? "",
		cursor: cursor,
		name: args?.name ?? "",
	});
}

export async function getEmailTemplateById(id: string) {
	const { roleId, organisationId } = useAuth();

	// maybe move this to an interceptor
	const headers = {
		"X-Role-ID": roleId.value ?? "",
		"X-Organisation-ID": organisationId.value ?? "",
	};

	return await emailTemplateAPI.getEmailTemplate(
		{
			id: id,
		},
		{
			headers: headers,
		},
	);
}

export async function deleteEmailTemplate(id: string | undefined) {
	const { roleId, organisationId } = useAuth();

	if (!id) {
		throw new Error("id is undefined");
	}

	// maybe move this to an interceptor
	const headers = {
		"X-Role-ID": roleId.value ?? "",
		"X-Organisation-ID": organisationId.value ?? "",
	};

	return await emailTemplateAPI.deletesEmailTemplate(
		{
			id: id,
		},
		{
			headers: headers,
		},
	);
}

export async function updateEmailTemplate(
	id: string | undefined,
	org: EmailTemplate | undefined,
) {
	if (!id || !org) {
		throw new Error("id is undefined");
	}
	const { roleId, organisationId } = useAuth();

	// maybe move this to an interceptor
	const headers = {
		"X-Role-ID": roleId.value ?? "",
		"X-Organisation-ID": organisationId.value ?? "",
	};

	return await emailTemplateAPI.updateEmailRequest(
		{
			id: id,
			updateEmailTemplateRequest: org,
		},
		{
			headers: headers,
		},
	);
}

export async function createEmailtemplate(request: CreateEmailTemplateRequest) {
	const { roleId, organisationId, customerId } = useAuth();

	// maybe move this to an interceptor
	const headers = {
		"X-Role-ID": roleId.value ?? "",
		"X-Organisation-ID": organisationId.value ?? "",
	};

	return await emailTemplateAPI.createIEmailTemplates(
		{
			createEmailTemplateRequest: request,
			istSessionId: uuidv4(),
			istCustomerId: customerId.value ?? "",
		},
		{
			headers: headers,
		},
	);

	//return result.data;
}

const getEmailTemplatesInternal = async (
	args: argsWithoutCustandSession,
): Promise<ApiResult<EmailTemplate>> => {
	const { roleId, organisationId, customerId } = useAuth();

	// maybe move this to an interceptor
	const headers = {
		"X-Role-ID": roleId.value ?? "",
		"X-Organisation-ID": organisationId.value ?? "",
	};

	if (args.cursor) {
		return convertApiResponse(
			await emailTemplateAPI.getEmailTemplates(
				{
					istSessionId: uuidv4(),
					cursor: args.cursor,
					istCustomerId: customerId.value ?? "",
				},
				{
					headers: headers,
				},
			),
		);
	}
	return convertApiResponse(
		await emailTemplateAPI.getEmailTemplates(
			{
				istCustomerId: customerId.value ?? "",
				istSessionId: uuidv4(),
				limit: args.limit,
				name: args.name,
				sort: args.sort,
			},
			{
				headers: headers,
			},
		),
	);
};

function convertApiResponse(
	response: AxiosResponse<EmailTemplates, any>,
): ApiResult<EmailTemplate> {
	return {
		first: response.data.first,
		items: response.data.items,
		last: response.data.last,
		next: response.data.next,
		prev: response.data.prev,
		self: response.data.self,
		total: response.data.total,
	};
}
