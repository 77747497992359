import rights from "@de/ist.cloud.rights";
import { NotFound } from "@de/ist.style_components_v3";
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

import { baseAddress } from "@/config";
import CustomerTable from "@/views/customer/CustomerTable.vue";
import EmailTemplateCreateView from "@/views/emailTemplate/EmailTemplateCreateView.vue";
import EmailTemplateDetailView from "@/views/emailTemplate/EmailTemplateDetailView.vue";
import EmailTemplateTable from "@/views/emailTemplate/EmailTemplateTable.vue";
import IdentityProviderCreateView from "@/views/identityProvider/IdentityProviderCreateView.vue";
import IdentityProviderDetailView from "@/views/identityProvider/IdentityProviderDetailView.vue";
import IdentityProviderTable from "@/views/identityProvider/IdentityProviderTable.vue";
import IdentityProviderCodeBulkCreateView from "@/views/identityProviderCode/IdentityProviderCodeBulkCreateView.vue";
import IdentityProviderCodeBulkEmailSendView from "@/views/identityProviderCode/IdentityProviderCodeBulkEmailSendView.vue";
import IdentityProviderCodeCreateView from "@/views/identityProviderCode/IdentityProviderCodeCreateView.vue";
import IdentityProviderCodeDetailView from "@/views/identityProviderCode/IdentityProviderCodeDetailView.vue";
import IdentityProviderCodeTable from "@/views/identityProviderCode/IdentityProviderCodeTable.vue";
import CreateOrganisationView from "@/views/organisation/OrganisationCreateView.vue";
import OrganisationDetailView from "@/views/organisation/OrganisationDetailView.vue";
import OrganisationTable from "@/views/organisation/OrganisationTable.vue";
import SecretToOrganisationCreateView from "@/views/organisation/SecretToOrganisationCreateView.vue";
import SecretToOrganisationDetailView from "@/views/organisation/SecretToOrganisationDetailView.vue";
import PersonCreateView from "@/views/person/PersonCreateView.vue";
import PersonDetailView from "@/views/person/PersonDetailView.vue";
import PersonTable from "@/views/person/PersonTable.vue";
import PersonToExternalIdCreateView from "@/views/person/PersonToExternalIdCreateView.vue";
import PersonToExternalIdDetailView from "@/views/person/PersonToExternalIdDetailView.vue";
import PersonToIdentityProviderCreateView from "@/views/person/PersonToIdentityProviderCreateView.vue";
import PersonToIdentityProviderDetailView from "@/views/person/PersonToIdentityProviderDetailView.vue";
import RoleToPersonCreateView from "@/views/person/RoleToPersonCreateView.vue";
import RoleToPersonDetailView from "@/views/person/RoleToPersonDetailView.vue";
import RoleToPersonToExternalIdCreateView from "@/views/person/RoleToPersonToExternalIdCreateView.vue";
import RoleToPersonToExternalIdDetailView from "@/views/person/RoleToPersonToExternalIdDetailView.vue";
import RightCreateView from "@/views/right/RightCreateView.vue";
import RightDetailView from "@/views/right/RightDetailView.vue";
import RightTable from "@/views/right/RightTable.vue";
import RightGroupCreateView from "@/views/rightGroup/RightGroupCreateView.vue";
import RightGroupDetailView from "@/views/rightGroup/RightGroupDetailView.vue";
import RightGroupTable from "@/views/rightGroup/RightGroupTable.vue";
import RoleCreateView from "@/views/role/RoleCreateView.vue";
import RoleDetailView from "@/views/role/RoleDetailView.vue";
import RoleTable from "@/views/role/RoleTable.vue";

const organisationReadRight: rights = "Organisation.Read";
const personReadRight: rights = "Person.Read";
const identityProviderReadRight: rights = "IdentityProvider.Read";
const identityProviderCodeReadRight: rights = "IdentityProviderCode.Send";
const emailTemplatesReadRight: rights = "EmailTemplates.Read";
const roleReadRight: rights = "Role.Read";
const rightReadRight: rights = "Right.Read";
const rightGroupReadRight: rights = "RightGroups.Read";

const routes: Array<RouteRecordRaw> = [
	{
		path: "/",
		name: "default",
		redirect: { name: "customers" },
	},
	{
		path: "/customers",
		name: "customers",
		component: CustomerTable,
		meta: {
			requireAuth: true,
		},
	},
	{
		path: "/organisation/:id",
		name: "organisation",
		component: OrganisationDetailView,
		meta: {
			requireAuth: true,
		},
	},
	{
		path: "/organisation",
		name: "createOrganisation",
		component: CreateOrganisationView,
		meta: {
			requireAuth: true,
		},
	},
	{
		path: "/organisations",
		name: "organisations",
		component: OrganisationTable,
		meta: {
			requireAuth: true,
			requiresRights: [organisationReadRight],
		},
	},
	{
		path: "/organisation/:id/secrets/:secretId",
		name: "secretToOrganisation",
		component: SecretToOrganisationDetailView,
		meta: {
			requireAuth: true,
		},
	},
	{
		path: "/organisation/:id/secrets",
		name: "createSecretForOrganisation",
		component: SecretToOrganisationCreateView,
		meta: {
			requireAuth: true,
		},
	},
	{
		path: "/persons",
		name: "persons",
		component: PersonTable,
		meta: {
			requireAuth: true,
			requiresRights: [personReadRight],
		},
	},
	{
		path: "/person/:id",
		name: "person",
		component: PersonDetailView,
		meta: {
			requireAuth: true,
		},
	},
	{
		path: "/person",
		name: "createPerson",
		component: PersonCreateView,
		meta: {
			requireAuth: true,
		},
	},
	{
		path: "/roles",
		name: "roles",
		component: RoleTable,
		meta: {
			requireAuth: true,
			requireRights: [roleReadRight],
		},
	},
	{
		path: "/role/:id",
		name: "role",
		component: RoleDetailView,
		meta: {
			requireAuth: true,
		},
	},
	{
		path: "/role",
		name: "createRole",
		component: RoleCreateView,
		meta: {
			requireAuth: true,
		},
	},
	{
		path: "/rights",
		name: "rights",
		component: RightTable,
		meta: {
			requireAuth: true,
			requiresRights: [rightReadRight],
		},
	},
	{
		path: "/right/:id",
		name: "right",
		component: RightDetailView,
		meta: {
			requireAuth: true,
		},
	},
	{
		path: "/right",
		name: "createRight",
		component: RightCreateView,
		meta: {
			requireAuth: true,
		},
	},
	{
		path: "/rightGroups",
		name: "rightGroups",
		component: RightGroupTable,
		meta: {
			requireAuth: true,
			requiresRights: [rightGroupReadRight],
		},
	},
	{
		path: "/rightGroup/:id",
		name: "rightGroup",
		component: RightGroupDetailView,
		meta: {
			requireAuth: true,
		},
	},
	{
		path: "/rightGroup",
		name: "createRightGroup",
		component: RightGroupCreateView,
		meta: {
			requireAuth: true,
		},
	},
	{
		path: "/person/:id/roleToPerson/:roleToPersonId",
		name: "roleToPerson",
		component: RoleToPersonDetailView,
		meta: {
			requireAuth: true,
		},
	},
	{
		path: "/person/:id/roleToPerson",
		name: "createRoleToPerson",
		component: RoleToPersonCreateView,
		meta: {
			requireAuth: true,
		},
	},
	{
		path: "/person/:id/roleToPerson/:roleToPersonId/personToExternalId/:externalId",
		name: "roleToPersonToExternalIdView",
		component: RoleToPersonToExternalIdDetailView,
		meta: {
			requireAuth: true,
		},
	},
	{
		path: "/person/:id/roleToPerson/:roleToPersonId/personToExternalId",
		name: "createRoleToPersonToExternalId",
		component: RoleToPersonToExternalIdCreateView,
		meta: {
			requireAuth: true,
		},
	},
	{
		path: "/identityProviders",
		name: "identityProviders",
		component: IdentityProviderTable,
		meta: {
			requireAuth: true,
			requiresRights: [identityProviderReadRight],
		},
	},
	{
		path: "/identityProvider/:id",
		name: "identityProvider",
		component: IdentityProviderDetailView,
		meta: {
			requireAuth: true,
		},
	},
	{
		path: "/identityProvider",
		name: "createIdentityProvider",
		component: IdentityProviderCreateView,
		meta: {
			requireAuth: true,
		},
	},
	{
		path: "/identityProviderCodes",
		name: "identityProviderCodes",
		component: IdentityProviderCodeTable,
		meta: {
			requireAuth: true,
			requiresRights: [identityProviderCodeReadRight],
		},
	},
	{
		path: "/person/:id/identityProviderCodes/:identityProviderCodesId",
		name: "identityProviderCode",
		component: IdentityProviderCodeDetailView,
		meta: {
			requireAuth: true,
		},
	},
	{
		path: "/person/:id/identityProviderCode",
		name: "createIdentityProviderCode",
		component: IdentityProviderCodeCreateView,
		meta: {
			requireAuth: true,
		},
	},
	{
		path: "/identityProviderCodeBulk",
		name: "createIdentityProviderCodeBulk",
		component: IdentityProviderCodeBulkCreateView,
		meta: {
			requireAuth: true,
		},
	},
	{
		path: "/identityProviderCodeBulkEmail",
		name: "sendMailIdentityProviderCodeBulk",
		component: IdentityProviderCodeBulkEmailSendView,
		meta: {
			requireAuth: true,
		},
	},
	{
		path: "/:catchAll(.*)",
		name: "NotFound",
		component: NotFound,
	},
	{
		path: "/person/:id/personToIdentityProvider/:personToIdentityProviderId",
		name: "personToIdentityProvider",
		component: PersonToIdentityProviderDetailView,
		meta: {
			requireAuth: true,
		},
	},
	{
		path: "/person/:id/personToIdentityProvider",
		name: "createPersonToIdentityProvider",
		component: PersonToIdentityProviderCreateView,
		meta: {
			requireAuth: true,
		},
	},
	{
		path: "/person/:id/personToExternalId/:externalId",
		name: "personToExternalId",
		component: PersonToExternalIdDetailView,
		meta: {
			requireAuth: true,
		},
	},
	{
		path: "/person/:id/personToExternalId",
		name: "createPersonToExternalId",
		component: PersonToExternalIdCreateView,
		meta: {
			requireAuth: true,
		},
	},
	{
		path: "/emailTemplates",
		name: "emailTemplates",
		component: EmailTemplateTable,
		meta: {
			requireAuth: true,
			requiresRights: [emailTemplatesReadRight],
		},
	},
	{
		path: "/emailTemplates/:id",
		name: "emailTemplate",
		component: EmailTemplateDetailView,
		meta: {
			requireAuth: true,
		},
	},
	{
		path: "/emailTemplate",
		name: "createEmailtemplate",
		component: EmailTemplateCreateView,
		meta: {
			requireAuth: true,
		},
	},
];

const router = createRouter({
	history: createWebHistory(baseAddress),
	routes,
});

export default router;
