<template>
	<page-layout
		:back-title="$t('back')"
		back-title-link="identityProviderCodes"
		:title="$t('identityProviderCodes.create')"
	>
		<v-row>
			<v-col>
				<validated-text-field
					v-model="valid_from"
					:label="$t('identityProviderCodes.valid_from')"
					name="valid_until"
					type="date"
				>
				</validated-text-field>
			</v-col>
			<v-col>
				<validated-text-field
					v-model="valid_until"
					:label="$t('identityProviderCodes.valid_until')"
					name="valid_until"
					type="date"
				>
				</validated-text-field>
			</v-col>
			<v-col>
				<validated-select-single
					v-model="providerId"
					:items="allRole"
					itemtext="name"
					itemvalue="id"
					:label="$t('identityProvider.singularTitle')"
					name="providerId"
				></validated-select-single>
			</v-col>
		</v-row>
		<v-row>
			<v-data-table
				v-if="persons"
				v-model="selectedPersons"
				density="compact"
				:headers="headers"
				:items="persons"
				:loading="loading"
				show-select
			>
			</v-data-table>
		</v-row>
		<v-row>
			<v-col cols="10"></v-col>
			<v-col cols="2">
				<access-button
					:access-requirements="createRight"
					color="primary"
					:disabled="disabled"
					location="bottom right"
					type="submit"
					variant="outlined"
					@click="save"
					>{{ $t("save") }}</access-button
				></v-col
			>
		</v-row>
	</page-layout>
	<notification-snackbar
		v-model="showSnackbar"
		:color="snackBarColor"
		:show-copy="false"
		:text="snackBarText"
		:timeout="snackBarTimeout"
		:title="snackBarTitle"
	></notification-snackbar>
</template>

<script setup lang="ts">
	import {
		CreateIdentityProviderCodeRequest,
		IdentityProvider,
		Person,
	} from "@de/IST.Cloud.Person_API";
	import allRights from "@de/ist.cloud.rights";
	import {
		AccessButton,
		NotificationSnackbar,
		PageLayout,
	} from "@de/ist.style_components_v3";
	import moment from "moment";
	import { ref } from "vue";
	import { computed } from "vue";

	import { getIdentityProviders } from "@/api/IdentityProviderAPI";
	import { getPersons } from "@/api/PersonAPI";
	import ValidatedSelectSingle from "@/components/validation/ValidatedSelectSingle.vue";
	import ValidatedTextField from "@/components/validation/ValidatedTextField.vue";
	import { Headers } from "@/shared/types/table/HeadersType";

	import { writeBulkIdentityProviderCodesForPerson } from "../../api/IdentityProviderCodeAPI";

	const valid_from = ref<string>(moment().format("YYYY-MM-DD"));
	const valid_until = ref<string>("");
	const providerId = ref<string>("");
	const persons = ref<Person[]>();
	const selectedPersons = ref<string[]>([]);
	const showSnackbar = ref<boolean>(false);
	const snackBarText = ref<string>("");
	const snackBarTitle = ref<string>("");
	const snackBarTimeout = ref<number>(4000);
	const snackBarColor = ref<string>("primary");
	const loading = ref<boolean>(false);

	const disabled = computed(() => {
		return (
			selectedPersons.value.length === 0 ||
			providerId.value.length === 0 ||
			valid_until.value.length === 0
		);
	});

	const headers = ref<Headers>([
		{
			key: "firstName",
			title: "Vorname",
		},
		{
			key: "lastName",
			title: "Nachname",
		},
		{
			key: "email",
			title: "E-Mail",
		},
		{
			key: "externalId",
			title: "Externe ID",
		},
	]);

	const createRight: allRights = "IdentityProviderCode.Create";

	const bulkRequest = ref<CreateIdentityProviderCodeRequest[]>([]);

	const save = () => {
		loading.value = true;
		bulkRequest.value = selectedPersons.value.map((personId) => {
			return {
				identity_provider_id: providerId.value,
				person_id: personId,
				valid_until: valid_until.value,
				valid_from: valid_from.value,
			};
		});
		snackBarTitle.value = "";
		snackBarText.value = "";
		writeBulkIdentityProviderCodesForPerson(bulkRequest.value)
			.then((res) => {
				var errorOccured = false;
				var errors: string[] = [];
				res.data.items.forEach((element) => {
					if (element.status != undefined && element.status != "200") {
						errorOccured = true;
						errors.push(element.error_message ?? "");
					}
				});
				if (!errorOccured) {
					snackBarTitle.value = "Erfolgreich";
					snackBarText.value = "Identitätsprovidercode erfolgreich erstellt";
					showSnackbar.value = true;
				} else {
					snackBarTitle.value = "Fehler";
					snackBarText.value =
						"Beim speichern sind Fehler aufgetreten " + errors;
				}
				showSnackbar.value = true;
			})
			.catch((err) => {
				snackBarTitle.value = "Fehler";
				snackBarText.value = "Beim speichern sind Probleme aufgetreten" + err;
				snackBarTimeout.value = -1;
				snackBarColor.value = "error";
				showSnackbar.value = true;
			})
			.finally(() => {
				loading.value = false;
			});
	};

	const allRole = ref<IdentityProvider[]>();

	getIdentityProviders(undefined, undefined).then((res) => {
		allRole.value = res.items;
	});

	getPersons(
		{
			limit: 100,
			sort: "",
		},
		undefined,
	).then((res) => {
		persons.value = res.items;
	});
</script>

<style scoped></style>
