import { computed, ref, watch, watchEffect } from "vue";

import { ApiResult } from "@/shared/types/api/apiTypes";

export default class PaginationApiReader<T> {
	public hasPage = (
		p: keyof Omit<ApiResult<T>, "current" | "items" | "size" | "total">,
		apiResult: ApiResult<T> | undefined,
	) => {
		if (apiResult) {
			const value = apiResult[p];
			if (value) {
				return value.length > 0;
			}
		}
		return false;
	};

	public call<K extends { cursor?: string }>(
		getTest: (p: K | undefined, c: string | undefined) => Promise<ApiResult<T>>,
		buildParameters: (provideParameters: (params: K) => void) => void,
	) {
		const cursor = ref<string>();
		const loading = ref<boolean>(false);
		const parameters = ref<K>();

		const apiResult = ref<ApiResult<T>>();

		const reload = () => {
			if (cursor.value != undefined) {
				loading.value = true;
				getTest(parameters.value, cursor.value)
					.then((res) => {
						apiResult.value = res;
					})
					.finally(() => {
						loading.value = false;
					});
			} else {
				loading.value = true;
				getTest(parameters.value, undefined)
					.then((res) => {
						apiResult.value = res;
					})
					.finally(() => {
						loading.value = false;
					});
			}
		};

		watchEffect(() => {
			if (parameters.value !== undefined) {
				// A parameter dependency has changed. The cursor is useless now
				cursor.value = undefined;
			}
			if (cursor.value != undefined) {
				reload();
			}
			buildParameters((newParameters: K) => {
				parameters.value = newParameters;
			});
		});

		watch(parameters, reload, { immediate: true, deep: true });

		const loadNext = (
			p: keyof Omit<ApiResult<T>, "current" | "items" | "size" | "total">,
			apiResult: ApiResult<T> | undefined,
		): void => {
			const newCursor = new URL(apiResult?.[p] ?? "").searchParams.get(
				"cursor",
			);
			if (cursor.value !== newCursor) {
				// Reset `parameters` to avoid resetting `cursor` below in the `watchEffect` callback
				parameters.value = undefined;
			}
			if (newCursor != null) {
				cursor.value = newCursor;
			}
		};
		return {
			state: computed(() => ({
				hasNextPage: () => this.hasPage("next", apiResult.value),
				hasPrevPage: () => this.hasPage("prev", apiResult.value),
				items: apiResult.value?.items,
				loadFirst: () => loadNext("first", apiResult.value),
				loadLast: () => loadNext("last", apiResult.value),
				loadNext: () => loadNext("next", apiResult.value),
				loadPrev: () => loadNext("prev", apiResult.value),
				loading: loading.value,
				total: apiResult.value?.total,
			})),
		};
	}
}
