<template>
	<form @submit="onSubmit">
		<v-card elevation="0">
			<v-card-text>
				<validated-text-field label="Id" :model-value="id" name="id" readonly />
				<validated-text-field
					:label="$t('role.name')"
					:model-value="name"
					name="name"
					@update:model-value="
						(newValue: string) => $emit('update:name', newValue)
					"
				/>
				<validated-text-field
					:label="$t('role.shortName')"
					:model-value="shortName"
					name="shortName"
					@update:model-value="
						(newValue: string) => $emit('update:shortName', newValue)
					"
				/>
				<validated-text-field
					:label="$t('role.description')"
					:model-value="description"
					name="description"
					@update:model-value="
						(newValue: string) => $emit('update:description', newValue)
					"
				/>
				<validated-select
					autocomplete
					chips
					clearable
					:items="allRights"
					itemtext="name"
					itemvalue="id"
					:label="$t('navigation.rights')"
					:model-value="rightsForRole"
					name="rights"
					@update:model-value="
						(newValue: RightRef) => $emit('update:rightsForRole', newValue)
					"
				></validated-select>
				<validated-select
					autocomplete
					chips
					clearable
					:items="allRightGroups"
					itemtext="name"
					itemvalue="id"
					:label="$t('navigation.rightGroups')"
					:model-value="rightGroups"
					name="rightGroups"
					@update:model-value="
						(newValue: RightGroupRef) => $emit('update:rightGroups', newValue)
					"
				></validated-select>
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn @click="resetForm">{{ $t("reset") }}</v-btn>
				<access-button
					:access-requirements="deleteRight"
					color="red"
					:disabled="id == undefined"
					variant="outlined"
					@click="emit('delete')"
					>{{ $t("delete") }}</access-button
				>
				<access-button
					:access-requirements="write_requirements"
					color="primary"
					location="bottom right"
					type="submit"
					variant="outlined"
					>{{ $t("save") }}</access-button
				>
			</v-card-actions>
		</v-card>
	</form>
</template>

<script setup lang="ts">
	import { RightGroupRef, RightRef } from "@de/IST.Cloud.Permission_API";
	import rights from "@de/ist.cloud.rights";
	import { AccessButton } from "@de/ist.style_components_v3";
	import { useForm } from "vee-validate";
	import { ref } from "vue";
	import * as yup from "yup";

	import { getRightGroups } from "@/api/RightGroupAPI";
	import { sharedInputProps } from "@/shared/types/SharedInputProps";

	import { getRights } from "../../api/RightAPI";
	import ValidatedSelect from "../validation/ValidatedSelect.vue";
	import ValidatedTextField from "../validation/ValidatedTextField.vue";

	const deleteRight: rights = "Role.Delete";

	defineProps({
		description: {
			type: String,
			default: "",
		},
		name: {
			type: String,
			default: "",
		},
		rightGroups: {
			type: Array as () => RightGroupRef[] | null | undefined,
			default: () => [],
		},
		rightsForRole: {
			type: Array as () => RightRef[] | null | undefined,
			default: () => [],
		},
		shortName: {
			type: String,
			default: "",
		},
		...sharedInputProps,
	});

	const emit = defineEmits([
		"update:name",
		"update:shortName",
		"update:description",
		"update:rightsForRole",
		"update:rightGroups",
		"submit",
		"delete",
	]);

	const allRights = ref<RightRef[]>();

	getRights({ limit: 100 }, undefined).then((res) => {
		allRights.value = res.items.map((right) => {
			return {
				id: right.id,
				name: right.name,
			};
		});
	});

	const { handleSubmit, resetForm } = useForm({
		validationSchema: yup.object({
			description: yup.string().required(),
			id: yup.string(),
			name: yup.string().required(),
			rightGroups: yup.array(),
			rightsForRole: yup.array(),
			shortName: yup.string().required(),
		}),
	});

	const allRightGroups = ref<RightGroupRef[]>();

	getRightGroups({ limit: 10 }, undefined).then((res) => {
		allRightGroups.value = res.items.map((rightGroup) => {
			return {
				id: rightGroup.id,
				name: rightGroup.name,
			};
		});
	});

	const onSubmit = handleSubmit(() => {
		emit("submit");
	});
</script>
