<template>
	<form @submit="onSubmit">
		<v-card elevation="0">
			<v-card-text>
				<validated-text-field label="Id" :model-value="id" name="id" readonly />
				<validated-text-field
					:label="$t('emailTemplates.name')"
					:model-value="name"
					name="name"
					@update:model-value="
						(newValue: string) => $emit('update:name', newValue)
					"
				/>
				<validated-text-field
					:label="$t('emailTemplates.description')"
					:model-value="description"
					name="description"
					@update:model-value="
						(newValue: string) => $emit('update:description', newValue)
					"
				/>
				<validated-text-field
					:label="$t('emailTemplates.subject')"
					:model-value="subject"
					name="subject"
					@update:model-value="
						(newValue: string) => $emit('update:subject', newValue)
					"
				/>

				<v-row>
					<v-col
						><validated-text-area
							:label="$t('emailTemplates.body')"
							:model-value="bodyText"
							name="bodyText"
							rows="20"
							@update:model-value="
								(newValue: string) => $emit('update:bodyText', newValue)
							"
						>
						</validated-text-area
					></v-col>
					<v-col
						>Vorschau:
						<div class="bordered-div" v-html="bodyText"></div
					></v-col>
				</v-row>
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn @click="resetForm">{{ $t("reset") }}</v-btn>
				<access-button
					:access-requirements="deleteRight"
					color="red"
					:disabled="id == undefined"
					variant="outlined"
					@click="emit('delete')"
					>{{ $t("delete") }}</access-button
				>
				<access-button
					:access-requirements="write_requirements"
					color="primary"
					location="bottom right"
					type="submit"
					variant="outlined"
					>{{ $t("save") }}</access-button
				>
			</v-card-actions>
		</v-card>
	</form>
</template>

<script setup lang="ts">
	import rights from "@de/ist.cloud.rights";
	import { AccessButton } from "@de/ist.style_components_v3";
	import { useForm } from "vee-validate";
	import * as yup from "yup";

	import ValidatedTextArea from "../../components/validation/ValidatedTextArea.vue";
	import ValidatedTextField from "../../components/validation/ValidatedTextField.vue";
	import { sharedInputProps } from "../../shared/types/SharedInputProps";

	const deleteRight: rights = "EmailTemplates.Delete";

	defineProps({
		bodyText: {
			type: String,
			default: "",
		},
		description: {
			type: String,
			default: "",
		},
		name: {
			type: String,
			default: "",
		},
		subject: {
			type: String,
			default: "",
		},
		...sharedInputProps,
	});

	const emit = defineEmits([
		"update:bodyText",
		"update:description",
		"update:name",
		"submit",
		"delete",
		"update:subject",
	]);

	const { handleSubmit, resetForm } = useForm({
		validationSchema: yup.object({
			bodyText: yup.string().required(),
			description: yup.string().required(),
			id: yup.string(),
			name: yup.string().required(),
			subject: yup.string().required(),
		}),
	});

	const onSubmit = handleSubmit(() => {
		emit("submit");
	});
</script>

<style scoped>
	.bordered-div {
		border: 1px solid grey;
		padding: 10px;
	}
</style>
