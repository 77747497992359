<template>
	<page-layout
		:back-title="$t('back')"
		back-title-link="persons"
		title="person.singularTitle"
	>
		<person-form
			v-if="newPerson"
			v-model:date-of-birth-date-time="newPerson.dateOfBirthDateTime"
			v-model:email="newPerson.email"
			v-model:first-name="newPerson.firstName"
			v-model:gender="newPerson.gender"
			v-model:last-name="newPerson.lastName"
			v-model:middle_name="newPerson.middleName"
			v-model:source="newPerson.source"
			:write_requirements="createRight"
			@submit="save"
		>
		</person-form>
	</page-layout>
</template>

<script setup lang="ts">
	import { CreatePersonRequest } from "@de/IST.Cloud.Person_API";
	import rights from "@de/ist.cloud.rights";
	import { PageLayout } from "@de/ist.style_components_v3";
	import { AxiosError } from "axios";
	import { ref } from "vue";
	import { useRouter } from "vue-router";

	import { createPerson } from "@/api/PersonAPI";
	import PersonForm from "@/components/person/PersonForm.vue";
	import { genderTypes } from "@/shared/types/api/genderTypes";

	const createRight: rights = "Person.Create";

	const newPerson = ref<CreatePersonRequest>({
		dateOfBirthDateTime: "",
		firstName: "",
		gender: genderTypes[4],
		lastName: "",
		middleName: "",
		source: "",
	});

	const router = useRouter();
	const save = () => {
		createPerson(newPerson.value)
			.then((res) => {
				//TODO: add notification
				router.push({
					name: "person",
					params: { id: res.data.id },
				});
			})
			.catch((err: AxiosError) => {
				//TODO: add notification
				alert("Fehler beim Speichern");
			});
	};
</script>

<style scoped></style>
