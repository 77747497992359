import {
	Configuration,
	CreateRoleToPersonRequest,
	RoleToPerson,
	RoleToPersons,
	RoleToPersonsApi,
	RoleToPersonsApiGetRoleToPersonsRequest,
	RoleToPersonToExternalId,
} from "@de/IST.Cloud.Permission_API";
import { useAuth } from "@de/ist-cloud-auth";
import { AxiosResponse } from "axios";
import { v4 as uuidv4 } from "uuid";
import { ref } from "vue";

import { ApiResult } from "@/shared/types/api/apiTypes";

const configuration = new Configuration({
	basePath: "/permission-api",
});

const rolesAPI = new RoleToPersonsApi(configuration);

export type argsWithoutCustandSession = Omit<
	RoleToPersonsApiGetRoleToPersonsRequest,
	"istCustomerId" | "istSessionId"
>;

export function getRoleToPersons(
	args: argsWithoutCustandSession | undefined,
	cursor: string | undefined,
) {
	const data = ref<ApiResult<RoleToPerson>>();
	const loading = ref<boolean>(true);

	getRolesInternal({
		cursor: cursor,
		key: args?.key,
		limit: args?.limit ?? 3,
		personId: args?.personId,
		roleId: args?.roleId,
		sort: args?.sort ?? "",
	})
		.then((result) => {
			data.value = result;
		})
		.finally(() => {
			loading.value = false;
		});

	return {
		data,
		loading,
	};
}

export async function getRoleToPersonById(id: string) {
	const { roleId, organisationId, customerId } = useAuth();

	// maybe move this to an interceptor
	const headers = {
		"X-Role-ID": roleId.value ?? "",
		"X-Organisation-ID": organisationId.value ?? "",
	};

	return await rolesAPI.getRoleToPerson(
		{
			id: id,
			istCustomerId: customerId.value ?? "",
			istSessionId: uuidv4(),
		},
		{
			headers: headers,
		},
	);
}

export async function deleteRoleById(id: string | undefined) {
	const { roleId, organisationId, customerId } = useAuth();

	if (!id) {
		throw new Error("id is undefined");
	}

	// maybe move this to an interceptor
	const headers = {
		"X-Role-ID": roleId.value ?? "",
		"X-Organisation-ID": organisationId.value ?? "",
	};

	return await rolesAPI.deleteRoleToPerson(
		{
			id: id,
			istCustomerId: customerId.value ?? "",
			istSessionId: uuidv4(),
		},
		{
			headers: headers,
		},
	);
}

export async function updateRole(
	id: string | undefined,
	org: RoleToPerson | undefined,
) {
	if (!id || !org) {
		throw new Error("id is undefined");
	}
	const { roleId, organisationId, customerId } = useAuth();

	// maybe move this to an interceptor
	const headers = {
		"X-Role-ID": roleId.value ?? "",
		"X-Organisation-ID": organisationId.value ?? "",
	};

	return await rolesAPI.updateRoleToPerson(
		{
			id: id,
			updateRoleToPersonRequest: org,
			istCustomerId: customerId.value ?? "",
			istSessionId: uuidv4(),
		},
		{
			headers: headers,
		},
	);
}

export async function createRole(request: CreateRoleToPersonRequest) {
	const { roleId, organisationId, customerId } = useAuth();

	// maybe move this to an interceptor
	const headers = {
		"X-Role-ID": roleId.value ?? "",
		"X-Organisation-ID": organisationId.value ?? "",
	};

	return await rolesAPI.createRoleToPerson(
		{
			createRoleToPersonRequest: request,
			istCustomerId: customerId.value ?? "",
			istSessionId: uuidv4(),
		},
		{
			headers: headers,
		},
	);

	//return result.data;
}

const getRolesInternal = async (
	args: argsWithoutCustandSession,
): Promise<ApiResult<RoleToPerson>> => {
	const { roleId, organisationId, customerId } = useAuth();

	// maybe move this to an interceptor
	const headers = {
		"X-Role-ID": roleId.value ?? "",
		"X-Organisation-ID": organisationId.value ?? "",
	};

	if (args.cursor) {
		return convertApiResponse(
			await rolesAPI.getRoleToPersons(
				{
					istCustomerId: customerId.value ?? "",
					istSessionId: uuidv4(),
					cursor: args.cursor,
				},
				{
					headers: headers,
				},
			),
		);
	}
	return convertApiResponse(
		await rolesAPI.getRoleToPersons(
			{
				istCustomerId: customerId.value ?? "",
				istSessionId: uuidv4(),
				key: args.key,
				limit: args.limit,
				personId: args.personId,
				roleId: args.roleId,
				sort: args.sort,
			},
			{
				headers: headers,
			},
		),
	);
};

function convertApiResponse(
	response: AxiosResponse<RoleToPersons, any>,
): ApiResult<RoleToPerson> {
	return {
		first: response.data.first,
		items: response.data.items,
		last: response.data.last,
		next: response.data.next,
		prev: response.data.prev,
		self: response.data.self,
		total: response.data.total,
	};
}

export function getIExternalIdsForPerson(id: string) {
	const data = ref<RoleToPersonToExternalId[]>();
	const loading = ref<boolean>(true);
	const { roleId, organisationId, customerId } = useAuth();
	const headers = {
		"X-Role-ID": roleId.value ?? "",
		"X-Organisation-ID": organisationId.value ?? "",
	};

	rolesAPI
		.getExternalIdsForRoleToPerson(
			{
				id: id,
				istCustomerId: customerId.value ?? "",
				istSessionId: uuidv4(),
			},
			{ headers: headers },
		)
		.then((result) => {
			data.value = result.data;
		})
		.finally(() => {
			loading.value = false;
		});

	return {
		data,
		loading,
	};
}
