import {
	Configuration,
	CreateIdentityProviderRequest,
	IdentityProvider,
	IdentityProviders,
	IdentityProvidersApi,
	IdentityProvidersApiGetIdentityProvidersRequest,
} from "@de/IST.Cloud.Person_API";
import { useAuth } from "@de/ist-cloud-auth";
import { AxiosResponse } from "axios";
import { v4 as uuidv4 } from "uuid";

import { ApiResult } from "@/shared/types/api/apiTypes";

const configuration = new Configuration({
	basePath: "/person-api",
});

const identityProviderAPI = new IdentityProvidersApi(configuration);

export type argsWithoutCustandSession = Omit<
	IdentityProvidersApiGetIdentityProvidersRequest,
	"istCustomerId" | "istSessionId"
>;

export async function getIdentityProviders(
	args: argsWithoutCustandSession | undefined,
	cursor: string | undefined,
): Promise<ApiResult<IdentityProvider>> {
	return await getIdentityProvidersInternal({
		limit: args?.limit ?? 3,
		sort: args?.sort ?? "",
		cursor: cursor,
	});
}

export async function getIdentityProviderById(id: string) {
	const { roleId, organisationId } = useAuth();

	// maybe move this to an interceptor
	const headers = {
		"X-Role-ID": roleId.value ?? "",
		"X-Organisation-ID": organisationId.value ?? "",
	};

	return await identityProviderAPI.getIdentityProvider(
		{
			id: id,
		},
		{
			headers: headers,
		},
	);
}

export async function deleteIdentityProvider(id: string | undefined) {
	const { roleId, organisationId } = useAuth();

	if (!id) {
		throw new Error("id is undefined");
	}

	// maybe move this to an interceptor
	const headers = {
		"X-Role-ID": roleId.value ?? "",
		"X-Organisation-ID": organisationId.value ?? "",
	};

	return await identityProviderAPI.deletesIdentityProvider(
		{
			id: id,
		},
		{
			headers: headers,
		},
	);
}

export async function updateIdentityProvider(
	id: string | undefined,
	org: IdentityProvider | undefined,
) {
	if (!id || !org) {
		throw new Error("id is undefined");
	}
	const { roleId, organisationId } = useAuth();

	// maybe move this to an interceptor
	const headers = {
		"X-Role-ID": roleId.value ?? "",
		"X-Organisation-ID": organisationId.value ?? "",
	};

	return await identityProviderAPI.updateIdentityProvider(
		{
			id: id,
			updateIdentityProviderRequest: org,
		},
		{
			headers: headers,
		},
	);
}

export async function createIdentityProvider(
	request: CreateIdentityProviderRequest,
) {
	const { roleId, organisationId } = useAuth();

	// maybe move this to an interceptor
	const headers = {
		"X-Role-ID": roleId.value ?? "",
		"X-Organisation-ID": organisationId.value ?? "",
	};

	return await identityProviderAPI.createIdentityProviders(
		{
			createIdentityProviderRequest: request,
			istSessionId: uuidv4(),
		},
		{
			headers: headers,
		},
	);

	//return result.data;
}

const getIdentityProvidersInternal = async (
	args: argsWithoutCustandSession,
): Promise<ApiResult<IdentityProvider>> => {
	const { roleId, organisationId } = useAuth();

	// maybe move this to an interceptor
	const headers = {
		"X-Role-ID": roleId.value ?? "",
		"X-Organisation-ID": organisationId.value ?? "",
	};

	if (args.cursor) {
		return convertApiResponse(
			await identityProviderAPI.getIdentityProviders(
				{
					istSessionId: uuidv4(),
					cursor: args.cursor,
				},
				{
					headers: headers,
				},
			),
		);
	}
	return convertApiResponse(
		await identityProviderAPI.getIdentityProviders(
			{
				istSessionId: uuidv4(),
				limit: args.limit,
				sort: args.sort,
			},
			{
				headers: headers,
			},
		),
	);
};

function convertApiResponse(
	response: AxiosResponse<IdentityProviders, any>,
): ApiResult<IdentityProvider> {
	return {
		first: response.data.first,
		items: response.data.items,
		last: response.data.last,
		next: response.data.next,
		prev: response.data.prev,
		self: response.data.self,
		total: response.data.total,
	};
}
