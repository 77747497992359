<template>
	<page-layout
		:back-title="$t('back')"
		back-title-link="../../rightGroups"
		:title="$t('rightGroup.singularTitle')"
	>
		<right-group-form
			v-if="right"
			v-model:description="right.description"
			v-model:id="right.id"
			v-model:name="right.name"
			v-model:rights-for-right-group="right.rights"
			:write_requirements="updateRight"
			@delete="deleteAction"
			@submit="save"
		>
		</right-group-form>
	</page-layout>
</template>

<script setup lang="ts">
	import { RightGroup } from "@de/IST.Cloud.Permission_API";
	import allRights from "@de/ist.cloud.rights";
	import { PageLayout } from "@de/ist.style_components_v3";
	import { AxiosError } from "axios";
	import { ref } from "vue";
	import { useRoute, useRouter } from "vue-router";

	import {
		deleteRightGroupById,
		getRightGroupById,
		updateRightGroup,
	} from "../../api/RightGroupAPI";
	import RightGroupForm from "../../components/rightGroup/RightGroupForm.vue";

	const updateRight: allRights = "RightGroups.Update";

	const id = useRoute().params.id as string;

	const right = ref<RightGroup>();

	getRightGroupById(id).then((res) => {
		right.value = res.data;
	});

	const router = useRouter();
	const save = () => {
		updateRightGroup(right.value?.id, right.value)
			.then(() => {})
			.catch((err: AxiosError) => {
				//TODO: add notification
				alert("Fehler beim Speichern");
			});
	};

	const deleteAction = () => {
		deleteRightGroupById(right.value?.id)
			.then(() => {
				router.push({
					name: "rightGroups",
				});
			})
			.catch((err: AxiosError) => {
				//TODO: add notification
				alert("Fehler beim Speichern");
			});
	};
</script>

<style scoped></style>
