<template>
	<v-dialog
		v-model="dialog"
		height="800"
		persistent
		width="800"
		@keydown.esc="cancel"
	>
		<v-card class="pb-4" :loading="loading">
			<v-card-title class="headline"> Email schreiben </v-card-title>
			<v-card-text>
				<validated-select-single
					v-model="selectedEmailTemplate"
					clearable
					:items="allEmailTemplates"
					itemtext="name"
					:label="$t('emailTemplates.singularTitle')"
					name="selectedEmailTemplate"
					return-object
				></validated-select-single>
				Vorschau:
				<div
					v-if="selectedEmailTemplate"
					class="bordered-div"
					v-html="getReplacesEmailText"
				></div>
			</v-card-text>
			<v-card-actions class="pt-4 mr-4">
				<v-spacer></v-spacer>

				<v-btn color="primary" variant="outlined" @click="cancel">
					{{ $t("cancel") }}
				</v-btn>
				<v-btn
					color="primary"
					:disabled="
						selectedEmailTemplate == undefined || selectedEmailTemplate == null
					"
					variant="flat"
					@click="confirmBtnAction"
				>
					{{ $t("email.send") }}
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
	<notification-snackbar
		v-model="showSnackbar"
		:color="snackBarColor"
		:text="snackBarText"
		:timeout="snackBarTimeout"
		:title="snackBarTitle"
	></notification-snackbar>
</template>

<script setup lang="ts">
	import {
		EmailTemplate,
		IdentityProviderCode,
		Person,
	} from "@de/IST.Cloud.Person_API";
	import { NotificationSnackbar } from "@de/ist.style_components_v3";
	import { computed, onMounted, onUnmounted, ref } from "vue";
	import { useI18n } from "vue-i18n";

	import { getEmailTemplates } from "@/api/EmailTemplateAPI";
	import { sendEmailForIdentityProviderCode } from "@/api/IdentityProviderCodeAPI";
	import { getPersonById } from "@/api/PersonAPI";

	import { formatDate } from "../../shared/DateUtils";
	import ValidatedSelectSingle from "../validation/ValidatedSelectSingle.vue";

	const i18n = useI18n();

	const showSnackbar = ref<boolean>(false);
	const snackBarTimeout = ref<number>(3000);
	const snackBarColor = ref<string>("primary");
	const snackBarText = ref<string>("");
	const snackBarTitle = ref<string>("");
	const loading = ref<boolean>(false);

	const props = defineProps({
		modelValue: {
			type: Boolean,
			default: false,
		},
		identityProviderCode: {
			type: Object as () => IdentityProviderCode | undefined,
			default: undefined,
		},
	});

	const selectedEmailTemplate = ref<EmailTemplate>();

	const emit = defineEmits(["emailSend", "update:modelValue"]);

	const dialog = computed({
		get() {
			return props.modelValue;
		},
		set(value) {
			emit("update:modelValue", value);
		},
	});

	function confirmBtnAction() {
		loading.value = true;
		sendEmailForIdentityProviderCode(
			props.identityProviderCode?.id,
			selectedEmailTemplate.value?.id,
		)
			.then(() => {
				showSnackbar.value = true;
				snackBarColor.value = "success";
				snackBarText.value = i18n.t("email.success");
				snackBarTitle.value = i18n.t("success");
				snackBarTimeout.value = 3000;
				selectedEmailTemplate.value = undefined;
				emit("emailSend");
				dialog.value = false;
			})
			.catch((error) => {
				showSnackbar.value = true;
				snackBarColor.value = "error";
				snackBarText.value =
					error.response?.data?.errors?.[0]?.message ?? i18n.t("unknownError");
				snackBarTitle.value = i18n.t("email.error");
				snackBarTimeout.value = -1;
			})
			.finally(() => {
				loading.value = false;
			});
	}

	function cancel() {
		selectedEmailTemplate.value = undefined;
		dialog.value = false;
	}

	const escapeHandler = (e: { key: string }) => {
		if (e.key === "Escape") {
			cancel();
		}
	};

	onMounted(() => {
		document.addEventListener("keydown", escapeHandler);
	});

	onUnmounted(() => {
		document.removeEventListener("keydown", escapeHandler);
	});
	const allEmailTemplates = ref<EmailTemplate[]>();

	getEmailTemplates({ limit: 100 }, undefined).then((res) => {
		allEmailTemplates.value = res.items;
	});

	const person = ref<Person>();

	getPersonById(props.identityProviderCode?.personRef.id ?? "").then((res) => {
		person.value = res.data;
	});

	const getReplacesEmailText = computed(() => {
		if (selectedEmailTemplate.value) {
			var text = selectedEmailTemplate.value.body_text.replace(
				"{{firstName}}",
				props.identityProviderCode?.personRef.first_name ?? "",
			);
			text = text.replace(
				"{{lastName}}",
				props.identityProviderCode?.personRef.last_name ?? "",
			);
			text = text.replace(
				"{{code}}",
				props.identityProviderCode?.code_value ?? "",
			);
			text = text.replace(
				"{{validFrom}}",
				formatDate(props.identityProviderCode?.valid_from) ?? "",
			);
			text = text.replace(
				"{{validUntil}}",
				formatDate(props.identityProviderCode?.valid_until) ?? "",
			);
			return text;
		}
		return "";
	});
</script>

<style scoped>
	.bordered-div {
		border: 1px solid grey;
		padding: 10px;
	}
</style>
