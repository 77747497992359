<template>
	<form @submit="onSubmit">
		<v-card elevation="0">
			<v-card-text>
				<validated-text-field label="Id" :model-value="id" name="id" readonly />
				<validated-text-field
					disabled
					label="PersonId"
					:model-value="personId"
					name="personId"
					@update:model-value="
						(newValue: string) => $emit('update:personId', newValue)
					"
				/>
				<validated-select-single
					:items="allRole"
					itemtext="name"
					itemvalue="id"
					:label="$t('identityProvider.singularTitle')"
					:model-value="providerId"
					name="providerId"
					@update:model-value="
						(newValue: string) => $emit('update:providerId', newValue)
					"
				></validated-select-single>
				<validated-text-field
					:label="$t('identityProvider.externalId')"
					:model-value="externalId"
					name="externalId"
					@update:model-value="
						(newValue: string) => $emit('update:externalId', newValue)
					"
				/>
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn @click="resetForm">{{ $t("reset") }}</v-btn>
				<v-btn
					color="red"
					:disabled="id == undefined"
					variant="outlined"
					@click="emit('delete')"
					>{{ $t("delete") }}</v-btn
				>
				<v-btn
					color="primary"
					location="bottom right"
					type="submit"
					variant="outlined"
					>{{ $t("save") }}</v-btn
				>
			</v-card-actions>
		</v-card>
	</form>
</template>

<script setup lang="ts">
	import { IdentityProvider } from "@de/IST.Cloud.Person_API";
	import { useForm } from "vee-validate";
	import { ref } from "vue";
	import * as yup from "yup";

	import { getIdentityProviders } from "@/api/IdentityProviderAPI";

	import { sharedInputProps } from "../../shared/types/SharedInputProps";
	import ValidatedSelectSingle from "../validation/ValidatedSelectSingle.vue";
	import ValidatedTextField from "../validation/ValidatedTextField.vue";

	defineProps({
		personId: {
			type: String,
			default: "",
		},
		providerId: {
			type: String,
			default: "",
		},
		externalId: {
			type: String,
			default: "",
		},
		...sharedInputProps,
	});

	const emit = defineEmits([
		"update:personId",
		"update:providerId",
		"update:externalId",
		"submit",
		"delete",
	]);

	const { handleSubmit, resetForm } = useForm({
		validationSchema: yup.object({
			externalId: yup.string().required(),
			id: yup.string(),
			personId: yup.string().required(),
			providerId: yup.string().required(),
		}),
	});

	const allRole = ref<IdentityProvider[]>();

	getIdentityProviders(undefined, undefined).then((res) => {
		allRole.value = res.items;
	});

	const onSubmit = handleSubmit(() => {
		emit("submit");
	});
</script>

<style scoped></style>
