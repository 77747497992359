import {
	Configuration,
	CreateRoleToPersonToExternalIdRequest,
	RoleToPersonToExternalId,
	RoleToPersonToExternalIdApi,
	RoleToPersonToExternalIdApiGetRoleToPersonToExternalIdsRequest,
	RoleToPersonToExternalIds,
	UpdateRoleToPersonToExternalIdRequest,
} from "@de/IST.Cloud.Permission_API";
import { useAuth } from "@de/ist-cloud-auth";
import { AxiosResponse } from "axios";
import { v4 as uuidv4 } from "uuid";
import { ref } from "vue";

import { ApiResult } from "@/shared/types/api/apiTypes";

const configuration = new Configuration({
	basePath: "/permission-api",
});

const roleToPersonToExternalIdApi = new RoleToPersonToExternalIdApi(
	configuration,
);

export type argsWithoutCustandSession = Omit<
	RoleToPersonToExternalIdApiGetRoleToPersonToExternalIdsRequest,
	"istCustomerId" | "istSessionId"
>;

export function getRoleToPersonToExternalIds(
	args: argsWithoutCustandSession | undefined,
	cursor: string | undefined,
) {
	const data = ref<ApiResult<RoleToPersonToExternalId>>();
	const loading = ref<boolean>(true);

	getRoleToPersonToExternalIdsInternal({
		cursor: cursor,
		externalId: args?.externalId,
		limit: args?.limit ?? 3,
		personId: args?.personId,
		sort: args?.sort ?? "",
		source: args?.source,
	})
		.then((result) => {
			data.value = result;
		})
		.finally(() => {
			loading.value = false;
		});

	return {
		data,
		loading,
	};
}

export async function getPersonToExternalId(id: string) {
	const { roleId, organisationId } = useAuth();

	// maybe move this to an interceptor
	const headers = {
		"X-Role-ID": roleId.value ?? "",
		"X-Organisation-ID": organisationId.value ?? "",
	};

	return await roleToPersonToExternalIdApi.getRoleToPersonToExternalId(
		{
			id: id,
		},
		{
			headers: headers,
		},
	);
}

export async function deleteRoleToPersonToExternalId(id: string | undefined) {
	const { roleId, organisationId } = useAuth();

	if (!id) {
		throw new Error("id is undefined");
	}

	// maybe move this to an interceptor
	const headers = {
		"X-Role-ID": roleId.value ?? "",
		"X-Organisation-ID": organisationId.value ?? "",
	};

	return await roleToPersonToExternalIdApi.deleteRoleToPersonToExternalId(
		{
			id: id,
		},
		{
			headers: headers,
		},
	);
}

export async function updateRoleToPersonToExternalId(
	id: string | undefined,
	org: UpdateRoleToPersonToExternalIdRequest | undefined,
) {
	if (!id || !org) {
		throw new Error("id is undefined");
	}
	const { roleId, organisationId } = useAuth();

	// maybe move this to an interceptor
	const headers = {
		"X-Role-ID": roleId.value ?? "",
		"X-Organisation-ID": organisationId.value ?? "",
	};

	return await roleToPersonToExternalIdApi.updateRoleToPersonToExternalId(
		{
			id: id,
			updateRoleToPersonToExternalIdRequest: org,
		},
		{
			headers: headers,
		},
	);
}

export async function createRoleToPersonToExternalId(
	request: CreateRoleToPersonToExternalIdRequest,
) {
	const { roleId, organisationId } = useAuth();

	// maybe move this to an interceptor
	const headers = {
		"X-Role-ID": roleId.value ?? "",
		"X-Organisation-ID": organisationId.value ?? "",
	};

	return await roleToPersonToExternalIdApi.createRoleToPersonToExternalId(
		{
			createRoleToPersonToExternalIdRequest: request,
			istSessionId: uuidv4(),
		},
		{
			headers: headers,
		},
	);

	//return result.data;
}

const getRoleToPersonToExternalIdsInternal = async (
	args: argsWithoutCustandSession,
): Promise<ApiResult<RoleToPersonToExternalId>> => {
	const { roleId, organisationId } = useAuth();

	// maybe move this to an interceptor
	const headers = {
		"X-Role-ID": roleId.value ?? "",
		"X-Organisation-ID": organisationId.value ?? "",
	};

	if (args.cursor) {
		return convertApiResponse(
			await roleToPersonToExternalIdApi.getRoleToPersonToExternalIds(
				{
					istSessionId: uuidv4(),
				},
				{
					headers: headers,
				},
			),
		);
	}
	return convertApiResponse(
		await roleToPersonToExternalIdApi.getRoleToPersonToExternalIds(
			{
				externalId: args.externalId,
				istSessionId: uuidv4(),
				limit: args.limit,
				personId: args.personId,
				sort: args.sort,
				source: args?.source,
			},
			{
				headers: headers,
			},
		),
	);
};

function convertApiResponse(
	response: AxiosResponse<RoleToPersonToExternalIds, any>,
): ApiResult<RoleToPersonToExternalId> {
	return {
		first: response.data.first,
		items: response.data.items,
		last: response.data.last,
		next: response.data.next,
		prev: response.data.prev,
		self: response.data.self,
		total: response.data.total,
	};
}
