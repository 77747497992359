<template>
	<page-layout
		:back-title="$t('back')"
		back-title-link="./"
		:title="
			$t('navigation.roles') + ' ' + person?.firstName + ' ' + person?.lastName
		"
	>
		<role-to-person-form
			v-if="roleToPerson.roleRef"
			v-model:organisation-id="roleToPerson.organisationId"
			v-model:person-id="id"
			v-model:right-groups="roleToPerson.rightGroups"
			v-model:rights="roleToPerson.rights"
			v-model:role-id="roleToPerson.roleRef.id"
			v-model:source="roleToPerson.source"
			@submit="save"
		></role-to-person-form>
	</page-layout>
</template>

<script setup lang="ts">
	import { RoleToPerson } from "@de/IST.Cloud.Permission_API";
	import { Person } from "@de/IST.Cloud.Person_API";
	import { PageLayout } from "@de/ist.style_components_v3";
	import { useAuth } from "@de/ist-cloud-auth";
	import { AxiosError } from "axios";
	import { ref } from "vue";
	import { useRoute, useRouter } from "vue-router";

	import { getPersonById } from "@/api/PersonAPI";
	import { createRole } from "@/api/RoleToPersonAPI";
	import RoleToPersonForm from "@/components/person/RoleToPersonForm.vue";

	const { organisationId } = useAuth();
	const id = useRoute().params.id as string;

	const roleToPerson = ref<RoleToPerson>({
		created_at: "",
		id: "",
		organisationId: organisationId.value ?? "",
		personId: id,
		rightGroups: [],
		rights: [],
		roleRef: {
			id: "",
			name: "",
		},
		updated_at: "",
	});

	const router = useRouter();
	const save = () => {
		createRole(roleToPerson.value)
			.then((res) => {
				//TODO: add notification
				router.push({
					name: "roleToPerson",
					params: {
						id: id,
						roleToPersonId: res.data.id,
					},
				});
			})
			.catch((err: AxiosError) => {
				//TODO: add notification
				alert("Fehler beim Speichern");
			});
	};

	const person = ref<Person>();

	getPersonById(id).then((res) => {
		person.value = res.data;
	});
</script>

<style scoped></style>
