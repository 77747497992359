<template>
	<page-layout
		:back-title="$t('back')"
		back-title-link="../"
		:title="
			$t('navigation.identityProviders') +
			' ' +
			person?.firstName +
			' ' +
			person?.lastName
		"
	>
		<person-to-identity-provider-form
			v-if="
				personToIdentityProvider && personToIdentityProvider.identityProviderRef
			"
			v-model:external-id="personToIdentityProvider.externalId"
			v-model:id="personToIdentityProvider.id"
			v-model:person-id="personId"
			v-model:provider-id="personToIdentityProvider.identityProviderRef.id"
			@delete="deleteAction"
			@submit="save"
		></person-to-identity-provider-form>
	</page-layout>
</template>

<script setup lang="ts">
	import { Person, PersonToIdentityProvider } from "@de/IST.Cloud.Person_API";
	import { PageLayout } from "@de/ist.style_components_v3";
	import { AxiosError } from "axios";
	import { ref } from "vue";
	import { useRoute, useRouter } from "vue-router";

	import { getPersonById } from "@/api/PersonAPI";
	import {
		deletePersonToIdentityProvider,
		getPersonToIdentityProviderById,
		updatePersonToIdentityProvider,
	} from "@/api/PersonToIdentityProvidersApi";
	import PersonToIdentityProviderForm from "@/components/person/PersonToIdentityProviderForm.vue";

	const id = useRoute().params.personToIdentityProviderId as string;
	const personId = useRoute().params.id as string;

	const personToIdentityProvider = ref<PersonToIdentityProvider>();

	getPersonToIdentityProviderById(id).then((res) => {
		personToIdentityProvider.value = res.data;
	});

	const router = useRouter();
	const save = () => {
		updatePersonToIdentityProvider(id, personToIdentityProvider.value)
			.then(() => {})
			.catch((err: AxiosError) => {
				//TODO: add notification
				alert("Fehler beim Speichern");
			});
	};

	const deleteAction = () => {
		deletePersonToIdentityProvider(id)
			.then(() => {
				router.push({
					name: "person",
					params: { id: personId },
				});
			})
			.catch((err: AxiosError) => {
				//TODO: add notification
				alert("Fehler beim Speichern");
			});
	};

	const person = ref<Person>();

	getPersonById(personId).then((res) => {
		person.value = res.data;
	});
</script>

<style scoped></style>
