<template>
	<ol class="buttonList">
		<li>
			{{
				$t("pagination.page", {
					actualPage: actualPage,
					totalPages: totalPages,
				})
			}}
			<v-btn
				aria-label="t('actions.showFirstPage')"
				class="button"
				:disabled="!enableFirstAndPrevious"
				icon="$first"
				rounded="lg"
				:title="$t('pagination.first')"
				variant="text"
				@click="first()"
			/>
			<v-btn
				class="button"
				:disabled="!enableFirstAndPrevious"
				rounded="lg"
				variant="text"
				@click="prev()"
				>{{ $t("pagination.prev") }}</v-btn
			>
			<v-btn
				class="button"
				:disabled="!enableLastAndNext"
				rounded="lg"
				variant="text"
				@click="next()"
				>{{ $t("pagination.next") }}</v-btn
			>
			<v-btn
				aria-label="t('actions.showLastPage')"
				class="button"
				:disabled="!enableLastAndNext"
				icon="$last"
				rounded="lg"
				:title="$t('pagination.last')"
				variant="text"
				@click="last()"
			/>
			<!-- Elemente pro Seite
				-->
			<v-select
				class="select"
				density="compact"
				hide-details
				:items="availableLimits"
				:model-value="modelValue"
				solo
				@update:model-value="(newValue) => $emit('update:modelValue', newValue)"
			>
			</v-select>
		</li>
	</ol>
</template>
<script setup lang="ts">
	import { ref } from "vue";

	var props = defineProps<{
		enableFirstAndPrevious?: boolean | undefined;
		enableLastAndNext?: boolean | undefined;
		totalPages: number | undefined;
		modelValue: number;
	}>();

	const actualPage = ref<number>(1);

	const availableLimits = [5, 10, 25, 50, 100];

	const emit = defineEmits<{
		(e: "firstPage"): void;
		(e: "lastPage"): void;
		(e: "nextPage"): void;
		(e: "previousPage"): void;
		(e: "update:modelValue", newValue: number): void;
	}>();

	function first() {
		emit("firstPage");
		actualPage.value = 1;
	}
	function last() {
		emit("lastPage");
		actualPage.value = props.totalPages || 1;
	}
	function next() {
		emit("nextPage");
		actualPage.value = actualPage.value + 1;
	}
	function prev() {
		emit("previousPage");
		actualPage.value = actualPage.value - 1;
	}

	const resetPageNumber = () => {
		actualPage.value = 1;
	};

	defineExpose({
		resetPageNumber,
	});
</script>

<style scoped>
	.buttonList {
		display: inline-flex;
		justify-content: right;
		list-style-type: none;
		width: 100%;
	}
	.buttonList > li {
		margin: 0.3rem;
	}

	.button {
		text-transform: none;
	}
	.select {
		width: 65px;
	}
</style>
