<template>
	<form @submit="onSubmit">
		<v-card elevation="0">
			<v-card-text>
				<validated-text-field label="Id" :model-value="id" name="id" readonly />
				<validated-text-field
					:label="$t('person.name')"
					:model-value="firstName"
					name="firstName"
					@update:model-value="
						(newValue: string) => $emit('update:firstName', newValue)
					"
				/>
				<validated-text-field
					:label="$t('person.lastName')"
					:model-value="lastName"
					name="lastName"
					@update:model-value="
						(newValue: string) => $emit('update:lastName', newValue)
					"
				/>
				<validated-text-field
					:label="$t('person.email')"
					:model-value="email"
					name="email"
					@update:model-value="
						(newValue: string) => $emit('update:email', newValue)
					"
				/>
				<validated-select-field
					:item-title="(item: string) => $t(item)"
					:item-value="(item: string) => item"
					:items="genderTypes"
					:label="$t('person.gender')"
					:model-value="gender"
					name="gender"
					@update:model-value="
						(newValue: string) => $emit('update:gender', newValue)
					"
				/>
				<validated-text-field
					:label="$t('person.dateOfBirth')"
					:model-value="dateOfBirthDateTime"
					name="dateOfBirthDateTime"
					type="date"
					@update:model-value="
						(newValue: string) => $emit('update:dateOfBirthDateTime', newValue)
					"
				/>
				<validated-text-field
					:label="$t('person.source')"
					:model-value="source"
					name="source"
					@update:model-value="
						(newValue: string) => $emit('update:source', newValue)
					"
				/>

				<v-card v-if="id.length > 0" class="mb-2" elevation="5">
					<v-card-title>{{ $t("navigation.roles") }}</v-card-title>
					<v-skeleton-loader
						:loading="roleToPersonsLoading"
						type="list-item-avatar"
					>
						<v-row v-if="roleToPersons">
							<v-col cols="1">
								<v-btn
									class="mb-2 ml-3"
									color="primary"
									rounded
									@click="
										$router.push({
											name: 'createRoleToPerson',
										})
									"
									><v-icon>mdi-plus</v-icon></v-btn
								></v-col
							>

							<v-col
								v-for="(roleToPerson, i) in roleToPersons.items"
								:key="i"
								cols="1"
							>
								<div>
									<v-chip @click="routeToDetailView(roleToPerson)">{{
										roleToPerson.roleRef?.name
									}}</v-chip>
								</div>
							</v-col>
						</v-row>
					</v-skeleton-loader>
				</v-card>

				<v-card v-if="id.length > 0" class="mb-2" elevation="5">
					<v-card-title>{{ $t("navigation.identityProviders") }}</v-card-title>
					<v-skeleton-loader
						:loading="personToIdentityProvidersLoading"
						type="list-item-avatar"
					>
						<v-row v-if="personToIdentityProviders">
							<v-col cols="1">
								<v-btn
									class="mb-2 ml-3"
									color="primary"
									rounded
									@click="
										$router.push({
											name: 'createPersonToIdentityProvider',
										})
									"
									><v-icon>mdi-plus</v-icon></v-btn
								>
							</v-col>
							<v-col
								v-for="(
									personToIdentityProvider, i
								) in personToIdentityProviders.items"
								:key="i"
								cols="1"
							>
								<div>
									<v-chip
										@click="
											routeToDetailViewIdentityProvider(
												personToIdentityProvider,
											)
										"
										>{{
											personToIdentityProvider.identityProviderRef?.name
										}}</v-chip
									>
								</div>
							</v-col>
						</v-row>
					</v-skeleton-loader>
				</v-card>
				<v-card v-if="id.length > 0" class="mb-2" elevation="5">
					<v-card-title>{{
						$t("navigation.identityProviderCodes")
					}}</v-card-title>
					<v-skeleton-loader
						:loading="identityProviderCodesToPersonLoading"
						type="list-item-avatar"
					>
						<v-row v-if="identityProviderCodesToPerson">
							<v-col cols="1">
								<v-btn
									class="mb-2 ml-3"
									color="primary"
									rounded
									@click="
										$router.push({
											name: 'createIdentityProviderCode',
										})
									"
									><v-icon>mdi-plus</v-icon></v-btn
								>
							</v-col>
							<v-col
								v-for="(
									personToIdentityProvider, i
								) in identityProviderCodesToPerson"
								:key="i"
								cols="1"
							>
								<div>
									<v-chip
										@click="
											routeToDetailViewIdentityProviderCode(
												personToIdentityProvider,
											)
										"
										>{{
											personToIdentityProvider.identityProviderRef?.name
										}}</v-chip
									>
								</div>
							</v-col>
						</v-row>
					</v-skeleton-loader>
				</v-card>
				<v-card v-if="id.length > 0" class="mb-2" elevation="5">
					<v-card-title>{{ $t("person.externalId") }}</v-card-title>
					<v-skeleton-loader
						:loading="externalIdsToPersonsLoading"
						type="list-item-avatar"
					>
						<v-row v-if="externalIdsToPerson">
							<v-col cols="1">
								<v-btn
									class="mb-2 ml-3"
									color="primary"
									rounded
									@click="
										$router.push({
											name: 'createPersonToExternalId',
										})
									"
									><v-icon>mdi-plus</v-icon></v-btn
								>
							</v-col>
							<v-col
								v-for="(externalId, i) in externalIdsToPerson"
								:key="i"
								cols="1"
							>
								<div>
									<v-chip
										@click="routeToDetailViewIPersonToExternalId(externalId)"
										>{{ externalId.source }}</v-chip
									>
								</div>
							</v-col>
						</v-row>
					</v-skeleton-loader>
				</v-card>
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn @click="resetForm">{{ $t("reset") }}</v-btn>
				<access-button
					:access-requirements="deleteRight"
					color="red"
					:disabled="id == undefined"
					variant="outlined"
					@click="emit('delete')"
					>{{ $t("delete") }}</access-button
				>
				<access-button
					:access-requirements="write_requirements"
					color="primary"
					location="bottom right"
					type="submit"
					variant="outlined"
					>{{ $t("save") }}</access-button
				>
			</v-card-actions>
		</v-card>
	</form>
</template>

<script setup lang="ts">
	import { Role, RoleToPerson } from "@de/IST.Cloud.Permission_API";
	import {
		IdentityProviderCode,
		PersonToExternalId,
		PersonToIdentityProvider,
	} from "@de/IST.Cloud.Person_API";
	import rights from "@de/ist.cloud.rights";
	import { AccessButton } from "@de/ist.style_components_v3";
	import { useForm } from "vee-validate";
	import { ref } from "vue";
	import { useRouter } from "vue-router";
	import * as yup from "yup";

	import { getRoles } from "@/api/RoleAPI";
	import { ApiResult } from "@/shared/types/api/apiTypes";
	import { genderTypes } from "@/shared/types/api/genderTypes";
	import { sharedInputProps } from "@/shared/types/SharedInputProps";

	import ValidatedSelectField from "../validation/ValidatedSelectSingle.vue";
	import ValidatedTextField from "../validation/ValidatedTextField.vue";

	const deleteRight: rights = "Person.Delete";

	defineProps({
		dateOfBirthDateTime: {
			type: null,
			default: null,
		},
		email: {
			type: null,
			default: null,
		},
		externalIdsToPerson: {
			type: Array as () => PersonToExternalId[] | null | undefined,
			default: undefined,
		},
		externalIdsToPersonsLoading: {
			type: Boolean,
			default: true,
		},
		firstName: {
			type: String,
			default: "",
		},
		gender: {
			type: String,
			default: genderTypes[3],
		},
		identityProviderCodesToPerson: {
			type: Array as () => IdentityProviderCode[] | null | undefined,
			default: undefined,
		},
		identityProviderCodesToPersonLoading: {
			type: Boolean,
			default: true,
		},
		lastName: {
			type: String,
			default: "",
		},
		middleName: {
			type: String,
			default: "",
		},
		personToIdentityProviders: {
			type: Object as () => ApiResult<PersonToIdentityProvider> | undefined,
			default: undefined,
		},
		personToIdentityProvidersLoading: {
			type: Boolean,
			default: true,
		},
		roleToPersons: {
			type: Object as () => ApiResult<RoleToPerson> | undefined,
			default: undefined,
		},
		roleToPersonsLoading: {
			type: Boolean,
			default: true,
		},
		source: {
			type: null,
			default: null,
		},
		...sharedInputProps,
	});

	const emit = defineEmits([
		"update:firstName",
		"update:lastName",
		"update:gender",
		"update:email",
		"update:middleName",
		"update:dateOfBirthDateTime",
		"update:source",
		"submit",
		"delete",
	]);

	const allRole = ref<Role[]>();

	getRoles(undefined, undefined).then((res) => {
		allRole.value = res.items;
	});

	const { handleSubmit, resetForm } = useForm({
		validationSchema: yup.object({
			email: yup.string().email(),
			firstName: yup.string().required(),
			gender: yup.string().required(),
			id: yup.string(),
			lastName: yup.string().required(),
		}),
	});

	const onSubmit = handleSubmit(() => {
		emit("submit");
	});

	const router = useRouter();
	const routeToDetailView = (roleToPerson: RoleToPerson) => {
		router.push({
			name: "roleToPerson",
			params: {
				id: roleToPerson.personId,
				roleToPersonId: roleToPerson.id,
			},
		});
	};

	const routeToDetailViewIdentityProvider = (
		roleToPerson: PersonToIdentityProvider,
	) => {
		router.push({
			name: "personToIdentityProvider",
			params: {
				id: roleToPerson.personRef?.id,
				personToIdentityProviderId: roleToPerson.id,
			},
		});
	};

	const routeToDetailViewIdentityProviderCode = (
		roleToPerson: IdentityProviderCode,
	) => {
		router.push({
			name: "identityProviderCode",
			params: {
				id: roleToPerson.personRef.id,
				identityProviderCodesId: roleToPerson.id,
			},
		});
	};

	const routeToDetailViewIPersonToExternalId = (
		roleToPerson: PersonToExternalId,
	) => {
		router.push({
			name: "personToExternalId",
			params: {
				id: roleToPerson.personRef?.id,
				externalId: roleToPerson.id,
			},
		});
	};
</script>
