<template>
	<v-autocomplete
		v-if="autocomplete"
		v-model="value"
		:chips="chips"
		:clearable="clearable"
		:disabled="readonly"
		:error-messages="errors"
		:item-title="itemtext"
		:item-value="itemvalue"
		:items="items"
		:label="label"
		multiple
		:readonly="readonly"
		return-object
		variant="outlined"
	>
		<template v-for="(_, slot) of $slots as {}" #[slot]="slotData">
			<slot :name="slot" v-bind="(slotData as any) || {}" />
		</template>
	</v-autocomplete>

	<v-select
		v-else
		v-model="value"
		:chips="chips"
		:clearable="clearable"
		:disabled="readonly"
		:error-messages="errors"
		:item-title="itemtext"
		:item-value="itemvalue"
		:items="items"
		:label="label"
		multiple
		:readonly="readonly"
		return-object
		variant="outlined"
	>
		<template v-for="(_, slot) of $slots as {}" #[slot]="slotData">
			<slot :name="slot" v-bind="(slotData as any) || {}" />
		</template>
	</v-select>
</template>

<script setup lang="ts" generic="T">
	import { useField } from "vee-validate";

	const props = defineProps({
		autocomplete: {
			type: Boolean,
			default: false,
		},
		chips: {
			type: Boolean,
			default: false,
		},
		clearable: {
			type: Boolean,
			default: false,
		},
		items: {
			type: Array as () => T[] | undefined,
			default: () => [],
		},
		itemtext: {
			type: String,
			default: "text",
		},
		itemvalue: {
			type: String,
			default: "value",
		},
		modelValue: {
			type: Array as () => T[] | null | undefined,
			default: null,
		},
		name: {
			type: String,
			default: "",
		},
		readonly: {
			type: Boolean,
			default: false,
		},

		label: {
			type: String,
			default: "",
		},
	});

	const { value, errors } = useField<T[]>(() => props.name, undefined, {
		syncVModel: true,
	});
</script>
