<template>
	<page-layout
		:back-title="$t('back')"
		back-title-link="./"
		:title="
			$t('identityProviderCodes.singularTitle') +
			' ' +
			person?.firstName +
			' ' +
			person?.lastName
		"
	>
		<identity-provider-code-form
			v-model:person-id="id"
			v-model:provider-id="role.identityProviderRef.id"
			v-model:valid-from="role.valid_from"
			v-model:valid-until="role.valid_until"
			:write_requirements="createRight"
			@submit="save"
		>
		</identity-provider-code-form>
	</page-layout>
</template>

<script setup lang="ts">
	import { IdentityProviderCode, Person } from "@de/IST.Cloud.Person_API";
	import allRights from "@de/ist.cloud.rights";
	import { PageLayout } from "@de/ist.style_components_v3";
	import { AxiosError } from "axios";
	import moment from "moment";
	import { ref } from "vue";
	import { useRoute, useRouter } from "vue-router";

	import { getPersonById } from "@/api/PersonAPI";

	import { createIdentityProviderCode } from "../../api/IdentityProviderCodeAPI";
	import IdentityProviderCodeForm from "../../components/identityProviderCode/IdentityProviderCodeForm.vue";

	const createRight: allRights = "IdentityProviderCode.Create";
	const id = useRoute().params.id as string;

	const role = ref<IdentityProviderCode>({
		code_value: "",
		created_at: "",
		id: "",
		identityProviderRef: {
			id: "",
			name: "",
		},
		personRef: {
			id: id,
			first_name: "",
			last_name: "",
		},
		send_at: "",
		updated_at: "",
		used_at: "",
		valid_from: moment().format("YYYY-MM-DD"),
		valid_until: "",
	});

	const router = useRouter();
	const save = () => {
		createIdentityProviderCode({
			identity_provider_id: role.value?.identityProviderRef.id,
			person_id: id,
			valid_from: role.value?.valid_from,
			valid_until: role.value?.valid_until,
		})
			.then((res) => {
				//TODO: add notification
				router.push({
					name: "identityProviderCode",
					params: {
						id: id,
						identityProviderCodesId: res.data.id,
					},
				});
			})
			.catch((err: AxiosError) => {
				alert("Fehler beim Speichern");
			});
	};
	const person = ref<Person>();

	getPersonById(id).then((res) => {
		person.value = res.data;
	});
</script>

<style scoped></style>
