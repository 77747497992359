<template>
	<page-layout
		:back-title="$t('back')"
		back-title-link="../../emailTemplates"
		:title="$t('emailTemplates.singularTitle')"
	>
		<email-template-form
			v-if="newOrganisation"
			v-model:body-text="newOrganisation.body_text"
			v-model:description="newOrganisation.description"
			v-model:name="newOrganisation.name"
			v-model:subject="newOrganisation.subject"
			:write_requirements="createRight"
			@submit="save"
		>
		</email-template-form>
	</page-layout>
</template>

<script setup lang="ts">
	import { CreateEmailTemplateRequest } from "@de/IST.Cloud.Person_API";
	import rights from "@de/ist.cloud.rights";
	import { PageLayout } from "@de/ist.style_components_v3";
	import { AxiosError } from "axios";
	import { ref } from "vue";
	import { useRouter } from "vue-router";

	import { createEmailtemplate } from "@/api/EmailTemplateAPI";

	import EmailTemplateForm from "../../components/emailTemplate/EmailTemplateForm.vue";

	const createRight: rights = "EmailTemplates.Create";

	const newOrganisation = ref<CreateEmailTemplateRequest>({
		name: "",
		description: "",
		subject: "",
		body_text: "",
	});

	const router = useRouter();
	const save = () => {
		createEmailtemplate(newOrganisation.value)
			.then((res) => {
				//TODO: add notification
				router.push({
					name: "emailTemplate",
					params: { id: res.data.id },
				});
			})
			.catch((err: AxiosError) => {
				//TODO: add notification
				alert("Fehler beim Speichern");
			});
	};
</script>

<style scoped></style>
