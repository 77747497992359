import {
	Configuration,
	CreateSecretRequest,
	Secrets,
	SecretsApi,
} from "@de/IST.Cloud.Organisation_API";
import { useAuth } from "@de/ist-cloud-auth";
import { v4 as uuidv4 } from "uuid";
import { ref } from "vue";

const configuration = new Configuration({
	basePath: "/organisation-api",
});

const organisationAPI = new SecretsApi(configuration);

export async function getSecretForOrganisation(
	id: string,
	forOrganisationId: string,
) {
	const { roleId, organisationId, customerId } = useAuth();

	// maybe move this to an interceptor
	const headers = {
		"X-Role-ID": roleId.value ?? "",
		"X-Organisation-ID": organisationId.value ?? "",
	};

	return await organisationAPI.getSecretForOrganisation(
		{
			secretid: id,
			id: forOrganisationId ?? "",
			istCustomerId: customerId.value ?? "",
			istSessionId: uuidv4(),
		},
		{
			headers: headers,
		},
	);
}

export function getSecretsForOrganisation(forOrganisationId: string) {
	const { roleId, organisationId, customerId } = useAuth();

	const data = ref<Secrets>();
	const loading = ref<boolean>(true);

	// maybe move this to an interceptor
	const headers = {
		"X-Role-ID": roleId.value ?? "",
		"X-Organisation-ID": organisationId.value ?? "",
	};

	organisationAPI
		.getSecretsForOrganisation(
			{
				id: forOrganisationId ?? "",
				istCustomerId: customerId.value ?? "",
				istSessionId: uuidv4(),
			},
			{
				headers: headers,
			},
		)
		.then((result) => {
			data.value = result.data;
		})
		.finally(() => {
			loading.value = false;
		});
	return {
		data,
		loading,
	};
}

export async function deleteSecretForOrganisation(
	id: string | undefined,
	forOrganisationId: string,
) {
	const { roleId, organisationId, customerId } = useAuth();

	if (!id) {
		throw new Error("id is undefined");
	}

	// maybe move this to an interceptor
	const headers = {
		"X-Role-ID": roleId.value ?? "",
		"X-Organisation-ID": organisationId.value ?? "",
	};

	return await organisationAPI.deleteSecretForOrganisation(
		{
			secretid: id,
			id: forOrganisationId ?? "",
			istCustomerId: customerId.value ?? "",
			istSessionId: uuidv4(),
		},
		{
			headers: headers,
		},
	);
}

export async function createSecretForOrganisation(
	request: CreateSecretRequest,
	forOrganisationId: string,
) {
	const { roleId, organisationId, customerId } = useAuth();

	// maybe move this to an interceptor
	const headers = {
		"X-Role-ID": roleId.value ?? "",
		"X-Organisation-ID": organisationId.value ?? "",
	};

	return await organisationAPI.createSecretForOrganisation(
		{
			id: forOrganisationId ?? "",
			createSecretRequest: request,
			istCustomerId: customerId.value ?? "",
			istSessionId: uuidv4(),
		},
		{
			headers: headers,
		},
	);
}

export async function retrieveAndDeleteSecret(id: string) {
	const { roleId, organisationId, customerId } = useAuth();

	// maybe move this to an interceptor
	const headers = {
		"X-Role-ID": roleId.value ?? "",
		"X-Organisation-ID": organisationId.value ?? "",
	};

	return await organisationAPI.retrieveAndDeleteSecret(
		{
			id: id,
			istCustomerId: customerId.value ?? "",
			istSessionId: uuidv4(),
		},
		{
			headers: headers,
		},
	);
}
