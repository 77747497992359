<template>
	<form @submit="onSubmit">
		<v-card elevation="0">
			<v-card-text>
				<validated-text-field label="Id" :model-value="id" name="id" readonly />
				<validated-text-field
					disabled
					:label="$t(label)"
					:model-value="relatedEntityId"
					name="personId"
					@update:model-value="
						(newValue: string) => $emit('update:personId', newValue)
					"
				/>

				<validated-text-field
					:label="$t('externalIds.source')"
					:model-value="source"
					name="source"
					@update:model-value="
						(newValue: string) => $emit('update:source', newValue)
					"
				/>
				<validated-text-field
					:label="$t('externalIds.externalId')"
					:model-value="externalId"
					name="externalId"
					@update:model-value="
						(newValue: string) => $emit('update:externalId', newValue)
					"
				/>
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn @click="resetForm">{{ $t("reset") }}</v-btn>
				<v-btn
					color="red"
					:disabled="id == undefined"
					variant="outlined"
					@click="emit('delete')"
					>{{ $t("delete") }}</v-btn
				>
				<v-btn
					color="primary"
					location="bottom right"
					type="submit"
					variant="outlined"
					>{{ $t("save") }}</v-btn
				>
			</v-card-actions>
		</v-card>
	</form>
</template>

<script setup lang="ts">
	import { useForm } from "vee-validate";
	import * as yup from "yup";

	import { sharedInputProps } from "../../shared/types/SharedInputProps";
	import ValidatedTextField from "../validation/ValidatedTextField.vue";

	defineProps({
		externalId: {
			type: String,
			default: "",
		},
		label: {
			type: String,
			default: "",
		},
		relatedEntityId: {
			type: String,
			default: "",
		},
		source: {
			type: String,
			default: "",
		},
		...sharedInputProps,
	});

	const emit = defineEmits([
		"update:personId",
		"update:source",
		"update:externalId",
		"submit",
		"delete",
	]);

	const { handleSubmit, resetForm } = useForm({
		validationSchema: yup.object({
			externalId: yup.string().required(),
			id: yup.string(),
			personId: yup.string().required(),
			source: yup.string().required(),
		}),
	});

	const onSubmit = handleSubmit(() => {
		emit("submit");
	});
</script>

<style scoped></style>
