<template>
	<page-layout
		:back-title="$t('back')"
		back-title-link="../../emailTemplates"
		:title="$t('emailTemplates.singularTitle')"
	>
		<email-template-form
			v-if="organisation"
			v-model:body-text="organisation.body_text"
			v-model:description="organisation.description"
			v-model:id="organisation.id"
			v-model:name="organisation.name"
			v-model:subject="organisation.subject"
			:write_requirements="updateRight"
			@delete="deleteAction"
			@submit="save"
		>
		</email-template-form>
	</page-layout>
</template>

<script setup lang="ts">
	import { EmailTemplate } from "@de/IST.Cloud.Person_API";
	import rights from "@de/ist.cloud.rights";
	import { PageLayout } from "@de/ist.style_components_v3";
	import { AxiosError } from "axios";
	import { ref } from "vue";
	import { useRoute, useRouter } from "vue-router";

	import {
		deleteEmailTemplate,
		getEmailTemplateById,
		updateEmailTemplate,
	} from "@/api/EmailTemplateAPI";

	import EmailTemplateForm from "../../components/emailTemplate/EmailTemplateForm.vue";

	const updateRight: rights = "EmailTemplates.Update";

	const id = useRoute().params.id as string;

	const organisation = ref<EmailTemplate>();

	getEmailTemplateById(id).then((res) => {
		organisation.value = res.data;
	});

	const router = useRouter();
	const save = () => {
		updateEmailTemplate(organisation.value?.id, organisation.value)
			.then(() => {})
			.catch((err: AxiosError) => {
				//TODO: add notification
				alert("Fehler beim Speichern");
			});
	};

	const deleteAction = () => {
		deleteEmailTemplate(organisation.value?.id)
			.then(() => {
				router.push({
					name: "organisations",
				});
			})
			.catch((err: AxiosError) => {
				//TODO: add notification
				alert("Fehler beim Speichern");
			});
	};
</script>

<style scoped></style>
