<template>
	<form @submit="onSubmit">
		<v-card elevation="0">
			<v-card-text>
				<validated-text-field label="Id" :model-value="id" name="id" readonly />
				<validated-text-field
					:label="$t('right.name')"
					:model-value="keyProp"
					name="keyProp"
					@update:model-value="
						(newValue: string) => $emit('update:keyProp', newValue)
					"
				/>
				<validated-text-field
					:label="$t('right.description')"
					:model-value="description"
					name="description"
					@update:model-value="
						(newValue: string) => $emit('update:description', newValue)
					"
				/>
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn @click="resetForm">{{ $t("reset") }}</v-btn>
				<access-button
					:access-requirements="deleteRight"
					color="red"
					:disabled="id == undefined"
					variant="outlined"
					@click="emit('delete')"
					>{{ $t("delete") }}</access-button
				>
				<access-button
					:access-requirements="write_requirements"
					color="primary"
					location="bottom right"
					type="submit"
					variant="outlined"
					>{{ $t("save") }}</access-button
				>
			</v-card-actions>
		</v-card>
	</form>
</template>

<script setup lang="ts">
	import rights from "@de/ist.cloud.rights";
	import { AccessButton } from "@de/ist.style_components_v3";
	import { useForm } from "vee-validate";
	import * as yup from "yup";

	import { sharedInputProps } from "@/shared/types/SharedInputProps";

	import ValidatedTextField from "../validation/ValidatedTextField.vue";

	const deleteRight: rights = "Right.Delete";

	defineProps({
		keyProp: {
			type: String,
			default: "",
		},
		description: {
			type: String,
			default: "",
		},
		...sharedInputProps,
	});

	const emit = defineEmits([
		"update:keyProp",
		"update:description",
		"submit",
		"delete",
	]);

	const { handleSubmit, resetForm } = useForm({
		validationSchema: yup.object({
			keyProp: yup.string().required(),
			description: yup.string().required(),
			id: yup.string(),
		}),
	});

	const onSubmit = handleSubmit(() => {
		emit("submit");
	});
</script>
