<template>
	<form @submit="onSubmit">
		<v-card elevation="0">
			<v-card-text>
				<validated-text-field label="Id" :model-value="id" name="id" readonly />
				<validated-text-field
					disabled
					label="PersonId"
					:model-value="personId"
					name="personId"
					@update:model-value="
						(newValue: string) => $emit('update:personId', newValue)
					"
				/>
				<validated-text-field
					label="Source"
					:model-value="source ?? ''"
					name="source"
					@update:model-value="
						(newValue: string) => $emit('update:source', newValue)
					"
				/>
				<validated-select-single
					:items="allRole"
					itemtext="name"
					itemvalue="id"
					:label="$t('role.singularTitle')"
					:model-value="roleId"
					name="roleId"
					@update:model-value="
						(newValue: string) => $emit('update:roleId', newValue)
					"
				></validated-select-single>
				<validated-select-single
					:items="allOrganisations"
					itemtext="name"
					itemvalue="id"
					:label="$t('organisation.singularTitle')"
					:model-value="organisationId"
					name="organisationId"
					:readonly="id != undefined && id != ''"
					@update:model-value="
						(newValue: string) => $emit('update:organisationId', newValue)
					"
				></validated-select-single>
				<validated-select
					autocomplete
					chips
					clearable
					:items="allRightGroups"
					itemtext="name"
					itemvalue="id"
					:label="$t('navigation.rightGroups')"
					:model-value="rightGroups"
					name="rightGroups"
					@update:model-value="
						(newValue: RightGroupRef) => $emit('update:rightGroups', newValue)
					"
				></validated-select>
				<validated-select
					autocomplete
					chips
					clearable
					:items="allRights"
					itemtext="name"
					itemvalue="id"
					:label="$t('navigation.rights')"
					:model-value="rights"
					name="rights"
					@update:model-value="
						(newValue: RightRef) => $emit('update:rights', newValue)
					"
				></validated-select>
				<v-card v-if="id.length > 0" class="mb-2" elevation="5">
					<v-card-title>{{ $t("person.externalId") }}</v-card-title>
					<v-skeleton-loader
						:loading="externalIdsToPersonsLoading"
						type="list-item-avatar"
					>
						<v-row v-if="externalIdsToPerson">
							<v-col cols="1">
								<v-btn
									class="mb-2 ml-3"
									color="primary"
									rounded
									@click="
										$router.push({
											name: 'createRoleToPersonToExternalId',
										})
									"
									><v-icon>mdi-plus</v-icon></v-btn
								>
							</v-col>
							<v-col
								v-for="(externalId, i) in externalIdsToPerson"
								:key="i"
								cols="1"
							>
								<div>
									<v-chip
										@click="routeToDetailViewIPersonToExternalId(externalId)"
										>{{ externalId.source }}</v-chip
									>
								</div>
							</v-col>
						</v-row>
					</v-skeleton-loader>
				</v-card>
			</v-card-text>

			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn @click="resetForm">{{ $t("reset") }}</v-btn>
				<v-btn
					color="red"
					:disabled="id == undefined"
					variant="outlined"
					@click="emit('delete')"
					>{{ $t("delete") }}</v-btn
				>
				<v-btn
					color="primary"
					location="bottom right"
					type="submit"
					variant="outlined"
					>{{ $t("save") }}</v-btn
				>
			</v-card-actions>
		</v-card>
	</form>
</template>

<script setup lang="ts">
	import { Organisation } from "@de/IST.Cloud.Organisation_API";
	import {
		RightGroupRef,
		RightRef,
		RightToRoleToPersonRef,
		Role,
		RoleToPersonToExternalId,
	} from "@de/IST.Cloud.Permission_API";
	import { useForm } from "vee-validate";
	import { ref } from "vue";
	import { useRouter } from "vue-router";
	import * as yup from "yup";

	import { getOrganisations } from "@/api/OrganisationAPI";
	import { getRightGroups } from "@/api/RightGroupAPI";
	import { getRoles } from "@/api/RoleAPI";
	import { sharedInputProps } from "@/shared/types/SharedInputProps";

	import { getRights } from "../../api/RightAPI";
	import ValidatedSelect from "../validation/ValidatedSelect.vue";
	import ValidatedSelectSingle from "../validation/ValidatedSelectSingle.vue";
	import ValidatedTextField from "../validation/ValidatedTextField.vue";

	const router = useRouter();

	const props = defineProps({
		externalIdsToPerson: {
			type: Array as () => RoleToPersonToExternalId[] | null | undefined,
			default: undefined,
		},
		externalIdsToPersonsLoading: {
			type: Boolean,
			default: true,
		},
		organisationId: {
			type: String,
			default: "",
		},
		personId: {
			type: String,
			default: "",
		},
		rightGroups: {
			type: Array as () => RightGroupRef[] | null | undefined,
			default: () => [],
		},
		rights: {
			type: Array as () => RightToRoleToPersonRef[] | null | undefined,
			default: () => [],
		},
		roleId: {
			type: String,
			default: "",
		},
		source: {
			type: String as () => string | null | undefined,
			default: null,
		},
		...sharedInputProps,
	});

	const allRights = ref<RightToRoleToPersonRef[]>();

	getRights({ limit: 100 }, undefined).then((res) => {
		allRights.value = res.items.map((right) => {
			return {
				id: right.id,
				name: right.name,
			};
		});
	});

	const allRightGroups = ref<RightGroupRef[]>();

	getRightGroups({ limit: 100 }, undefined).then((res) => {
		allRightGroups.value = res.items.map((rightGroup) => {
			return {
				id: rightGroup.id,
				name: rightGroup.name,
			};
		});
	});

	const allOrganisations = ref<Organisation[]>();

	getOrganisations({ limit: 100 }, undefined).then((res) => {
		allOrganisations.value = res.items;
	});

	const emit = defineEmits([
		"update:personId",
		"update:roleId",
		"update:rights",
		"update:source",
		"update:rightGroups",
		"update:organisationId",
		"submit",
		"delete",
	]);

	const { handleSubmit, resetForm } = useForm({
		validationSchema: yup.object({
			id: yup.string(),
			organisationId: yup.string().required(),
			personId: yup.string().required(),
			rightGroups: yup.array(),
			rights: yup.array(),
			roleId: yup.string().required(),
			source: yup.string(),
		}),
	});

	const allRole = ref<Role[]>();

	getRoles(undefined, undefined).then((res) => {
		allRole.value = res.items;
	});

	const onSubmit = handleSubmit(() => {
		emit("submit");
	});
	const routeToDetailViewIPersonToExternalId = (
		roleToPersonToExternalId: RoleToPersonToExternalId,
	) => {
		router.push({
			name: "roleToPersonToExternalIdView",
			params: {
				id: props.personId,
				externalId: roleToPersonToExternalId.id,
				roleToPersonId: roleToPersonToExternalId.roleToPersonId,
			},
		});
	};
</script>

<style scoped></style>
