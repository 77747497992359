<template>
	<page-layout
		:back-title="$t('back')"
		back-title-link="identityProviderCodes"
		:title="$t('email.send')"
	>
		<v-row>
			<v-col cols="3">
				<v-select
					v-model="selectedEmailTemplate"
					clearable
					item-title="name"
					:items="allEmailTemplates"
					:label="$t('emailTemplates.singularTitle')"
					name="selectedEmailTemplate"
					return-object
					><template #item="{ item, props }">
						<div class="ml-4">
							<v-list-item v-bind="props">
								<template #append>
									<v-tooltip>
										<template #activator="{ props }">
											<v-btn
												class="ml-2"
												icon
												readonly
												small
												variant="flat"
												v-bind="props"
												><v-icon>mdi-magnify</v-icon></v-btn
											></template
										>
										<v-card elevation="2">
											<v-card-text>
												<div v-html="item.raw.body_text"></div
											></v-card-text>
										</v-card>
									</v-tooltip>
								</template>
							</v-list-item>
						</div> </template
				></v-select>
			</v-col>
		</v-row>
		<v-row>
			<v-data-table
				v-if="persons"
				v-model="selectedPersons"
				density="compact"
				:headers="headers"
				:items="persons"
				:loading="loading"
				show-select
				><template #[`item.valid_from`]="{ item }">
					{{ formatDate(item.valid_from) }}
				</template>
				<template #[`item.valid_until`]="{ item }"
					>{{ formatDate(item.valid_until) }}
				</template>
				<template #[`item.send_at`]="{ item }">
					{{ formatDate(item.send_at) }}</template
				>
				<template #[`item.used_at`]="{ item }">
					{{ formatDate(item.used_at) }}</template
				>
			</v-data-table>
		</v-row>
		<v-row>
			<v-col cols="10"></v-col>
			<v-col cols="2">
				<access-button
					:access-requirements="createRight"
					color="primary"
					:disabled="disabled"
					location="bottom right"
					type="submit"
					@click="save"
					>{{ $t("email.send") }}</access-button
				></v-col
			>
		</v-row>
	</page-layout>
	<notification-snackbar
		v-model="showSnackbar"
		:color="snackBarColor"
		:show-copy="false"
		:text="snackBarText"
		:timeout="snackBarTimeout"
		:title="snackBarTitle"
	></notification-snackbar>
</template>

<script setup lang="ts">
	import {
		CreateEmailBulkRequest,
		EmailTemplate,
		IdentityProviderCode,
	} from "@de/IST.Cloud.Person_API";
	import allRights from "@de/ist.cloud.rights";
	import {
		AccessButton,
		NotificationSnackbar,
		PageLayout,
	} from "@de/ist.style_components_v3";
	import { ref } from "vue";
	import { computed } from "vue";

	import { getEmailTemplates } from "@/api/EmailTemplateAPI";
	import { getIdentityProviderCodes } from "@/api/IdentityProviderCodeAPI";
	import { Headers } from "@/shared/types/table/HeadersType";

	import { sendBulkEmailForIdentityProviderCodes } from "../../api/IdentityProviderCodeAPI";
	import { formatDate } from "../../shared/DateUtils";

	const selectedEmailTemplate = ref<EmailTemplate>();
	const persons = ref<IdentityProviderCode[]>();
	const selectedPersons = ref<string[]>([]);
	const showSnackbar = ref<boolean>(false);
	const snackBarText = ref<string>("");
	const snackBarTitle = ref<string>("");
	const snackBarTimeout = ref<number>(4000);
	const snackBarColor = ref<string>("primary");
	const loading = ref<boolean>(false);

	const disabled = computed(() => {
		return (
			selectedPersons.value.length === 0 ||
			selectedEmailTemplate.value === undefined ||
			selectedEmailTemplate.value === null
		);
	});

	const headers = ref<Headers>([
		{
			key: "personRef.first_name",
			title: "Vorname",
		},
		{
			key: "personRef.last_name",
			title: "Nachname",
		},
		{
			key: "identityProviderRef.name",
			title: "Identitätsprovider",
		},
		{
			key: "valid_from",
			title: "gültig von",
		},
		{
			key: "valid_until",
			title: "gültig bis",
		},
		{
			key: "used_at",
			title: "verwendet am",
		},
		{
			key: "send_at",
			title: "gesendet am",
		},
	]);

	const createRight: allRights = "IdentityProviderCode.Send";

	const bulkRequest = ref<CreateEmailBulkRequest>();

	const save = () => {
		loading.value = true;
		bulkRequest.value = {
			template_id: selectedEmailTemplate.value?.id ?? "",
			items: selectedPersons.value.map((x) => {
				return {
					id: x,
				};
			}),
		};

		snackBarTitle.value = "";
		snackBarText.value = "";
		sendBulkEmailForIdentityProviderCodes(bulkRequest.value)
			.then((res) => {
				var errorOccured = false;
				var errors: string[] = [];
				res.data.items.forEach((element) => {
					if (element.status != undefined && element.status != "200") {
						errorOccured = true;
						errors.push(element.error_message ?? "");
					}
				});
				if (!errorOccured) {
					snackBarTitle.value = "Erfolgreich";
					snackBarText.value = "Identitätsprovidercode erfolgreich erstellt";
					showSnackbar.value = true;
				} else {
					snackBarTitle.value = "Fehler";
					snackBarText.value =
						"Beim speichern sind Fehler aufgetreten " + errors;
				}
				showSnackbar.value = true;
			})
			.catch((err) => {
				snackBarTitle.value = "Fehler";
				snackBarText.value = "Beim speichern sind Probleme aufgetreten" + err;
				snackBarTimeout.value = -1;
				snackBarColor.value = "error";
				showSnackbar.value = true;
			})
			.finally(() => {
				selectedPersons.value = [];
				loadIdentityProviderCodes();
				loading.value = false;
			});
	};

	const allEmailTemplates = ref<EmailTemplate[]>();

	getEmailTemplates({ limit: 100 }, undefined).then((res) => {
		allEmailTemplates.value = res.items;
	});

	const loadIdentityProviderCodes = () => {
		getIdentityProviderCodes(
			{
				limit: 10,
				sort: "",
				status: "not_used",
			},
			undefined,
		).then((res) => {
			persons.value = res.items;
		});
	};

	loadIdentityProviderCodes();
</script>

<style scoped></style>
