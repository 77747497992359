<template>
	<page-layout
		:back-title="$t('back')"
		back-title-link="../../persons"
		title="person.singularTitle"
	>
		<person-form
			v-if="person"
			v-model:dateOfBirthDateTime="person.dateOfBirthDateTime"
			v-model:email="person.email"
			v-model:externalIdsToPerson="personToExternalIds"
			v-model:externalIdsToPersonsLoading="loadingPersonToExternalIds"
			v-model:first-name="person.firstName"
			v-model:gender="person.gender"
			v-model:id="person.id"
			v-model:identityProviderCodesToPerson="personToIdentityProviderCodes"
			v-model:identityProviderCodesToPersonLoading="loadingPersonToIDPCodes"
			v-model:last-name="person.lastName"
			v-model:middle_name="person.middleName"
			v-model:personToIdentityProviders="personToIdentityProviders"
			v-model:personToIdentityProvidersLoading="loadingyProviders"
			v-model:roleToPersons="roleToPersons"
			v-model:roleToPersonsLoading="loadingRoleToPersons"
			v-model:source="person.source"
			:write_requirements="updateRight"
			@delete="deleteAction"
			@submit="save"
		>
		</person-form>
	</page-layout>
</template>

<script setup lang="ts">
	import { Person } from "@de/IST.Cloud.Person_API";
	import rights from "@de/ist.cloud.rights";
	import { PageLayout } from "@de/ist.style_components_v3";
	import { AxiosError } from "axios";
	import moment from "moment";
	import { ref } from "vue";
	import { useRoute, useRouter } from "vue-router";

	import {
		deletePersonById,
		getPersonById,
		updatePerson,
	} from "@/api/PersonAPI";
	import {
		getIdentityProviderCodesForPerson,
		getIExternalIdsForPerson as getExternalIdsForPerson,
	} from "@/api/PersonAPI";
	import { getPersonToIdentityProviders } from "@/api/PersonToIdentityProvidersApi";
	import { getRoleToPersons } from "@/api/RoleToPersonAPI";
	import PersonForm from "@/components/person/PersonForm.vue";
	import { genderTypes } from "@/shared/types/api/genderTypes";

	const updateRight: rights = "Person.Update";

	const id = useRoute().params.id as string;
	const person = ref<Person>();

	function getGender(inGender: string) {
		if (!inGender || inGender == "") {
			return genderTypes[3];
		}

		var fixedGender = inGender.toLowerCase();

		switch (fixedGender) {
			case "1":
			case "m":
			case "mann":
			case "männlich":
			case "male":
			case "maskulin":
			case "viril":
				return genderTypes[0];
			case "2":
			case "w":
			case "f":
			case "frau":
			case "weiblich":
			case "female":
			case "feminin":
				return genderTypes[1];
			case "3":
			case "d":
			case "divers":
			case "diverse":
			case "geschlechtslos":
				return genderTypes[2];
			case "4":
			case "o":
			case "ohne angabe":
			case "ohneangabe":
			case "ohne":
			case "without":
			case "not stated":
			case "notstated":
				return genderTypes[3];
			default:
				return genderTypes[4];
		}
	}

	getPersonById(id).then((res) => {
		person.value = res.data;
		person.value.gender = getGender(person.value.gender);
		person.value.dateOfBirthDateTime = moment(
			person.value.dateOfBirthDateTime,
		).format("YYYY-MM-DD");
	});

	const { data: roleToPersons, loading: loadingRoleToPersons } =
		getRoleToPersons(
			{
				personId: id,
			},
			undefined,
		);

	const { data: personToIdentityProviders, loading: loadingyProviders } =
		getPersonToIdentityProviders(
			{
				personId: id,
			},
			undefined,
		);

	const {
		data: personToIdentityProviderCodes,
		loading: loadingPersonToIDPCodes,
	} = getIdentityProviderCodesForPerson(id);

	const { data: personToExternalIds, loading: loadingPersonToExternalIds } =
		getExternalIdsForPerson(id);

	const router = useRouter();
	const save = () => {
		updatePerson(person.value?.id, person.value)
			.then(() => {})
			.catch((err: AxiosError) => {
				//TODO: add notification
				alert("Fehler beim Speichern");
			});
	};

	const deleteAction = () => {
		deletePersonById(person.value?.id)
			.then(() => {
				router.push({
					name: "persons",
				});
			})
			.catch((err: AxiosError) => {
				//TODO: add notification
				alert("Fehler beim Speichern");
			});
	};
</script>

<style scoped></style>
