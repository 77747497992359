<template>
	<v-data-table-server
		density="compact"
		:headers="headers"
		:items="state.items"
		items-length="0"
		:loading="loading"
		:multi-sort="true"
		:row-props="rowProps"
		:sort-by="sortBy"
		@update:sort-by="changeOptions($event)"
	>
		<template v-for="(_, name) in $slots as {}" #[name]="slotData">
			<slot :name="name" v-bind="slotData || {}" />
		</template>

		<template #bottom>
			<pagination-controls
				ref="paginationControls"
				:enable-first-and-previous="state.hasPrevPage()"
				:enable-last-and-next="state.hasNextPage()"
				:model-value="modelValue"
				:total-pages="total"
				@first-page="state.loadFirst"
				@last-page="state.loadLast"
				@next-page="state.loadNext"
				@previous-page="state.loadPrev"
				@update:model-value="
					(newValue: number) => $emit('update:modelValue', newValue)
				"
			></pagination-controls> </template
	></v-data-table-server>
</template>

<script setup lang="ts" generic="T">
	import { ref } from "vue";

	import PaginationControls from "@/shared/table/PaginationControls.vue";

	import { PaginationResult } from "../types/api/apiTypes";
	import { Headers } from "../types/table/HeadersType";
	import { SortType } from "../types/table/SortType";
	type ItemKeys = {
		[K in keyof T as K extends string ? `item.${K}` : never]: any;
	};
	defineSlots<ItemKeys>();

	const sortString = ref<string>("");

	const props = defineProps<{
		headers: Headers;
		loading: boolean;
		total: number | undefined;
		modelValue: number;
		state: PaginationResult<T>;
		initialSort: SortType[];
	}>();

	const emit = defineEmits<{
		(e: "first"): void;
		(e: "lastPage"): void;
		(e: "nextPage"): void;
		(e: "previousPage"): void;
		(e: "update:modelValue", newValue: number): void;
		(e: "changeSort", newValue: string): void;
		(e: "click:row", item: T): void;
	}>();

	const sortBy = ref<SortType[]>(props.initialSort);

	const changeOptions = (opt: SortType[]) => {
		sortBy.value = opt;
		const sorts = opt
			.map((o) => {
				if (o.order == "desc") {
					return "-" + mapProps(o.key);
				}
				return mapProps(o.key);
			})
			.join(",");
		sortString.value = sorts;
		emit("changeSort", sortString.value);
	};

	// see https://jira.ist.com/browse/DEL-781
	// maybe we should do this in the api
	const mapProps = (key: string) => {
		if (key == "external_id") {
			return "externalId";
		}

		return key;
	};

	function rowProps({ item }: { item: T }) {
		return {
			onClick: () => emit("click:row", item),
		};
	}
</script>

<style scoped></style>
