import type { App } from "vue";
import type { I18n } from "vue-i18n";

import vueI18n from "./vueI18n";
import vuetify from "./vuetify";

export function registerPlugins(app: App) {
	const vuetifyInstance = vuetify(vueI18n as I18n);

	app.use(vuetifyInstance).use(vueI18n);

	return {
		vueI18n,
		vuetify: vuetifyInstance,
	};
}
