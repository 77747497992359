<template>
	<v-text-field
		v-model="value"
		color="primary"
		:disabled="readonly"
		:error-messages="errors"
		:label="label"
		:readonly="readonly"
		variant="outlined"
	></v-text-field>
</template>

<script setup lang="ts">
	import { useField } from "vee-validate";

	const props = defineProps({
		name: {
			type: String,
			default: "",
		},
		modelValue: {
			type: String,
			default: "",
		},
		readonly: {
			type: Boolean,
			default: false,
		},
		label: {
			type: String,
			default: "",
		},
	});

	const { value, errors } = useField<string>(() => props.name, undefined, {
		syncVModel: true,
	});
</script>
