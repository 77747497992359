import { ComponentPropsOptions } from "vue";

export const sharedInputProps = {
	id: {
		type: String,
		default: "",
	},
	write_requirements: {
		type: String,
		required: false,
		default: "",
	},
} satisfies ComponentPropsOptions;
