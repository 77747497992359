<template>
	<v-autocomplete
		v-if="autocomplete"
		v-model="test"
		:clearable="clearable"
		:disabled="readonly"
		:error-messages="errors"
		:item-title="itemtext"
		:item-value="itemvalue"
		:items="items"
		:label="label"
		:readonly="readonly"
		variant="outlined"
	></v-autocomplete>

	<v-select
		v-else
		v-model="value"
		:clearable="clearable"
		:disabled="readonly"
		:error-messages="errors"
		:item-title="itemtext"
		:item-value="itemvalue"
		:items="items"
		:label="label"
		:readonly="readonly"
		:return-object="returnObject"
		variant="outlined"
	></v-select>
</template>

<script setup lang="ts" generic="T">
	import { useField } from "vee-validate";
	import { PropType, ref } from "vue";

	const props = defineProps({
		autocomplete: {
			type: Boolean,
			default: false,
		},
		clearable: {
			type: Boolean,
			default: false,
		},
		items: {
			type: Array as () => T[] | undefined,
			default: () => [],
		},
		itemtext: {
			type: String,
			default: "text",
		},
		itemvalue: {
			type: String,
			default: "value",
		},
		label: {
			type: String,
			default: "",
		},
		modelValue: {
			type: null as unknown as PropType<T | string>,
			default: null,
		},
		multiple: {
			type: Boolean,
			default: true,
		},
		name: {
			type: String,
			default: "",
		},
		readonly: {
			type: Boolean,
			default: false,
		},
		returnObject: {
			type: Boolean,
			default: false,
		},
	});

	const test = ref<T>();

	const { value, errors } = useField<T | undefined>(
		() => props.name,
		undefined,
		{
			syncVModel: true,
		},
	);
</script>
