<template>
	<form @submit="onSubmit">
		<v-card elevation="0">
			<v-card-text>
				<validated-text-field label="Id" :model-value="id" name="id" readonly />
				<validated-text-field
					:label="$t('organisation.name')"
					:model-value="name"
					name="name"
					@update:model-value="
						(newValue: string) => $emit('update:name', newValue)
					"
				/>
				<validated-text-field
					:label="$t('organisation.shortName')"
					:model-value="shortName"
					name="shortName"
					@update:model-value="
						(newValue: string) => $emit('update:shortName', newValue)
					"
				/>
				<validated-text-field
					:label="$t('organisation.externalId')"
					:model-value="externalId"
					name="externalId"
					@update:model-value="
						(newValue: string) => $emit('update:externalId', newValue)
					"
				/>
				<validated-text-field
					:label="$t('organisation.schoolCodes')"
					:model-value="schoolCode"
					name="schoolCode"
					@update:model-value="
						(newValue: string) => $emit('update:schoolCode', newValue)
					"
				>
				</validated-text-field>
				<validated-select
					:items="schoolCodeTypes"
					:label="$t('organisation.schoolTypes')"
					:model-value="schoolTypes"
					name="schoolTypes"
					@update:model-value="
						(newValue: string) => $emit('update:schoolTypes', newValue)
					"
				></validated-select>
				<validated-text-field
					:label="$t('organisation.authority')"
					:model-value="authority"
					name="authority"
					@update:model-value="
						(newValue: string) => $emit('update:authority', newValue)
					"
				></validated-text-field>

				<v-card v-if="id.length > 0" class="mb-2" elevation="5">
					<v-card-title>{{ $t("secrets.singularTitle") }}</v-card-title>
					<v-skeleton-loader :loading="secrestsLoading" type="list-item-avatar">
						<v-row v-if="secrets">
							<v-col cols="1">
								<v-btn
									class="mb-2 ml-3"
									color="primary"
									rounded
									@click="
										$router.push({
											name: 'createSecretForOrganisation',
										})
									"
									><v-icon>mdi-plus</v-icon></v-btn
								></v-col
							>
							<v-col v-for="(secret, i) in secrets.items" :key="i" cols="1">
								<div>
									<v-chip @click="routeToDetailView(secret)">{{
										secret?.name
									}}</v-chip>
								</div>
							</v-col>
						</v-row>
					</v-skeleton-loader>
				</v-card>
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn @click="resetForm">{{ $t("reset") }}</v-btn>
				<access-button
					:access-requirements="deleteRight"
					color="red"
					:disabled="id == undefined"
					variant="outlined"
					@click="emit('delete')"
					>{{ $t("delete") }}</access-button
				>
				<access-button
					:access-requirements="write_requirements"
					color="primary"
					location="bottom right"
					type="submit"
					variant="outlined"
					>{{ $t("save") }}</access-button
				>
			</v-card-actions>
		</v-card>
	</form>
</template>

<script setup lang="ts">
	import { Secret, Secrets } from "@de/IST.Cloud.Organisation_API";
	import rights from "@de/ist.cloud.rights";
	import { AccessButton } from "@de/ist.style_components_v3";
	import { useForm } from "vee-validate";
	import { useRouter } from "vue-router";
	import * as yup from "yup";

	import ValidatedSelect from "@/components/validation/ValidatedSelect.vue";
	import ValidatedTextField from "@/components/validation/ValidatedTextField.vue";
	import { schoolCodeTypes } from "@/shared/types/api/schoolCodeTypes";
	import { sharedInputProps } from "@/shared/types/SharedInputProps";

	const deleteRight: rights = "Organisation.Delete";

	defineProps({
		authority: {
			type: String,
			default: "",
		},
		externalId: {
			type: String,
			default: "",
		},
		name: {
			type: String,
			default: "",
		},
		schoolCode: {
			type: String,
			default: "",
		},
		schoolTypes: {
			type: Array,
			default: () => [],
		},
		secrestsLoading: {
			type: Boolean,
			default: true,
		},
		secrets: {
			type: Object as () => Secrets | undefined,
			default: undefined,
		},
		shortName: {
			type: String,
			default: "",
		},
		...sharedInputProps,
	});

	const emit = defineEmits([
		"update:name",
		"update:shortName",
		"update:externalId",
		"submit",
		"delete",
		"update:authority",
		"update:schoolCode",
		"update:schoolTypes",
	]);

	schoolCodeTypes;

	const { handleSubmit, resetForm } = useForm({
		validationSchema: yup.object({
			authority: yup.string().required(),
			externalId: yup.string().required(),
			id: yup.string(),
			name: yup.string().required(),
			schoolCode: yup.string().required(),
			schoolTypes: yup.array().required().min(1),
			shortName: yup.string().required(),
		}),
	});

	const onSubmit = handleSubmit(() => {
		emit("submit");
	});

	const router = useRouter();
	const routeToDetailView = (secret: Secret) => {
		router.push({
			name: "secretToOrganisation",
			params: {
				id: secret.organisation.organisation_id,
				secretId: secret.id,
			},
		});
	};
</script>
