<template>
	<page-layout
		:back-title="$t('back')"
		back-title-link="../"
		:title="
			$t('roleToPerson.externalId') +
			' ' +
			person?.firstName +
			' ' +
			person?.lastName
		"
	>
		<external-id-form
			v-if="personToIdentityProvider"
			v-model:external-id="personToIdentityProvider.externalId"
			v-model:id="personToIdentityProvider.id"
			v-model:related-entity-id="roleToPersonId"
			v-model:source="personToIdentityProvider.source"
			label="roleToPerson.id"
			@delete="deleteAction"
			@submit="save"
		></external-id-form>
	</page-layout>
</template>

<script setup lang="ts">
	import { RoleToPersonToExternalId } from "@de/IST.Cloud.Permission_API";
	import { Person } from "@de/IST.Cloud.Person_API";
	import { PageLayout } from "@de/ist.style_components_v3";
	import { AxiosError } from "axios";
	import { ref } from "vue";
	import { useRoute, useRouter } from "vue-router";

	import { getPersonById } from "@/api/PersonAPI";
	import {
		deleteRoleToPersonToExternalId,
		getPersonToExternalId,
		updateRoleToPersonToExternalId,
	} from "@/api/RoleToPersonToExternalIdApi";
	import ExternalIdForm from "@/components/person/ExternalIdForm.vue";

	const roleToPersonToExternalId = useRoute().params.externalId as string;
	const personId = useRoute().params.id as string;
	const roleToPersonId = useRoute().params.roleToPersonId as string;

	const personToIdentityProvider = ref<RoleToPersonToExternalId>();

	getPersonToExternalId(roleToPersonToExternalId).then((res) => {
		personToIdentityProvider.value = res.data;
	});

	const router = useRouter();
	const save = () => {
		updateRoleToPersonToExternalId(
			roleToPersonToExternalId,
			personToIdentityProvider.value,
		)
			.then(() => {})
			.catch((err: AxiosError) => {
				//TODO: add notification
				alert("Fehler beim Speichern");
			});
	};

	const deleteAction = () => {
		deleteRoleToPersonToExternalId(roleToPersonToExternalId)
			.then(() => {
				router.push({
					name: "roleToPerson",
					params: { id: personId, roleToPersonId: roleToPersonId },
				});
			})
			.catch((err: AxiosError) => {
				//TODO: add notification
				alert("Fehler beim Speichern");
			});
	};

	const person = ref<Person>();

	getPersonById(personId).then((res) => {
		person.value = res.data;
	});
</script>

<style scoped></style>
