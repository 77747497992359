import {
	Configuration,
	CreatePersonRequest,
	IdentityProviderCode,
	Person,
	Persons,
	PersonsApi,
	PersonsApiGetPersonsRequest,
	PersonToExternalId,
} from "@de/IST.Cloud.Person_API";
import { useAuth } from "@de/ist-cloud-auth";
import { AxiosResponse } from "axios";
import { v4 as uuidv4 } from "uuid";
import { ref } from "vue";

import { ApiResult } from "@/shared/types/api/apiTypes";

const configuration = new Configuration({
	basePath: "/person-api",
});

const personAPI = new PersonsApi(configuration);

export type GetPersonsRequestWithoutHeaders = Omit<
	PersonsApiGetPersonsRequest,
	"istCustomerId" | "istSessionId" | "xOrganisationID" | "xRoleID"
>;

export async function getPersonById(id: string) {
	const { roleId, organisationId, customerId } = useAuth();

	// maybe move this to an interceptor
	const headers = {
		"X-Role-ID": roleId.value ?? "",
		"X-Organisation-ID": organisationId.value ?? "",
	};

	return await personAPI.getPerson(
		{
			id: id,
			istCustomerId: customerId.value ?? "",
			istSessionId: uuidv4(),
		},
		{
			headers: headers,
		},
	);
}

export async function deletePersonById(id: string | undefined) {
	const { roleId, organisationId, customerId } = useAuth();

	if (!id) {
		throw new Error("id is undefined");
	}

	// maybe move this to an interceptor
	const headers = {
		"X-Role-ID": roleId.value ?? "",
		"X-Organisation-ID": organisationId.value ?? "",
	};

	return await personAPI.deletePerson(
		{
			id: id,
			istCustomerId: customerId.value ?? "",
			istSessionId: uuidv4(),
		},
		{
			headers: headers,
		},
	);
}

export async function updatePerson(
	id: string | undefined,
	org: Person | undefined,
) {
	if (!id || !org) {
		throw new Error("id is undefined");
	}
	const { roleId, organisationId, customerId } = useAuth();

	// maybe move this to an interceptor
	const headers = {
		"X-Role-ID": roleId.value ?? "",
		"X-Organisation-ID": organisationId.value ?? "",
	};

	return await personAPI.updatePerson(
		{
			id: id,
			updatePersonRequest: org,
			istCustomerId: customerId.value ?? "",
			istSessionId: uuidv4(),
		},
		{
			headers: headers,
		},
	);
}

export async function createPerson(request: CreatePersonRequest) {
	const { roleId, organisationId, customerId } = useAuth();

	// maybe move this to an interceptor
	const headers = {
		"X-Role-ID": roleId.value ?? "",
		"X-Organisation-ID": organisationId.value ?? "",
	};

	return await personAPI.createPerson(
		{
			createPersonRequest: request,
			istCustomerId: customerId.value ?? "",
			istSessionId: uuidv4(),
		},
		{
			headers: headers,
		},
	);

	//return result.data;
}

export async function getPersons(
	args: GetPersonsRequestWithoutHeaders | undefined,
	cursor: string | undefined,
): Promise<ApiResult<Person>> {
	return await getPersonsInternal({
		cursor: cursor,
		email: args?.email ?? "",
		firstName: args?.firstName ?? "",
		gender: args?.gender ?? "",
		lastName: args?.lastName ?? "",
		limit: args?.limit ?? 3,
		roleId: args?.roleId ?? "",
		sort: args?.sort ?? "",
		source: args?.source ?? "",
	});
}

const getPersonsInternal = async (
	args: GetPersonsRequestWithoutHeaders,
): Promise<ApiResult<Person>> => {
	const { roleId, organisationId, customerId } = useAuth();

	const headers = {};

	if (args.cursor) {
		return convertApiResponse(
			await personAPI.getPersons(
				{
					cursor: args.cursor,
					istCustomerId: customerId.value ?? "",
					istSessionId: uuidv4(),
					xOrganisationID: organisationId.value ?? "",
					xRoleID: roleId.value ?? "",
				},
				{
					headers: headers,
				},
			),
		);
	}
	return convertApiResponse(
		await personAPI.getPersons(
			{
				email: args.email,
				firstName: args.firstName,
				gender: args.gender,
				istCustomerId: customerId.value ?? "",
				istSessionId: uuidv4(),
				lastName: args.lastName,
				limit: args.limit,
				roleId: args.roleId,
				sort: args.sort,
				source: args.source,
				xOrganisationID: organisationId.value ?? "",
				xRoleID: roleId.value ?? "",
			},
			{
				headers: headers,
			},
		),
	);
};

function convertApiResponse(
	response: AxiosResponse<Persons, any>,
): ApiResult<Person> {
	return {
		first: response.data.first,
		items: response.data.items,
		last: response.data.last,
		next: response.data.next,
		prev: response.data.prev,
		self: response.data.self,
		total: response.data.total,
	};
}

export function getIdentityProviderCodesForPerson(id: string) {
	const data = ref<IdentityProviderCode[]>();
	const loading = ref<boolean>(true);
	const { roleId, organisationId, customerId } = useAuth();
	const headers = {
		"X-Role-ID": roleId.value ?? "",
		"X-Organisation-ID": organisationId.value ?? "",
	};

	personAPI
		.getIdentityProviderCodesForPerson(
			{
				id: id,
				istCustomerId: customerId.value ?? "",
				istSessionId: uuidv4(),
			},
			{ headers: headers },
		)
		.then((result) => {
			data.value = result.data;
		})
		.finally(() => {
			loading.value = false;
		});

	return {
		data,
		loading,
	};
}

export function getIExternalIdsForPerson(id: string) {
	const data = ref<PersonToExternalId[]>();
	const loading = ref<boolean>(true);
	const { roleId, organisationId, customerId } = useAuth();
	const headers = {
		"X-Role-ID": roleId.value ?? "",
		"X-Organisation-ID": organisationId.value ?? "",
	};

	personAPI
		.getExternalIdsForPerson(
			{
				id: id,
				istCustomerId: customerId.value ?? "",
				istSessionId: uuidv4(),
			},
			{ headers: headers },
		)
		.then((result) => {
			data.value = result.data;
		})
		.finally(() => {
			loading.value = false;
		});

	return {
		data,
		loading,
	};
}
