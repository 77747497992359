<template>
	<page-layout
		:back-title="$t('back')"
		back-title-link="./"
		:title="
			$t('navigation.identityProviders') +
			' ' +
			person?.firstName +
			' ' +
			person?.lastName
		"
	>
		<person-to-identity-provider-form
			v-if="personToIdentityProvider.identityProviderRef"
			v-model:external-id="personToIdentityProvider.externalId"
			v-model:person-id="id"
			v-model:provider-id="personToIdentityProvider.identityProviderRef.id"
			@submit="save"
		></person-to-identity-provider-form>
	</page-layout>
</template>

<script setup lang="ts">
	import {
		CreatePersonToIdentityProviderRequest,
		Person,
	} from "@de/IST.Cloud.Person_API";
	import { PageLayout } from "@de/ist.style_components_v3";
	import { AxiosError } from "axios";
	import { ref } from "vue";
	import { useRoute, useRouter } from "vue-router";

	import { getPersonById } from "@/api/PersonAPI";
	import { createPersonToIdentityProvider } from "@/api/PersonToIdentityProvidersApi";
	import PersonToIdentityProviderForm from "@/components/person/PersonToIdentityProviderForm.vue";

	const id = useRoute().params.id as string;

	const personToIdentityProvider = ref<CreatePersonToIdentityProviderRequest>({
		personRef: {
			id: id,
			last_name: "",
			first_name: "",
		},
		identityProviderRef: {
			id: "",
			name: "",
		},
		externalId: "",
	});

	const router = useRouter();
	const save = () => {
		createPersonToIdentityProvider(personToIdentityProvider.value)
			.then((res) => {
				//TODO: add notification
				router.push({
					name: "personToIdentityProvider",
					params: {
						id: id,
						personToIdentityProviderId: res.data.id,
					},
				});
			})
			.catch((err: AxiosError) => {
				//TODO: add notification
				alert("Fehler beim Speichern");
			});
	};

	const person = ref<Person>();

	getPersonById(id).then((res) => {
		person.value = res.data;
	});
</script>

<style scoped></style>
